import { forwardRef, useImperativeHandle, useState } from 'react';
import ClassNames from 'classnames';
import {
  Form,
  Modal,
  RadioButton,
  useFormController,
  useThemeContext,
  useThemeDispatch,
} from '../../../../index';
import { getbgWider } from '../../getThemeStyles';
import CheckBoxDiv from './CheckBoxDiv';

function getColorPrimary(config = {}, themeType) {
  return config[themeType].colorPrimary;
}

const layoutModeArr = [
  { id: 'shrinkMenu', name: 'shrinkMenu', url: 'https://static-1255466169.cos.ap-guangzhou.myqcloud.com/ThemeAssets/shrinkMenu.png' },
  { id: 'default', name: 'default', url: 'https://static-1255466169.cos.ap-guangzhou.myqcloud.com/ThemeAssets/default.png' },
  { id: 'top', name: 'top', url: 'https://static-1255466169.cos.ap-guangzhou.myqcloud.com/ThemeAssets/top.png' },
  { id: 'ExpandMenu', name: 'ExpandMenu', url: 'https://static-1255466169.cos.ap-guangzhou.myqcloud.com/ThemeAssets/ExpandMenu.png' },
];
const markModeArr = [
  { id: 'markMode1', name: 'markMode1' },
  { id: 'markMode2', name: 'markMode2' },
  { id: 'markMode3', name: 'markMode3' },
  { id: 'markMode4', name: 'markMode4' },
];
const themeTypeArr = [
  { id: 'theme1', name: 'theme1' },
  { id: 'theme2', name: 'theme2' },
  { id: 'theme3', name: 'theme3' },
  { id: 'theme4', name: 'theme4' },
];
function formschema({ themeConfig }) {
  return {
    layoutMode: {
      type: CheckBoxDiv,
      name: '框架',
      rules: [],
      options: layoutModeArr,
    },
    markMode: {
      type: CheckBoxDiv,
      name: '纹理',
      rules: [],
      options: markModeArr,
      opts: {
        boxType: 'box',
        Contenxt: item => (
          <div className="TUI-markModeWarp">
            <img className="TUI-markModeImg" src={getbgWider(item.name)} />
          </div>
        ),
      },
    },
    sysMode: {
      type: RadioButton,
      name: '主题',
      rules: [],
      options: [
        { id: 'light', name: '明亮' },
        { id: 'dark', name: '暗黑' },
      ],
    },
    themeType: {
      type: CheckBoxDiv,
      name: '颜色',
      rules: [],
      options: themeTypeArr,
      opts: {
        boxType: 'box',
        Contenxt: item => (
          <div
            style={{ width: 48, height: 48, borderRadius: 12, backgroundColor: getColorPrimary(themeConfig, item.name) }}
          />
        ),
      },
    },
    fontSizeEm: {
      type: RadioButton,
      name: '字号',
      rules: [],
      options: [
        { id: 14, field: 14, name: '默认' },
        { id: 18, field: 18, name: '大' },
        { id: 24, field: 24, name: '超大' },
      ],
      opts: {
        // min: 12,
        // max: 32,
      },
    },
    // paddingEm: {
    //   type: InputSlider,
    //   name: '间距',
    //   rules: [
    //     { required: true },
    //   ],
    //   opts: {
    //     min: 0,
    //     max: 16,
    //   },
    // },
    sizeEm: {
      type: RadioButton,
      name: '间距',
      rules: [],
      options: [
        { id: 'small', field: 3, name: '紧凑' },
        { id: 'middle', field: 4, name: '默认' },
        { id: 'large', field: 5, name: '宽松' },
      ],
      opts: {
      },
    },
    borderRadiusEm: {
      type: RadioButton,
      name: '圆角',
      options: [
        { id: 16, field: 12, name: '较圆' },
        { id: 6, field: 6, name: '默认' },
        { id: 2, field: 0, name: '直角' },
      ],
      opts: {
        // min: 0,
        // max: 16,
      },
    },
    modalMode: {
      type: RadioButton,
      name: '弹窗类型',
      options: [
        { id: 'drawer', name: '抽屉' },
        { id: 'modal', name: '弹窗' },
      ],
      opts: {},
    },
  };
}

function ThemeConfigT(props, ref) {
  const { sysMode, themeType, layoutMode, markMode, fontSize, borderRadius, size, themeConfig, modalMode } = useThemeContext();
  const dispatch = useThemeDispatch();
  const [visible, setVisible] = useState(false);

  const form = useFormController({
    schema: formschema,
  });

  const onCancel = () => {
    form.reset();
    setVisible(false);
  };

  const onOk = async () => {
    // await form.submit();
    const { sysMode, themeType, layoutMode, markMode, fontSizeEm: fontSize, sizeEm: size, borderRadiusEm: borderRadius, modalMode } = form.fields;

    const Local_ThemeConfig = {
      sysMode,
      themeType,
      layoutMode,
      markMode,
      fontSize,
      size,
      borderRadius,
      modalMode,
    };
    dispatch({ type: 'setData', payload: Local_ThemeConfig });
    onCancel();
  };

  useImperativeHandle(ref, () => ({
    handle: () => {
      setVisible(true);
      const Local_ThemeConfig = {
        sysMode,
        themeType,
        layoutMode,
        markMode,
        fontSizeEm: fontSize,
        sizeEm: size,
        borderRadiusEm: borderRadius,
        modalMode,
      };

      form.setFields(Local_ThemeConfig);
    },
  }));

  return (
    <Modal
      open={visible}
      title="自定义主题"
      width={1000}
      maskClosable={false}
      destroyOnClose
      onCancel={onCancel}
      onOk={onOk}
      confirmLoading={form.submitting}
      className={ClassNames('TUI-Modal TUI-ThemeModal')}
    >
      <Form
        schema={formschema({ themeConfig })}
        fields={form.fields}
        errors={form.errors}
        onFieldChange={form.setField}
        onChange={form.setFields}
        className={ClassNames('TUI-Vertical-Form')}
      />
    </Modal>
  );
};

const ThemeConfig = forwardRef(ThemeConfigT);
export default ThemeConfig;
