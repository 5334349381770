import { useEffect, useRef, useState } from 'react';
import { Flex, Tooltip, Typography, message } from 'antd';
import ClassNames from 'classnames';
import { DragMenu, Search, TUIEmpty, TUIIcon, TUISkeleton, useResizer } from '../../index';
import type { LeftMenuProps, storeDataProps } from './types';
import { initStoreData } from './common';
import './index.less';

const { Title } = Typography;

function LeftMenu({
  id = 'DragLeft_Id',
  width = 258,
  nameWidth = 148,
  title = '分类',
  addTips = '新增自定义分类',
  isDarag = false, // 是否可拖拽
  icon = '',
  updateSelectItem = () => {},
  dependPara = {}, // 接口依赖的一些参数
  dependField = '', // 接口依赖的参数
  onFeatchStart = () => true, // 请求前的判断
  onFetchList,
  onFetchAdd,
  onFetchEdit,
  onFetchDelete,
  onFetchChangeOrder,
  auth = {},
  classNames = '',
  style = {},
  DragStyle = {},
  DargWarpHeight = `calc(100vh - 200px)`,
  DragMenuProps = {},
  ...rest
}: LeftMenuProps) {
  const { showAdd } = auth;
  const iconT = icon || (<TUIIcon type="icon-a-folder1x1" style={{ marginRight: 4, marginLeft: isDarag ? 0 : 4 }} />);

  const [storeData, setStoreData] = useState<storeDataProps>(initStoreData);
  const [searchVal, setSearchVal] = useState('');

  const { selectItem, treeData, loading, editGroupItem } = storeData;

  const setValue = (field, val) => {
    setStoreData({
      ...storeData,
      [field]: val,
    });
  };

  const onSearch = (val) => {
    setSearchVal(val);
    getTreeData({ keyWord: val });
  };

  /** 列表数据请求 */
  const getTreeData = async (para = {}, selectItemPara = {}) => {
    if (!onFetchList)
      return;

    if (dependField && !dependPara[dependField])
      return;

    if (!onFeatchStart(dependPara))
      return;

    setValue('loading', true);
    const data = await onFetchList({
      ...para,
      ...dependPara,
    }).finally(() => {
      setValue('loading', false);
    });

    setStoreData({
      ...storeData,
      loading: false,
      treeData: data,
      initData: data,
      selectItem: selectItemPara.id ? selectItemPara : data.length ? data[0] : {},
      editGroupItem: null,
    });
  };

  const getOrder = (item, data) => {
    return (data.find(it => it.id === item.id) || {}).order || item.order;
  };

  /** 更新树数据 */
  const updateTreeData = (data, sortData) => {
    setTimeout(() => {
      const newData = data.map(itr => ({ ...itr, order: getOrder(itr, sortData) }));
      setStoreData({
        ...storeData,
        treeData: newData,
        initData: newData,
      });
    }, 100);
  };

  const onAddT = () => {
    if (editGroupItem && editGroupItem.id === 0) {
      message.info('已有新增项，请填写完成后再新增');
      return;
    }

    const dataTemp = [...treeData, { id: 0, name: '' }];
    setStoreData({
      ...storeData,
      editGroupItem: { type: 'leaf', id: 0, name: '' },
      treeData: dataTemp,
    });
  };

  const submitCallback = () => {
    getTreeData({ keyWord: searchVal }, selectItem);
  };

  useEffect(() => {
    updateSelectItem(selectItem);
  }, [selectItem]);

  useEffect(() => {
    if (dependField)
      return;

    getTreeData();
  }, []);

  try {
    useEffect(() => {
      if (dependField && dependPara[dependField]) {
        setSearchVal('');
        getTreeData();
      }
    }, [dependPara[dependField]]);
  }
  catch (e) {
    console.log(e);
  }

  const listHeight = useRef();
  const { elementValue } = useResizer({ elementRef: listHeight, elementAttr: 'clientHeight' });

  const [curHeight, setCurHeight] = useState(100);

  useEffect(() => {
    const curHeightT = listHeight.current?.clientHeight;
    curHeightT && setCurHeight(curHeightT - 120);
  }, [elementValue]);

  return (
    <div id={id} className={ClassNames(`TUI-DragMenu ${classNames}`)} style={{ width }}>
      <div className={ClassNames('Warp')} style={{ width, ...style }} ref={listHeight}>
        <Flex justify="space-between" align="center" className="Top">
          <Title level={5}>{title}</Title>
          {showAdd && (
            <div className="Add" onClick={onAddT}>
              <Tooltip title={addTips}>
                <i className="iconfont">&#xe6b0;</i>
              </Tooltip>
            </div>
          )}
        </Flex>

        <div className="SearchWarp">
          <Search
            value={searchVal}
            onChange={onSearch}
            placeholder="搜索"
          />
        </div>

        {loading
          ? <TUISkeleton />
          : (
            <div className="DargWarp">
              {/* style={{ height: DargWarpHeight }}> */}
              {treeData.length > 0
                ? (
                  <DragMenu
                    width={width}
                    nameWidth={nameWidth}
                    isDarag={isDarag}
                    storeData={storeData}
                    setStoreData={setStoreData}
                    onFetchService={{
                      onFetchAdd,
                      onFetchEdit,
                      onFetchDelete,
                      onFetchChangeOrder,
                    }}
                    auth={auth}
                    updateTreeData={updateTreeData}
                    submitCallback={submitCallback}
                    icon={iconT}
                    DragStyle={DragStyle}
                    DragMenuProps={{
                      virtualListStyle: {
                        width,
                        height: curHeight,
                        itemSize: 48,
                      },
                      ...DragMenuProps,
                    }}
                    {...rest}
                  />
                  )
                : <TUIEmpty />}
            </div>
            )}
      </div>
    </div>
  );
}

export default LeftMenu;
