import { ColorPicker, ConfigProvider } from 'antd';

export function TUIColor(tokenProps = {}, opts = {}) {
  return (
    <ConfigProvider
      theme={{
        token: {
          ...tokenProps,
        },
      }}
    >
      <ColorPicker {...opts} />
    </ConfigProvider>
  );
}
