import React, { useEffect, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import type {
  DragEndEvent,
  DragMoveEvent,
  DragOverEvent,
  DragStartEvent,
  UniqueIdentifier,
} from '@dnd-kit/core';
import {
  DndContext,
  DragOverlay,
  KeyboardSensor,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  SortableContext,
  arrayMove,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { restrictToVerticalAxis, restrictToWindowEdges } from '@dnd-kit/modifiers';
import {
  buildTree,
  flattenTree,
  getProjection,
  removeChildrenOf,
  removeItem,
  setProperty,
} from '../utilities';
import { SortableTreeItem } from '../TreeItem';
import type { FlattenedItem, Props, SensorContext } from './types';
import { sortableTreeKeyboardCoordinates } from './keyboardCoordinates';

import { adjustTranslate, dropAnimationConfig, measuring } from './common';

export function SortableTree({
  isDarag,
  collapsible,
  defaultItems = [],
  indicator = false,
  indentationWidth = 24,
  extraContent = {},
  storeData = {},
  onFetchChangeOrder,
  updateTreeData,
  removable,
  showCount = false,
  onClickLeaf,
  setMenu = () => [],
  updateStore = () => { },
  renderTooltip,
}: Props) {
  const { editGroupItem, initData, treeData, selectItem = {}, expandKey = [] } = storeData;

  const [items, setItems] = useState(() => defaultItems);
  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);
  const [overId, setOverId] = useState<UniqueIdentifier | null>(null);
  const [offsetLeft, setOffsetLeft] = useState(0);

  const flattenedItems = useMemo(() => {
    const flattenedTree = flattenTree(items);
    const collapsedItems = flattenedTree.reduce<string[]>(
      (acc, { children = [], collapsed, id }) =>
        collapsed && children.length ? [...acc, id] : acc,
      [],
    );

    return removeChildrenOf(
      flattenedTree,
      activeId ? [activeId, ...collapsedItems] : collapsedItems,
    );
  }, [activeId, items, expandKey]);

  const projected
    = activeId && overId
      ? getProjection(
        flattenedItems,
        activeId,
        overId,
        offsetLeft,
        indentationWidth,
      )
      : null;

  const sensorContext: SensorContext = useRef({
    items: flattenedItems,
    offset: offsetLeft,
  });
  const [coordinateGetter] = useState(() =>
    sortableTreeKeyboardCoordinates(sensorContext, indicator, indentationWidth),
  );
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter,
    }),
  );

  const sortedIds = useMemo(() => flattenedItems.map(({ id }) => id), [
    flattenedItems,
  ]);
  const activeItem = activeId
    ? flattenedItems.find(({ id }) => id === activeId)
    : null;

  useEffect(() => {
    sensorContext.current = {
      items: flattenedItems,
      offset: offsetLeft,
    };
  }, [flattenedItems, offsetLeft]);

  useEffect(() => {
    setItems(() => defaultItems);
  }, [defaultItems]);

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      measuring={measuring}
      onDragStart={handleDragStart}
      onDragMove={handleDragMove}
      onDragOver={handleDragOver}
      onDragEnd={handleDragEnd}
      onDragCancel={handleDragCancel}
      modifiers={[restrictToVerticalAxis, restrictToWindowEdges]}
    >
      <SortableContext items={sortedIds} strategy={verticalListSortingStrategy}>
        <div>
          {flattenedItems.map((item, idx) => {
            const { id, name, children = [], collapsed, depth } = item;
            return (
              <div onClick={() => onClickLeaf && onClickLeaf(item)} key={`${item.id}`}>
                <SortableTreeItem
                  key={id}
                  id={id}
                  value={id}
                  name={name}
                  item={item}
                  selectItem={selectItem}
                  isDarag={isDarag}
                  depth={id === activeId && projected ? projected.depth : depth}
                  indentationWidth={indentationWidth}
                  indicator={indicator}
                  collapsed={Boolean(collapsed && children.length)}
                  onCollapse={
                    collapsible && children.length
                      ? () => handleCollapse(id)
                      : undefined
                  }
                  extraContent={extraContent}
                  onRemove={removable ? () => handleRemove(id) : undefined}
                  showCount={showCount}
                  setMenu={item => setMenu(item)}
                  renderTooltip={renderTooltip}
                />
              </div>
            );
          },
          )}
        </div>
        {createPortal(
          <DragOverlay
            dropAnimation={dropAnimationConfig}
            modifiers={indicator ? [adjustTranslate] : undefined}
          >
            {activeId && activeItem
              ? (
                <SortableTreeItem
                  id={activeId}
                  depth={activeItem.depth}
                  clone
                  isDarag={isDarag}
                  value={activeId.toString()}
                  name={activeItem.name}
                  item={activeItem}
                  selectItem={selectItem}
                  indentationWidth={indentationWidth}
                  extraContent={extraContent}
                  // childCount={getChildCount(items, activeId) + 1}
                  showCount={showCount}
                  setMenu={item => setMenu(activeItem)}
                  renderTooltip={renderTooltip}
                />
                )
              : null}
          </DragOverlay>,
          document.body,
        )}
      </SortableContext>
    </DndContext>
  );

  function handleDragStart({ active: { id: activeId } }: DragStartEvent) {
    setActiveId(activeId);
    setOverId(activeId);

    document.body.style.setProperty('cursor', 'grabbing');
  }

  function handleDragMove({ delta }: DragMoveEvent) {
    setOffsetLeft(delta.x);
  }

  function handleDragOver({ over }: DragOverEvent) {
    setOverId(over?.id ?? null);
  }

  function handleDragEnd({ active, over, ...rest }: DragEndEvent) {
    resetState();

    if (projected && over) {
      const { depth, parentId } = projected;
      const clonedItems: FlattenedItem[] = JSON.parse(
        JSON.stringify(flattenTree(items)),
      );
      const overIndex = clonedItems.findIndex(({ id }) => id === over.id);
      const overIndex2 = clonedItems.findIndex(({ id }) => id === parentId);

      const activeIndex = clonedItems.findIndex(({ id }) => id === active.id);
      const activeTreeItem = clonedItems[activeIndex];
      if (depth !== activeTreeItem.depth)
        return;
      const overTreeItem = clonedItems[overIndex];
      const overIndexLast = depth === 0 ? overIndex : activeTreeItem.parentId === overTreeItem.parentId || (over.id === overTreeItem.id) ? overIndex : overIndex2;

      clonedItems[activeIndex] = { ...activeTreeItem, depth, parentId };

      const sortedItems = arrayMove(clonedItems, activeIndex, overIndexLast);
      const newItems = buildTree(sortedItems);
      const flattenTreeItems: FlattenedItem[] = JSON.parse(
        JSON.stringify(flattenTree(newItems)),
      );
      // console.log('拖拽完成---newItems', sortedItems, newItems, flattenTreeItems);
      updateTreeData && updateTreeData(newItems);
      setItems(newItems);

      onFetchChangeOrder && onFetchChangeOrder(
        depth,
        newItems,
        {
          activeTreeItem,
          overTreeItem: clonedItems[overIndexLast],
          activeIndex,
          overIndex: overIndexLast,
          clonedItems,
          sortedItems,
          flattenTreeItems,
        },
      );
    }
  }

  function handleDragCancel() {
    resetState();
  }

  function resetState() {
    setOverId(null);
    setActiveId(null);
    setOffsetLeft(0);

    document.body.style.setProperty('cursor', '');
  }

  function handleRemove(id: UniqueIdentifier) {
    setItems(items => removeItem(items, id));
  }

  function handleCollapse(id: UniqueIdentifier) {
    setItems((items) => {
      const itemsT = setProperty(items, id, 'collapsed', (value) => {
        return !value;
      });
      const findCollapsed = (items.find(it => it.id === id) || {}).collapsed;
      let expandKeyT = [];
      if (findCollapsed) { // 收起
        expandKeyT = expandKey.filter(it => it !== id);
      }
      else {
        expandKeyT = [...expandKey, id];
      }
      updateStore({
        ...storeData,
        expandKey: expandKeyT,
      });
      return itemsT;
    });
  }
}

export * as keyboardCoordinates from './keyboardCoordinates';
