import type { ReactNode } from 'react';
import { useState } from 'react';
import { ConfigProvider, Transfer } from 'antd';
import type { TransferProps } from 'antd';
import ClassNames from 'classnames';
import './index.less';

function formatOption(o) {
  o.title = o.title || o.name;
  o.key = o.key || o.id;
  if (o.children)
    o.children = o.children.map(formatOption);

  return o;
}

interface OptionsProps {
  id: string | number;
  name: string | number | ReactNode;
  disabled?: boolean;
}
interface TUITransferProps {
  value: TransferProps['targetKeys'];
  options?: Array<OptionsProps>;
  onChange: Function;
  opts?: TransferProps;
}
export default function TUITransfer({
  value = [],
  options = [],
  onChange,
  opts = {},
}: TUITransferProps) {
  const { token, listHeight: defaultListHeight = 350 } = opts;
  const _options = (options || []).map(formatOption);

  const [selectedKeys, setSelectedKeys] = useState<TransferProps['targetKeys']>([]);

  /** 数据变更 */
  const onTransferChange: TransferProps['onChange'] = (nextTargetKeys) => {
    onChange(nextTargetKeys);
  };

  /** 选择 */
  const onSelectChange: TransferProps['onSelectChange'] = (sourceSelectedKeys, targetSelectedKeys) => {
    setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Transfer: {
            /* 这里是你的组件 token */
            listHeight: defaultListHeight,
            ...token,
          },
        },
      }}
    >
      <div className={ClassNames('TUI-Transfer')}>
        <Transfer
          showSearch
          dataSource={_options}
          titles={['全部', '已选择']}
          targetKeys={value}
          onChange={onTransferChange}
          selectedKeys={selectedKeys}
          onSelectChange={onSelectChange}
          render={item => item.name}
          {...opts}
        />
      </div>
    </ConfigProvider>
  );
};
