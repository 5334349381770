import { useState } from 'react';
import styles from './index.module.less';

export function Tip({ children, onClose, tipImg, className }) {
  return (
    <div className={`${styles[`TUI-TipWrap`]} ${className && styles[`${className}`]}`}>
      {tipImg ? <img src={tipImg} /> : <span>提示</span>}
      {children}
      <div
        onClick={onClose}
        style={{
          cursor: 'pointer',
        }}
      >
        <i className="iconfont">&#xe620;</i>
      </div>
    </div>
  );
}
export function TipTrigger({ onClick, tipImg }) {
  return (
    <span className={`${styles['TUI-TipTriggerWrap']}`} onClick={onClick}>
      {tipImg ? <img src={tipImg} /> : <span>提示</span>}
    </span>
  );
}
export function useTip({ content, tipImg, className = '' }) {
  const [visible, setVisible] = useState(true);
  return {
    tipVisible: visible,
    tipContent: (
      <Tip
        tipImg={tipImg}
        className={className}
        onClose={() => {
          setVisible(false);
        }}
      >
        {content}
      </Tip>
    ),
    tipTrigger: (
      <TipTrigger
        tipImg={tipImg}
        onClick={() => {
          setVisible(true);
        }}
      />
    ),
  };
}
