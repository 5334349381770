import { Input, Popover } from 'antd';

function InputReminder({
  value,
  onChange,
  placeholder,
  opts,
  trigger,
}) {
  return (
    <Popover trigger={trigger} {...opts} style={{ opacity: '0.65' }}>
      <Input
        value={value}
        placeholder={placeholder || '请输入'}
        onChange={e => onChange(e.target.value)}
        {...opts}
      />
    </Popover>
  );
}

export default InputReminder;
