import { Checkbox } from 'antd';
import { formatOption } from '../../utils/utils';

export default function CheckGroup({ value, options = [], onChange, opts = {} }) {
  const _options = options.map(formatOption);
  return (
    <Checkbox.Group
      value={value}
      onChange={onChange}
      options={_options}
      {...opts}
    />
  );
};
