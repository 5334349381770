import { useEffect, useState } from 'react';

export default function useDatasource({
  service,
  defaultData = [],
}) {
  const [state, setState] = useState(defaultData);

  useEffect(() => {
    service().then((res) => {
      setState(res);
    });
  }, []);

  return {
    state,
    setState,
  };
}
