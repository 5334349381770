import { TreeSelect as AntTreeSelect } from 'antd';

const SHOW_PARENT = AntTreeSelect.SHOW_PARENT;
export default function TUITreeSelect({
  id,
  value,
  options = [],
  onChange = () => {},
  placeholder,
  disabled = false,
  opts = {},
}) {
  const { multiple, showCheckedStrategy, treeDefaultExpandAll, maxHeight = 400 } = opts;
  return (
    <AntTreeSelect
      id={id}
      value={value === 0 ? `${value}` : value}
      treeData={options}
      dropdownStyle={{ maxHeight, overflow: 'auto' }}
      placeholder={placeholder || '请选择'}
      allowClear
      multiple={multiple}
      treeCheckable={multiple}
      disabled={disabled}
      showCheckedStrategy={AntTreeSelect[showCheckedStrategy] || SHOW_PARENT}
      treeDefaultExpandAll={!!treeDefaultExpandAll}
      style={{ width: '100%' }}
      onChange={onChange}
      {...opts}
    />
  );
};
