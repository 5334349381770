import * as React from 'react';
import classNames from 'classnames';

import styles from './List.module.less';

const { forwardRef } = React;

export interface Props {
  children: React.ReactNode;
  columns?: number;
  style?: React.CSSProperties;
  horizontal?: boolean;
}

function ListTT({ children, columns = 1, horizontal, style }: Props, ref) {
  return (
    <ul
      ref={ref}
      style={
          {
            ...style,
            '--columns': columns,
          } as React.CSSProperties
        }
      className={classNames(styles.List, horizontal && styles.horizontal)}
    >
      {children}
    </ul>
  );
}

const ListT = forwardRef<HTMLUListElement, Props>(ListTT);
export const List = ListT;
