import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Button, InputNumber, Space } from 'antd';
// import { useDebounceFn } from "ahooks";
import styles from './index.module.less';

function useDebounceFn(func, wait, immediate) {
  const timeout = useRef();
  let context;
  let result;
  function resDebounced(...args) {
    // 这个函数里面的this就是要防抖函数要的this
    // args就是事件对象event
    context = this;

    // 一直触发一直清除上一个打开的延时器
    if (timeout.current)
      clearTimeout(timeout.current);

    if (immediate) {
      // 第一次触发，timeout===undefined恰好可以利用timeout的值
      const callNow = !timeout.current;
      timeout.current = setTimeout(() => {
        timeout.current = null;
      }, wait);
      if (callNow)
        result = func.apply(context, args);
    }
    else {
      // 停止触发，只有最后一个延时器被保留
      timeout.current = setTimeout(() => {
        timeout.current = null;
        // func绑定this和事件对象event，还差一个函数返回值
        result = func.apply(context, args);
      }, wait);
    }
    return result;
  }
  resDebounced.cancal = function () {
    clearTimeout(timeout.current);
    timeout.current = null;
  };
  return resDebounced;
}

function InputRange({ value, onChange = () => {}, opts = {} }) {
  const { debounce = true } = opts; // 用于非筛选时需设定opst={{ debounce: false }}
  const [state, setState] = useState('-');

  const onDebounceChange = useDebounceFn(onChange, debounce ? 500 : 0);

  const [min, max] = useMemo(() => {
    return state ? state.split('-') : ['', ''];
  }, [state]);

  const onCurChange = (mode, value) => {
    const resEnum = {
      min: () => `${value ?? ''}-${max ?? ''}`,
      max: () => `${min ?? ''}-${value ?? ''}`,
    };
    onDebounceChange(resEnum[mode]());
    setState(resEnum[mode]());
  };

  useEffect(() => {
    setState(value);
  }, [value]);

  return (
    <Space.Compact className={`${styles.labelInputRange} labelInputRange`}>
      <InputNumber
        placeholder="最小值"
        value={min}
        max={max}
        onChange={(value) => {
          onCurChange('min', value);
        }}
      />
      <Button type="default" className={`${styles.dash} dash`}>
        ~
      </Button>
      <InputNumber
        placeholder="最大值"
        value={max}
        min={min}
        onChange={(value) => {
          onCurChange('max', value);
        }}
      />
    </Space.Compact>
  );
}

export default InputRange;
// 尾注：非filter区域需关闭debounce
