import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localeData from 'dayjs/plugin/localeData';
import weekday from 'dayjs/plugin/weekday';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekYear from 'dayjs/plugin/weekYear';
import * as _Validator from './lib/validator';
import './index.less';

dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);

// antd component set default props
export * as antd from './antd';

// export const antd = antd1;

// 基础组件
export { default as Button } from './components/Button';
export { Label } from './components/Label';
export { TUIColor } from './components/TUColor';
export { ThemeBtn } from './components/ThemeBtn';
export { default as message } from './components/message';
export { default as Tabs } from './components/Tabs';

export { default as Card } from './components/Card';
export { Image } from './components/Image';
export { default as Table } from './components/Table';
export { Table as AntTable } from './components/Table/index2';
export { default as Modal } from './components/Modal';
export { default as AntDrawer } from './components/Drawer';
export { default as UploadImg } from './components/UploadImg';
export { default as TUIIcon } from './components/TUIIcon';
export { default as TUIIconPark } from './components/TUIIconPark';
export { default as TUIModalConfirm } from './components/TUIModalConfirm';
export { default as TUISkeleton } from './components/TUISkeleton';
export { default as LinkBtn } from './components/LinkBtn';

// 基础表单组件 必须带 value, onChange, opts-其他属性
export { default as Select } from './components/Select';
export { default as MultSelect } from './components/MultSelect';
export { default as Input } from './components/Input';
export { default as AutoComplete } from './components/AutoComplete';
export { default as TextArea } from './components/TextArea';
export { default as Textarea } from './components/TextArea';
export { default as Search } from './components/Search';
export { default as InputNumber } from './components/InputNumber';
export { default as TUIInputReminder } from './components/TUIInputReminder';
export { default as String } from './components/String';
export { default as Cascader } from './components/Cascader';
export { default as DatePicker } from './components/DatePicker';
export { default as RangePicker } from './components/RangePicker';
export { default as DateTimePicker } from './components/DateTimePicker';
export { default as TreeSelect } from './components/TreeSelect';
export { default as TreeCheck } from './components/TreeCheck';
export { default as TUITreeSelect } from './components/TUITreeSelect';
export { default as Radio } from './components/Radio';
export { default as RadioButton } from './components/RadioButton';
export { default as Switch } from './components/Switch';
export { default as DrawableUploader } from './components/DrawableUploader';
export { default as DraggerUploader } from './components/DraggerUploader';
export { default as Upload } from './components/Upload';
export { default as ArrFields } from './components/ArrFields';
export { default as BlockSelect } from './components/BlockSelect';
export { default as Checkbox } from './components/Checkbox';
export { default as CheckGroup } from './components/CheckGroup';
export { default as TUIRangeInput } from './components/TUIRangeInput';
export { default as InputPassword } from './components/InputPassword';
export { default as InputSlider } from './components/InputSlider';
export { default as TUITransfer } from './components/TUITransfer';

// 组合控件
export { default as Crud } from './components/Crud';
export { default as Form } from './components/Form';
export { default as Filter } from './components/Filter';
export { default as FormItem } from './components/FormItem';
export { default as TUIDrawer } from './components/TUIDrawer';
export { default as TUIAddModal } from './components/Crud/AddModal';
export { default as AdvancedCrud } from './components/AdvancedCrud';
export { default as AdvancedForm } from './components/AdvancedCrud/AdvancedForm';
export { default as AdvancedFilter } from './components/AdvancedFilter';
export { default as TableEditor } from './components/TableEditor';
export { default as TUIEditTable } from './components/TUIEditTable';
export { default as TUIDragTableProvider, TUIDragTableBodyRow } from './components/TUIDragTableProvider';
export { default as ColumnSetting } from './components/ColumnSetting';
export { default as ColumnDensity } from './components/ColumnDensity';

// 业务组件
export { AuthButton, useCurrentMenuAuth, AuthComponent, useNameAuth, useIdAuth, getNameAuth } from './components/AuthButton';
export { StatisticCard } from './components/StatisticCard';
export { LeftList } from './components/LeftList';
export { LeftTree } from './components/LeftTree';
export { default as ExportButton } from './components/ExportButton';
export { default as Compose } from './components/Compose';
export { default as Combine } from './components/Combine';
export { default as Carousel } from './components/Carousel';
export { default as GridLayout } from './components/GridLayout';
export { default as Group } from './components/Group';
export { default as BatchAddModel } from './components/BatchAddModel';
export { useTip, Tip, TipTrigger } from './components/TopTips';
export { default as TUIList } from './components/TUIList';
export { default as ContentTitle, Back, BadgeIMG } from './components/ContentTitle';
export { default as TUICardTable } from './components/TUICardTable';
export { default as TUICardGrid } from './components/TUICardGrid';
export { default as renderDropdown } from './components/TUICardGrid/renderDropdown';
export { default as TUIPageLayout } from './components/TUIPageLayout';
export { default as TUIBannerTop } from './components/TUIBannerTop';
export { default as LoginPage } from './components/LoginPage';
export { default as CodeRedirectPage } from './components/CodeRedirectPage';
export { default as TUILogin } from './components/LoginPage/loginPage';
export { default as DragLeft } from './components/DragLeft';
export { default as DragTree, DragTreeUitls, DragTreeCommon, DragTreeTypes } from './components/DragTree';
export { default as DragTreeMenu } from './components/DragTree/DragTree';
export { SortableTree, keyboardCoordinates } from './components/DragTree/SortableTree';
export * from './components/DragTree/TreeItem';
export * from './components/DragMenu';
export { default as VirtualList } from './components/VirtualList';
export { themeConfig, getGlobalThemeToken } from './globalTheme/Theme';
export { globalCommonStyle } from './globalTheme/glCommonStyle';
export { default as TUILayout } from './components/TUILayout';
export { default as TUIThreeLevelMenu } from './components/TUILayout/TUIThreeLevelMenu';
export { default as TUITools } from './components/TUILayout/Tools';
export { AppBox, CheckBoxDiv, JumpSystem, ModifyPassword, RevisePhoneNumber, ThemeModal, UserInfo } from './components/TUILayout/Tools/components';
export { default as TUIEmpty } from './components/TUIEmpty';
export { default as TUIApp } from './components/TUIApp';
export { default as ImageList } from './components/ImageList';
export { TUITag } from './components/TUITag';
export * from './context';

// 函数
export { default as useCrudController } from './hooks/useCrudController';
export { default as useDatasource } from './hooks/useDatasource';
export { default as useFilterController } from './hooks/useFilterController';
export { default as useFormController } from './hooks/useFormController';
export { default as useListController } from './hooks/useListController';
export { default as useModalController } from './hooks/useModalController';
export { default as usePrevious } from './hooks/usePrevious';
export { default as usePaginationHooks } from './hooks/usePaginationHooks';
export { default as useThemeHook } from './hooks/useThemeHook';
export { default as useResizer } from './hooks/useResizer';

export { formatOption, formatTreeOption, objectValueAllEmpty, addLabelValue, getTreeFromPlain, jugeIsVideo } from './utils/utils';
export * as GridLayoutUitls from './components/GridLayout/layout';
export * as SchemaHelper from './components/AdvancedCrud/schemahelper';
export const Validator = _Validator;
export * as request from './utils/request';
export { default as ValidateRules, DB_KEYWORDS_ARR, validateCascaderKey } from './utils/validator';
export { getLocalUserPermissions, findBreadcrumbByUrl, encrypt, checkUpdate } from './utils/index';
export * as utils from './utils/index';
export * from './utils/routes';
export * from './utils/tuiHelperFunction';
export * from './utils/http-client';
export { IconTypeObj, getIconType } from './Contanst';
export * from './types';
