import { theme } from 'antd';

const { useToken } = theme;
export default function useThemeHook() {
  const { theme, token, hashId } = useToken();

  return {
    theme,
    hashId,
    token,
  };
};
