import { useEffect, useRef, useState } from 'react';
import { isValidate, validateSchema } from '../lib/validator';

export default function useFormController({
  schema,
  doSubmit = () => Promise.resolve(),
  defaultValue = {},
  onStateChange,
}) {
  const handler = useRef({

  });
  const [state, setState] = useState({
    fields: { ...defaultValue },
    errors: [],
    submitting: false,
    defaultValue,
  });
  const mountedRef = useRef(false);
  useEffect(() => {
    if (typeof onStateChange === 'function' && mountedRef.current)
      onStateChange(state);

    mountedRef.current = true;
  }, [state]);

  const setFields = (fields) => {
    setState({
      ...state,
      fields: {
        ...state.fields,
        ...fields,
      },
    });
  };

  const setField = (key, value) => {
    setState({
      ...state,
      fields: {
        ...state.fields,
        [key]: value,
      },
    });
  };

  const reset = (defaultValue = {}, defaultErrors = {}) => {
    setState({
      ...state,
      fields: {
        ...defaultValue,
      },
      errors: {
        ...defaultErrors,
      },
    });
  };

  const validate = () => {
    const { fields } = state;
    const errors = validateSchema(schema, fields);
    setState({
      ...state,
      errors,
    });
    return errors;
  };

  const reValidate = () => {
    const { fields, errors } = state;
    const subSchema = {};
    const subFields = {};
    for (const key in errors) {
      subSchema[key] = schema[key];
      subFields[key] = fields[key];
    }
    const subErrors = validateSchema(subSchema, subFields);
    setState({
      ...state,
      errors: subErrors,
    });
    return subErrors;
  };

  const submit = () => {
    const { fields } = state;
    const errors = validate();
    if (!isValidate(schema, fields))
      return Promise.reject(errors, fields);

    setState({
      ...state,
      errors,
      submitting: true,
    });
    return doSubmit(fields)
      .then((r) => {
        setState({
          ...state,
          errors,
          submitting: false,
        });
        return r;
      })
      .catch((e) => {
        setState({
          ...state,
          errors,
          submitting: false,
        });
        throw e;
      });
  };
  // 保持form实例
  return Object.assign(handler.current, {
    ...state,
    state,
    setState,
    setFields,
    setField,
    reset,
    validate,
    reValidate,
    submit,
  });
}
