import { useRef, useState } from 'react';
import useFormController from './useFormController';

interface FilterControllerProps {
  needValidate?: boolean;
  schema: Array<object>;
  defaultValue?: object;
  doSubmit?: Function;
  onStateChange?: Function;
}
export default function useFilterController({
  needValidate = false,
  schema,
  defaultValue = {},
  doSubmit = () => Promise.resolve(),
  onStateChange,
}: FilterControllerProps) {
  const {
    state,
    setState,
    setFields,
    setField,
    validate,
    submit,
  } = useFormController({
    schema,
    doSubmit,
    defaultValue,
    onStateChange,
  });
  const [tempValue, setTempValue] = useState({});
  const handler = useRef({});
  const ref = useRef();
  const search = () => {
    const { fields } = state;
    if (needValidate)
      return submit();
    else
      return doSubmit(fields);
  };

  const reset = (defaultValue = {}, defaultErrors = {}) => {
    setState({
      ...state,
      fields: {
        ...defaultValue,
      },
      errors: {
        ...defaultErrors,
      },
    });
    return Promise.resolve(defaultValue);
  };

  const clearAndFetch = (defaultValue = {}, defaultErrors = {}) => {
    setState({
      ...state,
      fields: {
        ...defaultValue,
      },
      errors: {
        ...defaultErrors,
      },
    });
    return Promise.resolve(ref.current.doClear());
  };

  return Object.assign(handler.current, {
    ...state,
    setState,
    setFields,
    setField,
    reset,
    validate,
    search,
    clearAndFetch,
    tempValue,
    setTempValue,
    ref,
  });
}
