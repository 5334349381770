export const configObj = {
  loginTitleColor: [
    { id: 1, field: 'light', name: '黑' },
    { id: 2, field: 'dark', name: '白' },
  ],
  loginType: [
    { id: 1, field: 'rightModal', name: '登录框在右' },
    { id: 2, field: 'rightDrawer', name: '抽屉登录框' },
    { id: 3, field: 'Center', name: '登录框居中' },
  ],
  LoginBckType: [
    { id: 1, field: 'img', name: '图片' },
    { id: 2, field: 'video', name: '视频' },
  ],
  loginBGMask: [
    { id: 1, field: 'default', name: '随主题' },
    { id: 2, field: 'whiteMask', name: '白蒙版' },
    { id: 3, field: 'darkMask', name: '黑蒙版' },
  ],
};

/** 默认的登录页配置 */
export const defaultConfig = {
  loginTitleColor: 2,
  loginType: 1,
  systemName: '系统标题',
  systemSubTitle: '',
  LoginBckType: 2,
  logo: 'https://static-1255466169.cos.ap-guangzhou.myqcloud.com/assets/logo/ten_logo_white.png',
  backImage: 'https://static-1255466169.cos.ap-guangzhou.myqcloud.com/ThemeAssets/loginVideo.mp4',
  footer: '',
  formWidth: 430,
  loginBGMask: 1,
};

/** 获得登录需要的配置信息 */
export function getConfigValueByField(ConfigField, val, field = 'field') {
  const fItem = configObj[ConfigField].find(it => it.id === val);
  return fItem ? fItem[field] : '';
}
