/** url取参数 */
export default function getUrlParams() {
  let url = window.location.search;
  if (url.indexOf('?') == 1)
    return false;

  url = url.substr(1);
  const urlArr = url.split('&');
  const obj = {}; // 返回的参数对象
  // 获取全部参数及其值
  for (let i = 0; i < urlArr.length; i++) {
    const info = urlArr[i].split('=');
    obj[info[0]] = decodeURI(info[1]);
  }
  // 返回结果
  return obj;
}
