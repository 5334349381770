import { useEffect, useState } from 'react';
import { Button, Result } from 'antd';
import classNames from 'classnames';

const countDown = 3;
export default function ResultPage({ toLogin }) {
  const [timeCount, setTimeCount] = useState(0);

  let interval;
  /** 开启倒计时 */
  const runGetCaptchaCountDown = (time = 1) => {
    let cot = countDown;
    setTimeCount(cot);
    interval = setInterval(() => {
      cot -= 1;
      setTimeCount(cot);
      if (cot === 0) {
        clearInterval(interval);
        toLogin();
      }
    }, 1000 * time);
  };

  useEffect(() => {
    runGetCaptchaCountDown();
    return () => clearInterval(interval);
  }, []);

  return (
    <Result
      status="success"
      title="您已成功修改密码，可直接登录"
      extra={[
        <Button
          type="primary"
          key="loginBtn"
          onClick={() => toLogin()}
          className={classNames('TUI-LoginBtn')}
          style={{ width: '200px' }}
        >
          去登录
          {timeCount ? `（${timeCount}s）` : ''}
        </Button>,
      ]}
    />
  );
};
