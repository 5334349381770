import { useEffect, useState } from 'react';
import { Input } from 'antd';
import './index.less';

const AntSearch = Input.Search;

export default function Search({ id, field, value, placeholder, onChange = () => {}, opts = {} }) {
  const [innerValue, setInnerValue] = useState(value);
  useEffect(() => {
    setInnerValue(value);
  }, [value]);
  return (
    <AntSearch
      id={id || 'Search_Id'}
      name={field}
      value={innerValue}
      placeholder={placeholder || '搜索关键词'}
      onChange={(e) => {
        setInnerValue(e.target.value);
      }}
      onSearch={(v) => {
        onChange(v);
      }}
      onPressEnter={() => {
        onChange(innerValue);
      }}
      allowClear={true}
      {...opts}
    />
  );
}
