import { Cascader as AntCascader } from 'antd';
import { formatOption } from '../../utils/utils';

export default function TUICascader({
  id,
  value,
  onChange = () => {},
  options = [],
  placeholder = '请选择',
  opts = {},
}) {
  const _options = (options || []).map(formatOption);
  return (
    <AntCascader
      id={id}
      value={value}
      onChange={onChange}
      options={_options}
      placeholder={placeholder}
      {...opts}
    />
  );
};
