import { useRef } from 'react';
import useFilterController from './useFilterController';
import useFormController from './useFormController';
import useModalController from './useModalController';
import useListController from './useListController';

export default function useCrudController({
  FilterSchema,
  FormSchema,
  ListSchema,
  service = {},
  filterNeedValidate = false,
  modalVisible = false,
  modalLoading = false,
  defaultFilterValue,
  defaultFormValue,
  onFormStateChange,
  onFilterStateChange,
  AntApp,
  listProps,
}) {
  const handler = useRef({});
  const filter = useFilterController({
    needValidate: filterNeedValidate,
    schema: FilterSchema,
    doSubmit: service.list,
    defaultValue: defaultFilterValue,
    onStateChange: onFilterStateChange,
  });

  const form = useFormController({
    schema: FormSchema,
    defaultValue: defaultFormValue,
    doSubmit: service.submit,
    onStateChange: onFormStateChange,
  });

  const modal = useModalController({
    visible: modalVisible,
    loading: modalLoading,
  });

  const list = useListController({
    schema: ListSchema,
    defaultValue: defaultFilterValue,
    doList: service.list,
    doHandleItemById: service.doHandleItemById,
    query: filter.fields,
    AntApp,
    listProps,
  });

  return Object.assign(handler.current, {
    filter,
    form,
    modal,
    list,
    FormSchema,
    FilterSchema,
    ListSchema,
  });
}
