import * as React from 'react';
import { Button as AntButton, Col, ColorPicker, ConfigProvider, Divider, Input, InputNumber, Modal, Row } from 'antd';
import { DefalutTokenProps } from '../../TokenTheme';
import { configTheme } from './config';

const { useState } = React;
export function ThemeBtn({ children, type = 'primary', tokenProps = {}, modalProps = {} }) {
  const [visible, setVisible] = useState(false); // 配置的弹窗
  const [data, setData] = useState({});

  const handleCancel = () => {
    setVisible(false);
  };

  const handleOk = () => {
    // console.log('提交---', data)
    sessionStorage.setItem('sessionToken', JSON.stringify(data));
    setVisible(false);
  };

  const showValue = (field, oriVal) => {
    return field ? data[field] : '';
  };

  const onChangeValue = (field, val, type) => {
    setData({
      ...data,
      [field]: type === 'number' ? +val : val,
    });
  };

  const onClick = () => {
    let sessionToken = sessionStorage.getItem('sessionToken');
    sessionToken = sessionToken ? JSON.parse(sessionToken) : DefalutTokenProps;

    // console.log('sessionToken---', sessionToken);
    setData(sessionToken);

    setVisible(true);
  };

  return (
    <ConfigProvider
      theme={{
        token: {
          ...tokenProps,
        },
      }}
    >
      <AntButton type={type} onClick={onClick}>
        {children}
      </AntButton>
      <Modal
        title="主题变量配置"
        open={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={1300}
        {...modalProps}
      >
        <Row gutter={16}>
          <Col span={3}>field</Col>
          <Col span={5}>名称</Col>
          <Col span={8}>描述</Col>
          <Col span={3}>类型</Col>
          <Col span={5}>值</Col>
        </Row>
        <Divider dashed />
        {configTheme.map((item) => {
          return (
            <Row gutter={16} key={item.field}>
              <Col span={3}>{item.field}</Col>
              <Col span={5}>{item.name}</Col>
              <Col span={8}>{item.remark}</Col>
              <Col span={3}>{item.type}</Col>
              <Col span={5}>
                {item.type === 'color' ? (
                  <ColorPicker
                    format="hex" // rgb
                    showText
                    value={showValue(item.field, item.value)}
                    onChange={(e, hex) => onChangeValue(item.field, hex, item.type)}
                  />
                ) : item.type === 'number'
                  ? (
                    <InputNumber
                      value={showValue(item.field, item.value)}
                      onChange={e => onChangeValue(item.field, e, item.type)}
                    />
                    )
                  : (
                    <Input
                      value={showValue(item.field, item.value)}
                      onChange={e => onChangeValue(item.field, e.target.value, item.type)}
                    />
                    )}
              </Col>
            </Row>
          );
        })}
      </Modal>
    </ConfigProvider>
  );
}
