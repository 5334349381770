import { createContext } from 'react';

const TableContext = createContext({} as any);
const Container: React.FC<{
  initValue: any;
  children: React.ReactNode;
}> = ({ initValue = {}, children }) => {
  const value = {
    ...initValue,
  };
  return (
    <TableContext.Provider value={value}>
      {children}
    </TableContext.Provider>
  );
};

export { Container, TableContext };
