import { Flex } from 'antd';
import ClassNames from 'classnames';
import { useAppConfigContext } from '../../context/AppConfigContext';
import { useThemeContext } from '../../context/ThemeContext';
import { useLayoutContext } from '../../context/LayoutContext';
import type { ComponentBaseProps } from '../../types';
import Menus from './Menus';
import Tools from './Tools';
import { getbgWider } from './getThemeStyles';

function HeadLogo() {
  const { layoutMode } = useThemeContext();
  const { headLogo, headShinkLogo } = useAppConfigContext();
  const { menuCollapsed } = useLayoutContext();
  const img = ['default', 'top'].includes(layoutMode)
    ? headLogo
    : menuCollapsed ? headShinkLogo : headLogo;
  return (
    <img
      className={ClassNames(`TUI-Layout-Header-Logo`, { menuCollapsed })}
      src={img}
    />
  );
}

function Header() {
  const { sysMode, layoutMode } = useThemeContext();
  const { systemName } = useAppConfigContext();
  return (
    <>
      {['default', 'top'].includes(layoutMode) && <HeadLogo />}
      <div className={ClassNames(`TUI-Layout-Header-Title TUI-Header-Title-${layoutMode} TUI-Header-Title-${sysMode}`)}>{systemName}</div>
      <Tools />
    </>
  );
}

function BgWiderImg() {
  const { markMode } = useThemeContext();
  return <img className={ClassNames('TUI-Layout-Header-BackImg')} src={getbgWider(markMode)} />;
}

function TUILayout({
  classNames,
  style,
  children,
}: ComponentBaseProps) {
  const { layoutMode } = useThemeContext();
  const { menuCollapsed } = useLayoutContext();

  return (
    <div className={ClassNames(`TUI-LayoutWarp`, classNames)} style={{ ...style }}>
      {/*  上下，左侧展开 */}
      {
        layoutMode === 'default' && (
          <div className={ClassNames(`TUI-Layout-${layoutMode}`)}>
            <div className="TUI-Layout-Header">
              <Header />
              <BgWiderImg />
            </div>

            <Flex className={ClassNames(`TUI-LeftRightLayout`)}>
              <div className={ClassNames(`TUI-Side`, { menuCollapsed })}>
                <Menus />
              </div>
              {children}
            </Flex>
          </div>
        )
      }

      {/*  左右，左侧展开/收起 */}
      {
        ['ExpandMenu', 'shrinkMenu'].includes(layoutMode) && (
          <Flex className={ClassNames(`${menuCollapsed ? 'TUI-Layout-shrinkMenu' : 'TUI-Layout-ExpandMenu'}`)}>
            <div className={ClassNames(`TUI-Side`, { menuCollapsed })}>
              <Menus
                top={(
                  <div className={ClassNames(`LogoWarp`, { menuCollapsed })}>
                    <HeadLogo />
                  </div>
                )}
              />
              <BgWiderImg />
            </div>

            <div className={ClassNames(`TUI-LeftRightLayout`, { menuCollapsed })}>
              <div className="TUI-Layout-Header">
                <Header />
              </div>
              {children}
            </div>
          </Flex>
        )
      }

      {/*  上下 */}
      {
        layoutMode === 'top' && (
          <div className={ClassNames(`TUI-Layout-${layoutMode}`)}>
            <div className="TUI-Layout-Header">
              <Header />
              <BgWiderImg />
              <Menus />
            </div>

            <Flex className={ClassNames(`TUI-Layout-Content`)}>
              {children}
            </Flex>
          </div>
        )
      }

    </div>
  );
}

export default TUILayout;
