import { useEffect, useState } from 'react';
import { Flex, Popover, message } from 'antd';
import { useAppConfigContext } from '../../../../context';
import { getAppQrCode } from './service';

function BoxContainer({ ercode, APPBoxTips }) {
  if (!ercode)
    return null;
  return (
    <div>
      <img
        src={`data:image/jpeg;base64,${ercode}`}
        style={{ width: 128, height: 128, margin: '0 auto', display: 'block' }}
        alt=""
      />
      <div style={{ textAlign: 'center' }}>
        <div>扫码下载</div>
        <div>{APPBoxTips}</div>
      </div>
    </div>
  );
}

export default function APPBox() {
  const { appBoxProps = {} } = useAppConfigContext();
  const { api = '/web/application/getAppQrCode', appBoxTips = '智慧养护管理平台APP', customizeAppBox = '' } = appBoxProps;

  const [ercode, saveErCode] = useState('');

  const handleClick = async () => {
    if (ercode)
      return;
    try {
      const res = await getAppQrCode(api);
      if (res.status === 0)
        saveErCode(res.data);

      else
        saveErCode('');
    }
    catch (e) {
      message.error('获取APP二维码失败，请重试！');
      saveErCode('');
    }
  };

  useEffect(() => {
    handleClick();
  }, []);

  return (
    <Popover
      forceRender
      placement="bottomLeft"
      trigger="click"
      content={customizeAppBox ? customizeAppBox(ercode) : <BoxContainer ercode={ercode} APPBoxTips={appBoxTips} />}
    >
      <Flex align="center">
        <i className="iconfont">&#xe667;</i>
        <span onClick={handleClick}>
          APP
        </span>
      </Flex>
    </Popover>
  );
};
