import type { ReactNode } from 'react';
import { Dropdown, Tooltip } from 'antd';
import { ColumnHeightOutlined } from '@ant-design/icons';

interface ColumnDensityProps {
  settingIcon?: ReactNode;
  size: 'middle' | 'large' | 'small';
  onChangeSize: Function;
}
export default function ColumnDensity({
  settingIcon,
  size = 'middle',
  onChangeSize,
}: ColumnDensityProps) {
  const items = [
    {
      key: 'small',
      label: '紧凑',
    },
    {
      key: 'middle',
      label: '默认',
    },
    {
      key: 'large',
      label: '宽松',
    },
  ];

  const onClick = ({ key }) => {
    onChangeSize && onChangeSize(key);
  };

  return (
    <Dropdown
      menu={{
        items,
        onClick,
        selectable: true,
        selectedKeys: [size],
      }}
    >
      <Tooltip title="密度">
        {settingIcon ?? <ColumnHeightOutlined style={{ height: 32 }} />}
      </Tooltip>
    </Dropdown>
  );
}
