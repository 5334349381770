import { Button, Dropdown } from 'antd';
import { TUIIcon } from '../../index';

export default function renderDropdown({ oprateMenu = [], menuItems = [], text = '', opts = {} }) {
  const items = oprateMenu.map((item, index) => ({
    key: index,
    label: item,
  }));
  const renderMode = text ? <Button key="last-oprate" type="link" icon={<TUIIcon type="icon-ellipsis" />}>{text}</Button> : <TUIIcon key="last-oprate" type="icon-ellipsis" />;

  return oprateMenu.length || menuItems.length
    ? (
      <Dropdown
        key="last-oprate"
        menu={{
          items: menuItems.length ? menuItems : items,
        }}
        // getPopupContainer={trigger =>  document.getElementById('root')}
        {...opts}
      >
        {renderMode}
      </Dropdown>
      )
    : renderMode;
}
