export const initStoreData = {
  selectItem: {}, // 选中的数据
  treeData: [],
  initData: [],
  loading: false,
  expandKey: [],
  editGroupItem: null, // 正在编辑的数据
};

/** 获取第一个有值的chilren */
export function getFirstChildrenItem(data = [], parentId = 0, subName = '') {
  let itemTemp;
  for (let i = 0; i < data.length; i++) {
    const item = data[i];
    if (itemTemp)
      break;

    if (parentId) {
      if (item.id !== parentId)
        continue;

      itemTemp = subName ? (item.children || []).find(it => it.name === subName) : (item.children || [])[0];
    }
    else {
      if (item.children && Array.isArray(item.children) && item.children.length)
        itemTemp = item.children[0];
    }
  }
  return itemTemp;
}

/** 获取第一个有值的parent */
export function getFirstParentItem(data = []) {
  for (let i = 0; i < data.length; i++) {
    const item = data[i];

    if (item.children && Array.isArray(item.children) && item.children.length)
      return item;
  }
  return {};
}
