import { Col, Row } from 'antd';
import styles from './index.module.less';
import ArrowImg from './arrowImg.png';

export function StatisticCard({ HeadField = [], statisticData = {} }) {
  return (
    <Row gutter={16} style={{ padding: 24, paddingBottom: 0, display: 'flex' }}>
      {HeadField.map((item) => {
        return (
          <Col
            key={item.name}
            style={{
              minWidth: 270,
            }}
          >
            <div className={styles.StatisWarper}>
              <div className={styles.Left}>
                <div className={styles.Title}>{item.name}</div>
                <div className={styles.Count}>{statisticData[item.field] || 0}</div>
              </div>
              <img className={styles.BackImg} src={ArrowImg} />
            </div>
          </Col>
        );
      })}
    </Row>
  );
}
