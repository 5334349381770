import { merge } from 'lodash-es';

interface SetDataType<T> {
  type: 'setData';
  payload?: Partial<T>;
}

interface ReplaceDataType<T> {
  type: 'replaceData';
  payload: T;
}

interface InitDataType {
  type: 'initData';
  payload?: never;
}

export type DispatchType<T> = SetDataType<T> | ReplaceDataType<T> | InitDataType;

/**
 * 获取reducer函数
 *
 * @param initialState 初始状态
 * @param next 回调函数，当状态更新时触发 例如做持久化
 * @returns 返回reducer函数，接受旧状态和action作为参数，返回新状态
 */
export function getReducer<C, D extends DispatchType<C>>(initialState: C, next?: (newStatus: C) => void): (oldStatus: C, action: D) => C {
  return function reducer(oldStatus: C, action: D): C {
    const { type, payload } = action;
    switch (type) {
      case 'initData': {
        next?.(initialState);
        return initialState;
      }
      case 'setData': {
        const newStatus = merge({}, oldStatus, payload);
        next?.(newStatus);
        return newStatus;
      }
      case 'replaceData': {
        next?.(payload);
        return payload;
      }
      default: {
        throw new Error(`Unknown action: ${type}`);
      }
    }
  };
}
