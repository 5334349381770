import axios from 'axios';

const fetch = window.fetch;

export function getToken() {
  return sessionStorage.getItem('token') || localStorage.getItem('token');
}
const systemId = window.systemId;
const globalParam = window.globalParam || {};
function formatPagination(res) {
  if (typeof res.pagination === 'object') {
    res.pagination = {
      ...res.pagination,
      current: res.pagination.currentPage,
      pageSize: res.pagination.listRows,
      total: res.pagination.totalRows,
    };
  }
  return res;
}
const _hooks = {};
export function addRequestHook(url, fn) {
  _hooks[url] = fn;
}
export function getRequestHook(url) {
  return _hooks[url];
}
const _reqHooks = {};
export function addRequestHookBefore(url, fn) {
  _reqHooks[url] = fn;
}
export function getRequestHookBefore(url) {
  return _reqHooks[url];
}
const _resHooks = {};
export function addRequestHookAfter(url, fn) {
  _resHooks[url] = fn;
}
export function getRequestHookAfter(url) {
  return _resHooks[url];
}
// 加自定义请求处理
let _requestDataHooks;
export function addRequestDataAfter(fn) {
  _requestDataHooks = fn;
}
export function getRequestDataAfter() {
  return _requestDataHooks;
}
export default function request(url, params, ...rest) {
  const defaultOptions = {
    method: 'POST',
    // credentials: 'include',
    headers: {
      Accept: 'application/json',
      Authorization: `${getToken()}`,
    },
  };
  const requestBefore = getRequestHookBefore(url);
  const requestAfter = getRequestHookAfter(url);
  const body = requestBefore ? requestBefore(params) : params;
  const newOptions = { ...defaultOptions, body };
  if (!(body instanceof FormData)) {
    newOptions.headers = {
      ...newOptions.headers,
      'Content-Type': 'application/json; charset=utf-8',
    };
    newOptions.body = JSON.stringify({
      ...(window.globalParam || {}),
      ...newOptions.body,
      systemId: window.systemId,
    });
  }
  const hok = getRequestHook(url);
  if (hok)
    return hok(body, ...rest);

  return fetch(url, newOptions)
    .then(res => res.json())
    .then((res) => {
      if (res.status) {
        const dataHok = getRequestDataAfter();
        if (dataHok) {
          return dataHok(res);
        }
        else {
          if (res.status === 1003) {
            if (window.goBackLogin) {
              window.goBackLogin();
            }
            else {
              const userFormUrl = localStorage.getItem('formUrl');
              window.location.href = userFormUrl ? `${userFormUrl}/login` : `/login?prePath=${encodeURIComponent(window.location.href)}`;
            }
          }

          else { throw res; }
        }
      }
      return requestAfter ? requestAfter(formatPagination(res)) : formatPagination(res);
    });
}

export function syncRequest(url, body) {
  // 同步请求
  const params = body || {};
  return new Promise((resolve, reject) => {
    const oAjax = new XMLHttpRequest();
    oAjax.open('POST', url, false); // false同步请求  （true异步）
    oAjax.setRequestHeader('Content-type', 'application/json; charset=utf-8');
    oAjax.setRequestHeader('Authorization', getToken());
    oAjax.onreadystatechange = function () {
      if (oAjax.readyState === 4 && oAjax.status === 200) {
        console.log(oAjax);
        resolve(JSON.parse(oAjax.responseText));
      }
      else {
        console.log(oAjax);
      }
    };
    oAjax.send(
      JSON.stringify({
        ...params,
        systemId,
      }),
    );
  });
}

/** axios请求文件流 */
export function RequrstAxios(url, params) {
  return axios.post(url, params, {
    responseType: 'blob',
    headers: {
      Accept: 'application/pdf',
      ContentType: 'application/pdf',
      Authorization: `${getToken()}`,
    },
  });
}
