import { message } from '../../src/antd';

// 定义接口和类型
interface Config {
  baseURL?: string;
  headers?: Record<string, string>;
  onError?: (data: any) => void;
  onUnauthorized?: (data: any) => void;
}

type HttpMethod = 'GET' | 'POST' | 'PUT' | 'DELETE';

interface RequestConfig extends Config {
  method?: HttpMethod;
  body?: string;
}

// 定义默认配置
const defaultConfig: Config = {
  baseURL: '',
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'Authorization': localStorage.getItem('token') || '',
  },
  onError(msg: string) {
    message.error(msg);
  },
  onUnauthorized() {
    location.href = `/login?prePath=${encodeURIComponent(location.href)}`;
  },
};

// interface Result {
//   data: any;
//   message: string;
//   pagination: {
//     currentPage: number;
//     listRows: number;
//     totalRows: number;
//   };
//   status: number;
// }

function formatPagination(res: any) {
  // 判断返回结果中是否存在分页信息，并且分页信息是否为对象类型
  if (res.pagination && typeof res.pagination === 'object') {
    // 对分页信息进行格式化
    res.pagination = {
      ...res.pagination,
      current: res.pagination.currentPage,
      pageSize: res.pagination.listRows,
      total: res.pagination.totalRows,
    };
  }
  // 返回格式化后的结果
  return res;
}

export class HttpClient {
  private config: Config;

  constructor(config?: Config) {
    this.config = { ...defaultConfig, ...config };
  }

  async request<T>(
    method: HttpMethod,
    url: string,
    data?: unknown,
    customConfig?: Partial<RequestConfig>,
  ): Promise<T> {
    const config: RequestConfig = {
      ...this.config,
      ...customConfig,
      method,
      headers: { ...this.config.headers, ...customConfig?.headers },
    };

    let fullUrl = config.baseURL + url;

    // params/data
    if (data) {
      if (method === 'GET' || method === 'DELETE')
        fullUrl += `?${new URLSearchParams(data as Record<string, string>).toString()}`;
      else
        config.body = JSON.stringify(data);
    }

    // fetch
    const response = await fetch(fullUrl, config);

    if (!response.ok) {
      config.onError?.(`${response.status}:${response.statusText}`);
      return Promise.reject(response);
    }

    const result = await response.json();

    if (result.status === 1003) {
      config.onUnauthorized?.(result);
      return Promise.reject(result);
    }
    if (result.status !== 0) {
      config.onError?.(result.message);
      return Promise.reject(result);
    }

    return formatPagination(result);
  }

  get<T>(url: string, params?: Record<string, string | number>, config?: Partial<RequestConfig>): Promise<T>;
  get<T>(args: [string, Record<string, string | number>?, Partial<RequestConfig>?]): Promise<T>;
  get<T>(
    urlOrArgs: string | [string, Record<string, string | number>?, Partial<RequestConfig>?],
    params?: Record<string, string | number>,
    config?: Partial<RequestConfig>,
  ): Promise<T> {
    if (Array.isArray(urlOrArgs))
      return this.request<T>('GET', ...urlOrArgs);

    return this.request<T>('GET', urlOrArgs, params, config);
  }

  post<T>(url: string, data?: unknown, config?: Partial<RequestConfig>): Promise<T>;
  post<T>(args: [string, unknown?, Partial<RequestConfig>?]): Promise<T>;
  post<T>(
    urlOrArgs: string | [string, unknown?, Partial<RequestConfig>?],
    data?: unknown,
    config?: Partial<RequestConfig>,
  ): Promise<T> {
    if (Array.isArray(urlOrArgs))
      return this.request<T>('POST', ...urlOrArgs);

    return this.request<T>('POST', urlOrArgs, data, config);
  }

  put<T>(url: string, data?: unknown, config?: Partial<RequestConfig>): Promise<T>;
  put<T>(args: [string, unknown?, Partial<RequestConfig>?]): Promise<T>;
  put<T>(
    urlOrArgs: string | [string, unknown?, Partial<RequestConfig>?],
    data?: unknown,
    config?: Partial<RequestConfig>,
  ): Promise<T> {
    if (Array.isArray(urlOrArgs))
      return this.request<T>('PUT', ...urlOrArgs);

    return this.request<T>('PUT', urlOrArgs, data, config);
  }

  delete<T>(url: string, params?: Record<string, string | number>, config?: Partial<RequestConfig>): Promise<T>;
  delete<T>(args: [string, Record<string, string | number>?, Partial<RequestConfig>?]): Promise<T>;
  delete<T>(
    urlOrArgs: string | [string, Record<string, string | number>?, Partial<RequestConfig>?],
    params?: Record<string, string | number>,
    config?: Partial<RequestConfig>,
  ): Promise<T> {
    if (Array.isArray(urlOrArgs))
      return this.request<T>('DELETE', ...urlOrArgs);

    return this.request<T>('DELETE', urlOrArgs, params, config);
  }
}

export const httpClient = new HttpClient();
