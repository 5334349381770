export interface ThemeTokenType {
  colorPrimary: string;
  colorInfo: string;
  colorPrimaryBgHover: string;
  colorPrimaryBg?: string;
  colorLink?: string;
  colorPrimaryHover?: string;
  colorBgLayout?: string;
};

/** 主题色配置 */
const themeToken1: ThemeTokenType = {
  colorPrimary: '#1352be',
  colorInfo: '#1352be',
  colorLink: '#1352be',
  colorPrimaryBg: '#86aced26',
  colorPrimaryBgHover: '#cbdbf5',
};

const themeToken2: ThemeTokenType = {
  colorPrimary: '#a78949',
  colorInfo: '#a78949',
  colorBgLayout: '#a78949',
  colorPrimaryBgHover: '#f5f4ed',
};

const themeToken3: ThemeTokenType = {
  colorPrimary: '#5f87bd', // '#2b3b56',
  colorInfo: '#5f87bd',
  colorLink: '#4671ba',
  colorPrimaryBg: '#9aaebf33',
  colorPrimaryBgHover: '#d1d7e2',
  colorPrimaryHover: '#5d6d87',
};

const themeToken4: ThemeTokenType = {
  colorPrimary: '#282b2d',
  colorInfo: '#4f86af',
  colorLink: '#4f86af',
  colorPrimaryBgHover: '#c5d4e1',
  colorPrimaryHover: '#4f86af',
};

function darkThemeToken(themeType) {
  return ['theme3'].includes(themeType)
    ? {
        colorLink: '#5f87bd',
        colorPrimary: '#5f87bd',
        colorInfo: '#1677ff',
      }
    : ['theme1', 'theme4'].includes(themeType)
        ? {
            colorLink: '#1677ff',
            colorPrimary: '#1677ff',
            colorInfo: '#1677ff',
          }
        : {};
}

/** 4套主题色 */
export const themeConfig = {
  theme1: themeToken1,
  theme2: themeToken2,
  theme3: themeToken3,
  theme4: themeToken4,
};

/** 根据选择的颜色返回主题色 */
export function getGlobalThemeToken({ themeType, sysMode = 'light' }) {
  // 默认全局token
  const initThemeToken = sysMode === 'light'
    ? {
        colorTextDisabled: `var(--ant-color-text, #333)`,
        colorBgContainerDisabled: 'rgba(0,0,0,.03)',
      }
    : {
        colorTextDisabled: `var(--ant-color-text, #fff)`,
        colorBgContainerDisabled: 'rgba(255,255,255,.03)',
      };

  return {
    ...initThemeToken,
    ...themeConfig[themeType === 'theme4' ? 'theme1' : themeType],
    ...(sysMode === 'dark')
      ? {
          ...darkThemeToken(themeType),
        }
      : {},
  };
}
