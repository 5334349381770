import { DatePicker } from 'antd';

const AntRangePicker = DatePicker.RangePicker;

interface OptsType {
  placeholder?: string;
  rest?: Object;
}
interface RangePickerProps {
  id?: string;
  value: number | string;
  onChange: Function;
  opts?: OptsType;
}
export default function RangePicker({ id, value, onChange, opts = {} }: RangePickerProps) {
  const { placeholder, ...rest } = opts;
  return (
    <AntRangePicker
      id={id}
      format="YYYY-MM-DD"
      value={value === null || value === undefined ? [null, null] : value}
      placeholder={placeholder || ['开始时间', '结束时间']}
      onChange={onChange}
      style={{ width: '100%' }}
      {...rest}
    />
  );
};
