import { useState } from 'react';
import { Input as AntInput } from 'antd';

export default function InputPassword({ value, onChange, placeholder = '请输入', opts = {} }) {
  const [mode, setMode] = useState('eye-invisible');

  return (
    <div>
      <AntInput
        style={{ webkitTextSecurity: mode === 'eye' ? 'inherit' : 'disc' }}
        value={value}
        onChange={e => onChange(e.target.value)}
        placeholder={placeholder}
        mode={mode}
        suffix={(
          <span
            style={{ webkitTextSecurity: 'none' }}
            onClick={() => {
              setMode(mode === 'eye-invisible' ? 'eye' : 'eye-invisible');
            }}
          >
            {
              mode === 'eye'
                ? <i className="iconfont">&#xe6c5;</i>
                : <i className="iconfont">&#xe61a;</i>
            }
          </span>
        )}
        {...opts}
      />
    </div>
  );
};
