import React from 'react';
import type { ModalFuncProps } from 'antd';
import { Modal } from 'antd';

interface ModalConfirmProps {
  store?: object | any;
  confirmProps?: ModalFuncProps;
  onConfirmed?: Function;
  definedModal?: Function;
  beforeConfirm?: Function;
  children?: React.ReactNode;
}

export default function ModalConfirm({
  confirmProps = {
    content: '请确认是否继续进行删除操作？',
  },
  onConfirmed,
  definedModal, // 自定义modal方法
  beforeConfirm, // 一些前置校验
  children,
}: ModalConfirmProps) {
  const [modal, contextHolder] = Modal.useModal();

  return (
    <>
      <div onClick={async () => {
        if (definedModal) {
          await definedModal(modal);
          return;
        }
        if (beforeConfirm && !beforeConfirm())
          return;

        const confirmed = await modal.confirm({ title: '提示', cancelText: '取消', okText: '确定', ...confirmProps });
        onConfirmed && onConfirmed(confirmed);
      }}
      >
        {
          children
        }
      </div>
      {contextHolder}
    </>
  );
}
