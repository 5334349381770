export function checkType(value, type) {
  if (value !== '' && type === 'number')
    return /^\d+$/.test(value);

  return true;
}

export function genErrorMessage(pattern, name) {
  if (typeof pattern === 'string')
    return pattern.replace('${name}', name);

  return `请输入${name}`;
}

export function validateRule(rule, value, name, fields) {
  if (!rule)
    return;
  let error;
  if ('required' in rule) {
    if (!!rule.required && (value === '' || value === undefined || value === null))
      error = genErrorMessage(rule.message, name);
  }

  if ('type' in rule) {
    if (value !== undefined && value !== null && !checkType(value, rule.type))
      error = genErrorMessage(rule.message || '${name}格式不正确', name);
  }

  if (rule.pattern instanceof RegExp) {
    if (!rule.pattern.test(value))
      error = genErrorMessage(rule.message || '${name}格式不正确', name);
  }

  if ('max' in rule) {
    if (value && value.length > rule.max)
      error = genErrorMessage(rule.message || `\${name}长度不能超过${rule.max}个字符`, name);
  }

  if ('noSpace' in rule) {
    if (!!rule.noSpace && value && !/^\S.*\S$|(^\S{1,2}$)/.test(value))
      error = genErrorMessage(rule.message || '前后不允许出现空格', name);
  }

  if ('min' in rule) {
    if (value && value.length < rule.min)
      error = genErrorMessage(rule.message || `\${name}长度不能少于${rule.min}个字符`, name);
  }

  if ('validator' in rule) {
    const msg = rule.validator(value, fields);
    error = msg === true ? undefined : genErrorMessage(typeof msg === 'string' ? msg : rule.message, name);
  }
  return error;
}

export function validateSchema(schema, fields) {
  let errors = {};
  for (const key in schema) {
    const value = fields[key];
    errors = { ...errors, ...validateField(schema, key, value, fields) };
  }
  return errors;
}

export function validateField(schema, key, value, fields) {
  const errors = {};
  const rules = schema[key].rules || [];
  const name = schema[key].name;
  const visible = schema[key].visible;
  if (typeof visible === 'function') {
    if (!visible({ fields }))
      return errors;
  }

  for (const rule of rules)
    errors[key] = errors[key] || validateRule(rule, value, name, fields);

  return errors;
}

export function getValidateErrors(schema, fields) {
  return validateSchema(schema, fields);
}
export function isValidate(schema, fields) {
  const errors = getValidateErrors(schema, fields);
  for (const key in errors) {
    if (errors[key] !== undefined)
      return false;
  }

  return true;
}
export function getDefaultValue(schema) {
  const data = {};
  for (const key in schema)
    data[key] = schema[key].defaultValue;

  return data;
}
