import * as React from 'react';
import classNames from 'classnames';
import { Flex, Typography } from 'antd';
import type { DraggableSyntheticListeners } from '@dnd-kit/core';
import type { Transform } from '@dnd-kit/utilities';

import { ChangeDivComponent, Handle } from './components';

import styles from './Item.module.less';

const { useEffect } = React;

const { Text } = Typography;

export interface Props {
  isDarag?: boolean;
  dragOverlay?: boolean;
  color?: string;
  colorBg?: string;
  borderRadios?: number;
  disabled?: boolean;
  dragging?: boolean;
  handle?: boolean;
  handleProps?: any;
  height?: number;
  index?: number;
  fadeIn?: boolean;
  transform?: Transform | null;
  listeners?: DraggableSyntheticListeners;
  sorting?: boolean;
  style?: React.CSSProperties;
  transition?: string | null;
  wrapperStyle?: React.CSSProperties;
  value: React.ReactNode;
  icon?: React.ReactNode;
  isSelected?: boolean;
  item?: object;
  nameWidth?: number | string;
  extraContent?: object;
  renderItem?: (args: {
    dragOverlay: boolean;
    dragging: boolean;
    sorting: boolean;
    index: number | undefined;
    fadeIn: boolean;
    listeners: DraggableSyntheticListeners;
    ref: React.Ref<HTMLElement>;
    style: React.CSSProperties | undefined;
    transform: Props['transform'];
    transition: Props['transition'];
    value: Props['value'];
  }) => React.ReactElement;
}

export const Item = React.memo(
  React.forwardRef<HTMLLIElement, Props>(
    (
      {
        isDarag,
        color,
        colorBg,
        borderRadios = 6,
        dragOverlay,
        dragging,
        disabled,
        fadeIn,
        handle,
        handleProps,
        height,
        index,
        listeners,
        renderItem,
        sorting,
        style,
        transition,
        transform,
        value,
        icon,
        item,
        isSelected,
        nameWidth,
        extraContent,
        wrapperStyle,
        ...props
      },
      ref,
    ) => {
      useEffect(() => {
        if (!dragOverlay)
          return;

        document.body.style.cursor = 'grabbing';

        return () => {
          document.body.style.cursor = '';
        };
      }, [dragOverlay]);

      const Menus = (extraContent?.setMenu(item) || []).filter(itr => itr.isShow ? !!itr.isShow(item) : true);

      return renderItem
        ? (
            renderItem({
              dragOverlay: Boolean(dragOverlay),
              dragging: Boolean(dragging),
              sorting: Boolean(sorting),
              index,
              fadeIn: Boolean(fadeIn),
              listeners,
              ref,
              style,
              transform,
              transition,
              value,
            })
          )
        : (
          <li
            className={classNames(
              styles.Wrapper,
              fadeIn && styles.fadeIn,
              sorting && styles.sorting,
              dragOverlay && styles.dragOverlay,
            )}
            style={
            {
              ...wrapperStyle,
              'transition': [transition, wrapperStyle?.transition]
                .filter(Boolean)
                .join(', '),
              '--translate-x': transform
                ? `${Math.round(transform.x)}px`
                : undefined,
              '--translate-y': transform
                ? `${Math.round(transform.y)}px`
                : undefined,
              '--scale-x': transform?.scaleX
                ? `${transform.scaleX}`
                : undefined,
              '--scale-y': transform?.scaleY
                ? `${transform.scaleY}`
                : undefined,
              '--index': index,
              '--color': color,
              '--colorBg': colorBg,
              '--diff-borderRadius': `${borderRadios - 6}px`,
            } as React.CSSProperties
          }
            ref={ref}
          >
            <div
              className={classNames(
                styles.Item,
                dragging && styles.dragging,
                isSelected && styles.isSelected,
                handle && styles.withHandle,
                dragOverlay && styles.dragOverlay,
                disabled && styles.disabled,
                color && styles.color,
              )}
              style={style}
              data-cypress="draggable-item"
              {...(!handle ? listeners : undefined)}
              {...props}
              tabIndex={!handle ? 0 : undefined}
            >
              {extraContent && Menus.length
                ? (
                  <div className={styles.extraContent}>
                    <div className={styles.Actions}>
                      {handle && isDarag ? <Handle {...handleProps} {...listeners} /> : null}
                    </div>
                    {icon}
                    <ChangeDivComponent
                      itr={item}
                      name={value}
                      isSelected={isSelected}
                      hasHandle={handle && isDarag}
                      onSaveLeaf={(itr, val) => {
                        extraContent.onSaveLeaf(itr, val);
                      }}
                      onCancelLeaf={(type, itr) => {
                        extraContent.onCancelLeaf(type, itr);
                      }}
                      nameWidth={nameWidth}
                      {
                      ...extraContent
                    }
                    />
                  </div>
                  )
                : (
                  <Flex align="center" className={classNames(styles.ItemWarp)}>
                    {icon}
                    <Text
                      ellipsis={{ tooltip: value }}
                      className={classNames(styles.Names, isSelected && styles.isSelected)}
                      style={nameWidth ? { width: `calc(${nameWidth}px + 24px)` } : {}}
                    >
                      {value}
                    </Text>
                  </Flex>
                  )}

            </div>
          </li>
          );
    },
  ),
);
