import { forwardRef, useImperativeHandle, useState } from 'react';
import { Alert, message } from 'antd';
import { useNavigate } from 'react-router-dom';
import {
  Form,
  Input,
  InputPassword,
  String,
  TUIDrawer,
  encrypt,
  useAppConfigContext,
  useFormController,
} from '../../../../index';
import { revisePhoneNumber } from './service';

const formschema = {
  oldPhone: {
    type: String,
    name: '原手机号',
  },
  phone: {
    type: Input,
    name: '新手机号',
    rules: [
      { required: true, message: '请输入新手机号' },
      {
        validator: value => (value && /^1[3-9]\d{9}$/.test(value) ? true : '手机号格式不正确'),
      },
    ],
    opts: { maxLength: 11 },
  },
  password: {
    type: InputPassword,
    name: '密码',
    placeholder: '本人系统登录密码',
    rules: [
      { required: true },
    ],
    opts: {},
  },
};

function RevisePhoneNumberT(props, ref) {
  const navigate = useNavigate();
  const { revisePhoneNumberProps = {} } = useAppConfigContext();
  const { api = '/saas/user/changePhone', revisePhoneService, callBack } = revisePhoneNumberProps;

  const [visible, setVisible] = useState(false);

  const form = useFormController({
    schema: formschema,
    doSubmit: fields => revisePhoneService
      ? revisePhoneService(fields)
      : revisePhoneNumber(api, {
        oldPhone: fields.oldPhone,
        newPhone: fields.phone,
        password: encrypt(fields.password),
      }),
  });

  const onCancel = () => {
    form.reset();
    setVisible(false);
  };

  const onOk = async () => {
    const r = await form.submit();
    if (r && r.status === 0) {
      onCancel();
      message.success('修改成功，请重新登录');
      if (callBack) {
        callBack();
        return;
      }
      const userFormUrl = localStorage.getItem('formUrl');
      window.location.href = userFormUrl ? `${userFormUrl}/login` : `/login?prePath=${encodeURIComponent(window.location.href)}`;
    }
  };

  useImperativeHandle(ref, () => ({
    handle: () => {
      setVisible(true);
      form.setFields({
        oldPhone: JSON.parse(localStorage.getItem('userInfo') || '{}').phone,
      });
    },
  }));

  return (
    <TUIDrawer
      visible={visible}
      title="修改手机号"
      onCancel={onCancel}
      onOk={onOk}
    >
      <>
        <div style={{ marginBottom: '16px' }}>
          <Alert message="提交后，所有子系统的个人手机号均同步修改~" type="info" />
        </div>
        <Form
          schema={formschema}
          fields={form.fields}
          errors={form.errors}
          onFieldChange={form.setField}
          onChange={form.setFields}
        />
      </>
    </TUIDrawer>
  );
};

const RevisePhoneNumber = forwardRef(RevisePhoneNumberT);
export default RevisePhoneNumber;
