import * as React from 'react';
import { Tree } from 'antd';
import styles from './index.module.less';

const { useEffect } = React;
const { TreeNode } = Tree;
export function LeftTree({ value = {}, options, onChange = () => {}, opts = {} }) {
  const { selectedKeys, expandedKeys, checkedKeys } = value;

  const {
    defaultExpandedKeys = [],
    checkable = false,
    selectable = false,
    lastCheckable = false,
    lastSelectable = false,
    disabled = false,
    Level = 3,
    firstSelectable = false,
    propStyle = {},
  } = opts;

  // console.log('val---22', value, options, selectedKeys, expandedKeys, )

  const _onChangeTree = (filed, val, record) => {
    onChange({
      ...value,
      [filed]: val,
    }, record, filed);
  };

  const renderTreeNodes = data =>
    data.map((item) => {
      if (item.children) {
        return (
          <TreeNode
            title={item.title}
            key={item.key}
            dataRef={item}
            checkable={checkable}
            selectable={selectable}
            {...opts}
          >
            {renderTreeNodes(item.children)}
          </TreeNode>
        );
      }
      return (
        <TreeNode
          key={item.key}
          {...item}
          dataRef={item}
          checkable={lastCheckable}
          selectable={lastSelectable}
          {...opts}
          // disabled={disabled ? true : item.disabled && !item.children ? true : false}
        />
      );
    });

  const onCheck = (val, spara) => {
    const { checked, halfCheckedKeys } = spara;
    // console.log('onCheck--', val, spara, checked, halfCheckedKeys)
    // onChange(val)
    _onChangeTree('checkedKeys', val);
  };

  const onExpand = (val) => {
    // console.log('onExpand--', val)
    // setExpandedKeys(val)
    _onChangeTree('expandedKeys', val);
  };

  const onSelect = (val, spara) => {
    const { node } = spara;
    const record = node.props.dataRef || node.props;
    // console.log('选择--', val, node.props, record)
    if (
      Level == 3
      && val
      && val.length === 1
      && val[0].split('_').length == 3
    )
      _onChangeTree('selectedKeys', val, record);

    if (Level == 2) {
      if (firstSelectable) {
        if (val && val.length === 1)
          _onChangeTree('selectedKeys', val, record);
      }
      else {
        if (val && val.length === 1 && record.level === 2) { // 第二层
          _onChangeTree('selectedKeys', val, record);
        }
      }
    }
  };

  useEffect(
    () => {
      if (options.length) {
        // console.log('opopopopop-----', options, defaultExpandedKeys)
        _onChangeTree('expandedKeys', defaultExpandedKeys);
      }
    },
    [options],
  );

  /** 插槽 */
  const getSlot = (key) => {
    return React.Children.map(children, (child, i) => {
      return child && child.key === key ? child : null;
    });
  };

  return (
    <div style={Level ? styles[`TUI-TreeWarp-${Level}`] : ''}>
      <Tree
        checkable={checkable}
        selectable={selectable}
        checkedKeys={checkedKeys}
        selectedKeys={selectedKeys}
        // checkStrictly={true}
        expandedKeys={expandedKeys}
        // defaultExpandAll={true}
        onCheck={onCheck} // 点击复选框触发
        onExpand={onExpand}
        onSelect={onSelect} // 点击树节点触发
        blockNode
        {...opts}
      >
        {renderTreeNodes(options || [])}
      </Tree>
    </div>
  );
}
