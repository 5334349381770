import dayjs from 'dayjs';

const _reqTrans = {
  DatePicker: v => dayjs(v).isValid() ? dayjs(v).format('YYYY-MM-DD') : v,
  DateTimePicker: v => dayjs(v).isValid() ? dayjs(v).format('YYYY-MM-DD HH:mm') : v,
  RangePicker: (v, field, out) => {
    const [s, e] = v || [];
    const [f, t] = Array.isArray(out) ? out : [`${field}Start`, `${field}End`];
    const res = {};
    if (s && dayjs(s).isValid() && f)
      res[f] = dayjs(s).format('YYYY-MM-DD 00:00:00');

    if (e && dayjs(e).isValid() && t)
      res[t] = dayjs(e).format('YYYY-MM-DD 23:59:59');

    if (res[f] || res[t]) {
      // 抹掉原来的字段
      // TODO 是否要删掉？
      // res[field] = undefined;
    }
    return res;
  },
};
const _resTrans = {
  DatePicker: v => dayjs(v).isValid() ? dayjs(v, 'YYYY-MM-DD') : v,
  DateTimePicker: v => dayjs(v).isValid() ? dayjs(v, 'YYYY-MM-DD HH:mm') : v,
};
export function addReqTransformer(name, fn) {
  _reqTrans[name] = fn;
}
export function addResTransformer(name, fn) {
  _resTrans[name] = fn;
}
export function getReqTransformer(type) {
  return _reqTrans[type];
}
export function getResTransformer(type) {
  return _resTrans[type];
}
export function formatReq(req, schema, filterView) {
  const result = { ...req };
  for (const s of schema) {
    const { field, type, view, filterType } = s;
    if (filterView && Array.isArray(view) && !view.includes(filterView))
      continue;
    if (field in req) {
      const transfromer = getReqTransformer(filterView === 'filter' && filterType ? filterType : type);
      if (typeof transfromer === 'function') {
        const value = transfromer(req[field], field, s.reqFields);
        if (typeof value === 'object')
          Object.assign(result, value);

        else
          result[field] = value;
      }
      else {
        result[field] = req[field];
      }
    }
  }
  return result;
}

export function formatRes(res, schema) {
  const result = { ...res };
  for (const s of schema) {
    const { field, type } = s;
    if (field in res) {
      const transfromer = getResTransformer(type);
      if (typeof transfromer === 'function') {
        const value = transfromer(res[field]);
        result[field] = value;
      }
    }
  }
  return result;
}
