import { Button, Flex, Typography } from 'antd';
import type { ConfigProviderProps, FlexProps } from 'antd';
import ClassNames from 'classnames';
import { TUIIcon } from '../../index';
import BadgePng from './badge.png';
import './index.less';

const { Title } = Typography;

type SizeType = ConfigProviderProps['componentSize'];

interface Props {
  showBack?: true | false;
  pageTitle: string;
  pageTitleIcon?: any;
  flexProps?: FlexProps | {};
  gap?: SizeType | number;
  children?: any;
  className?: string;
  gobackCallBack?: Function;
}
export const BadgeIMG = BadgePng;
export function Back({ gobackCallBack }) {
  return (
    <div
      className="Wrapper"
      onClick={() => {
        if (gobackCallBack) {
          gobackCallBack(window.history);
          return;
        }
        window.history.back();
      }}
    >
      <Button type="text" size="small" icon={<TUIIcon type="icon-chevron-left" />}>返回</Button>
    </div>
  );
}

export default function ContentTitle({
  showBack = false,
  pageTitle,
  pageTitleIcon,
  gobackCallBack,
  flexProps = {
    justify: 'space-between',
  },
  gap = 'middle',
  children,
  className,
}: Props) {
  return (
    <Flex align="center" {...flexProps} gap={gap} className={ClassNames('TUI-ContentTitleWarp', className)}>
      <Flex align="center">
        {pageTitleIcon && <img className="TUI-pageTitleIcon" src={pageTitleIcon} />}
        {showBack && <Back gobackCallBack={gobackCallBack} />}
        <Title level={5}>{pageTitle}</Title>
      </Flex>
      {children}
    </Flex>
  );
};
