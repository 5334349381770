import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Form, Modal, String, useFormController } from '../../../../index';

const formschema = {
  account: {
    type: String,
    name: '账号',
  },
  userName: {
    type: String,
    name: '姓名',
  },
  phone: {
    type: String,
    name: '手机号',
  },
};

function UserInfoT(props, ref) {
  const [visible, setVisible] = useState(false);

  const form = useFormController({
    schema: formschema,
  });

  const onCancel = () => {
    form.reset();
    setVisible(false);
  };

  const getUserInfo = () => {
    const userInfoStr = localStorage.getItem('userInfo');
    return userInfoStr ? JSON.parse(userInfoStr) : {};
  };

  useImperativeHandle(ref, () => ({
    handle: () => {
      setVisible(true);
      const userInfo = getUserInfo();
      form.setFields({
        oldPhone: userInfo.phone,
      });
    },
  }));

  useEffect(() => {
    if (!visible)
      return;
    const userInfo = getUserInfo();
    form.setFields({
      account: userInfo.account,
      userName: userInfo.name,
      phone: userInfo.phone,
    });
  }, [visible]);

  return (
    <Modal
      open={visible}
      title="个人信息"
      maskClosable={false}
      destroyOnClose
      onCancel={onCancel}
      cancelText="关闭"
      confirmLoading={form.submitting}
      okButtonProps={{ style: { display: 'none' } }}
      width={600}
    >
      <Form
        schema={formschema}
        fields={form.fields}
        errors={form.errors}
        onFieldChange={form.setField}
        onChange={form.setFields}
      />
    </Modal>
  );
};

const UserInfo = forwardRef(UserInfoT);
export default UserInfo;
