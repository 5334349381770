import { getLocalUserPermissions } from './index';

export function checkIsShowPageTitle(menus = [], currentUrl) {
  return menus.some((menu) => {
    if (menu.children)
      return checkIsShowPageTitle(menu.children, currentUrl);
    else
      return menu.key === currentUrl && menu.showPageTitle === true;
  });
}

/** 根据当前url 返回 面包屑 */
export function findBreadcrumbByUrl(Menus, url) {
  for (const menu of Menus) {
    if (menu.key === url)
      return [{ path: menu.key, breadcrumbName: menu.label }];

    if (menu.children) {
      const child = menu.children.find(child => child.key === url);
      if (child)
        return [{ path: '', breadcrumbName: menu.label }, { path: child.key, breadcrumbName: child.label }];
    }
  }
  return null;
}

/** 导出有权限的Menus */
export function getFilterMenusByAuth({ Menus = [], sysMode = 'light' }) {
  const permissions = getLocalUserPermissions();
  if (!Array.isArray(permissions) || permissions.length < 1) {
    if (window.location.pathname !== '/login' && window.location.pathname !== '/resetPass')
      window.location.href = '/login';
  }
  const newMenus = [];
  Menus.forEach((item) => {
    if (Array.isArray(item.children)) {
      const subMenus = item.children.filter(val => permissions.includes(val.id));
      if (subMenus.length > 0) {
        newMenus.push({
          ...item,
          children: subMenus,
        });
      }
    }
    else {
      if (permissions.includes(item.id))
        newMenus.push({ ...item });
    }
  });
  return newMenus.map(it => ({ ...it, theme: sysMode }));
}
