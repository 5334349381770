import React from 'react';
import { Tooltip } from 'antd';
import ClassNames from 'classnames';
import { createFromIconfontCN } from '@ant-design/icons';

// cursor: 'pointer'
const Icon = createFromIconfontCN({
  extraCommonProps: { style: { fontSize: `calc(var(--ant-font-size-icon, 0px) + 2px)` } },
  scriptUrl: 'https://static-1255466169.cos.ap-guangzhou.myqcloud.com/assets/fonts/iconfont.js', // 在 iconfont.cn 上生成
});

const TUIIcon: React.FC<{
  title?: string;
  className?: string;
  type: string;
}> = ({ title, className, ...rest }) => {
  return (
    <Tooltip title={title} getPopupContainer={trigger => trigger.parentNode}>
      <Icon className={ClassNames('TUI-Icon', className)} {...rest} />
    </Tooltip>
  );
};
export default TUIIcon;
