export default (obj) => {
  const res = {};
  const deep = (value, path) => {
    // TODO 严格校验
    if (typeof value !== 'object' || value === null) {
      const t = path.join('');
      res[t] = value;
    }
    else {
      if (Array.isArray(value)) {
        // TODO 可能会遍历出其他属性，默认期望没有多余属性
        for (const k in value) {
          const p = [...path, `[${k}]`];
          deep(value[k], p);
        }
      }
      else if (typeof value === 'object') {
        for (const k in value) {
          const p = [...path, path.length ? `.${k}` : `${k}`];
          deep(value[k], p);
        }
      }
    }
  };

  deep(obj, []);
  return res;
};
