import { Radio } from 'antd';
import { formatOption } from '../../utils/utils';

export default function TUIRadio({ id, value, onChange = (e) => {}, options = [], opts = {} }) {
  const _options = (options || []).map(formatOption);

  return (
    <Radio.Group
      id={id}
      value={value}
      onChange={(e) => {
        onChange(e.target.value);
      }}
      options={_options}
      {...opts}
    />
  );
};
