/** options 转 { id, name } -> { value, label }  */
export function formatOption(o) {
  o.label = o.label || o.name;
  o.value = o.value || o.id;
  if (o.children)
    o.children = o.children.map(formatOption);

  return o;
}

/** treeData 转 { id, name } -> { value, title }  */
export function formatTreeOption(o) {
  o.title = o.title || o.name;
  o.value = o.value || o.id;
  if (o.children)
    o.children = o.children.map(formatTreeOption);

  return o;
}

/** form 校验 */
export function objectValueAllEmpty(object) {
  let isEmpty = true;
  Object.keys(object).forEach((x) => {
    if (object[x] != null && object[x] != '')
      isEmpty = false;
  });
  if (isEmpty) {
    // 值全为空
    return true;
  }
  return false;
}

/** 树选择时 - 加需要的 label, value */
export function addLabelValue(data) {
  return data.map(d => ({
    ...d,
    label: d.name,
    value: d.id,
  }));
}

/** 平级转树 id, parentId */
export function getTreeFromPlain(data, MAX_STACK_COUNT = 99) {
  // todo max stack level
  let count = 0;
  const run = (nodes, rest) => {
    const nodeIds = nodes.map(n => n.id);
    const res = [];
    const newNodes = [];
    for (const c of rest) {
      const i = nodeIds.indexOf(c.parentId);
      if (i >= 0) {
        nodes[i].children = [...(nodes[i].children || []), c];
        newNodes.push(c);
      }
      else {
        res.push(c);
      }
    }
    count++;
    if (!newNodes.length)
      return res;
    if (count > MAX_STACK_COUNT)
      return res.concat();
    return run(newNodes, res);
  };
  return run(data, data);
}

/** 判断是否为视频 */
export function jugeIsVideo(url) {
  // 获取路径的最后一个点之后的内容作为文件扩展名
  const extension = url ? url.split('.').pop().toLowerCase() : '';
  const videoExtensions = ['mp4', 'wmv', 'avi', 'mov'];
  return videoExtensions.some(item => extension?.endsWith(item));
}
