/* eslint-disable react-refresh/only-export-components */
import type { Dispatch } from 'react';
import { createContext, useContext, useReducer } from 'react';
import { merge } from 'lodash-es';
import { type DispatchType, getReducer } from '../types';
import type { ThemeTokenType } from '../../globalTheme/Theme';
import { themeConfig } from '../../globalTheme/Theme';
import { LOCAL_STORAGE_KEY } from '../../types';

/**
 * 主题上下文类型
 */
export interface ThemeContextType {
  /**
   * 主题
   */
  sysMode: 'light' | 'dark';
  /**
   * 颜色
   */
  themeType: 'theme1' | 'theme2' | 'theme3' | 'theme4' | 'otherTheme';
  /**
   * 框架
   */
  layoutMode: 'shrinkMenu' | 'default' | 'top' | 'ExpandMenu';
  /**
   * 纹理
   */
  markMode: 'markMode1' | 'markMode2' | 'markMode3' | 'markMode4';
  /**
   * 字号
   */
  fontSize: number;
  /**
   * 圆角
   */
  borderRadius: number;
  /**
   * 间距
   */
  size: 'small' | 'middle' | 'large' | 16;
  /**
   * 主题配置
   */
  themeConfig: Record<string, ThemeTokenType>;
  /**
   * 弹窗类型
   */
  modalMode: 'drawer' | 'modal'; // 弹窗类型
  /**
   * 全局自定义样式
   */
  defaultStyle?: object;
};

/**
 * 主题分发上下文类型
 */
type ThemeDispatchType = DispatchType<ThemeContextType>;

/**
 * 主题初始化数据
 */
const initialState: ThemeContextType = {
  sysMode: 'light',
  themeType: 'theme1',
  layoutMode: 'default',
  markMode: 'markMode1',
  fontSize: 14,
  borderRadius: 6,
  size: 'middle',
  themeConfig,
  modalMode: 'drawer',
  defaultStyle: {},
};

/**
 * 主题上下文
 */
export const ThemeContext = createContext(initialState);

/**
 *  获取主题上下文
 * @returns {ThemeContextType} 主题上下文
 */
export function useThemeContext() {
  return useContext(ThemeContext);
}

/**
 * 主题分发上下文
 */
export const ThemeDispatch = createContext<Dispatch<ThemeDispatchType>>(() => {});

/**
 * 获取主题分发上下文
 * @returns {Dispatch<ThemeDispatchType>} 主题分发上下文
 */
export function useThemeDispatch() {
  return useContext(ThemeDispatch);
}

export interface ThemeContextProviderProps {
  defaultStatus?: ThemeContextType;
  children: React.ReactNode | Function; // TODO: 写明具体类型
}

/**
 * 主题上下文提供者
 * @param param0 子组件
 * @returns {React.ReactNode} 主题上下文提供者
 */
export function ThemeContextProvider({ defaultStatus, children }: ThemeContextProviderProps) {
  const jsonStr = localStorage.getItem(LOCAL_STORAGE_KEY.THEMESTATUS);
  let _initialState: ThemeContextType;
  if (jsonStr) {
    try {
      _initialState = JSON.parse(jsonStr) as ThemeContextType;
    }
    catch (e) {
      _initialState = merge({}, initialState, defaultStatus); ;
    }
  }
  else {
    _initialState = merge({}, initialState, defaultStatus); ;
  }

  const [data, dispatch] = useReducer(
    getReducer<ThemeContextType, ThemeDispatchType>(_initialState, (newStatus) => {
      localStorage.setItem(LOCAL_STORAGE_KEY.THEMESTATUS, JSON.stringify(newStatus));
    }),
    _initialState,
  );
  return (
    <ThemeContext.Provider value={data}>
      <ThemeDispatch.Provider value={dispatch}>
        {/* TUIApp 组件中需要用render props 的形式 */}
        {typeof children === 'function' ? children(data, dispatch) : children}
      </ThemeDispatch.Provider>
    </ThemeContext.Provider>
  );
}
