/**
 * 刷新高级 CRUD 组件或 CRUD 组件的列表数据
 *
 * @param refOrList 组件引用或列表数据
 * @param query 查询参数
 */
export function refershAdvancedCrud(refOrList, query) {
  if (!refOrList)
    return;

  let list;
  if (refOrList.current) {
    // AdvancedCrud
    list = refOrList.current.list;
  }
  else if (refOrList.list) {
    // Crud
    list = refOrList.list;
  }
  else {
    // record
    list = refOrList;
  }
  const { doFetch, pagination, filters, sorters } = list;
  doFetch(query || list.query, pagination, filters, sorters);
}

/**
 * Select 的 filterOption prop
 * Select开启搜索后（showSearch），默认按 label 搜索
 * 如果使用 fieldNames 更改了label 取值，需要自定义 filterOption 函数
 * @param input
 * @param option
 * @returns boolean
 */
export function filterOptionByName(input, option) {
  return (option?.name ?? '').toLowerCase().includes(input.toLowerCase());
}

/**
 * 同 filterOptionByName，但是可以自定义 label 取值
 * @param labelKey 自定义的label取值
 * @returns filterOption 函数
 */
export function getFilterOption(labelKey = 'name') {
  return (input, option) =>
    (option?.[labelKey] ?? '').toLowerCase().includes(input.toLowerCase());
}
