import * as React from 'react';
import ClassNames from 'classnames';
import { Dropdown, Menu, Tooltip } from 'antd';
import { TUIIcon } from '../../../../../../index';
import './index.less';

const { useState } = React;
export interface MenuProps {
  id: number | string;
  name: string | React.ReactNode;
  isShow?: Function;
  onClick?: Function;
  pId?: number | string;
}

interface TUIDropdownProps {
  menu: Array<MenuProps>;
  itr: object;
}
export function TUIDropdown({ menu = [], itr = {} }: TUIDropdownProps) {
  const [open, setOpen] = useState(false);

  if (!menu.length)
    return null;

  const MenuT = (
    <Menu>
      {
        menu.filter(item => item.isShow ? !!item.isShow(itr) : true).map(item => (
          <Menu.Item key={item.name}>
            <a onClick={(e) => {
              e.stopPropagation();
              item.onClick && item.onClick();
              setOpen(false);
            }}
            >
              {item.name}
            </a>
          </Menu.Item>
        ),
        )
}
    </Menu>
  );

  const handleOpenChange = (nextOpen, info) => {
    if (info.source === 'trigger' || nextOpen)
      setOpen(nextOpen);
  };

  // trigger={['click']}
  return (
    <Dropdown
      overlay={MenuT}
      placement="bottomLeft"
      onOpenChange={handleOpenChange}
      open={open}
    >
      <span className="ellSty" onClick={e => e.preventDefault()}>
        <i className="iconfont">&#xe62e;</i>
      </span>
    </Dropdown>
  );
}

export const IconObj = {
  edit: <TUIIcon type="icon-edit-1" />,
  copy: <TUIIcon type="icon-file-copy" />,
  del: <TUIIcon type="icon-delete" />,
  ok: <TUIIcon type="icon-check" />,
  cancel: <TUIIcon type="icon-close" />,
};

export function getTooltipIcon({
  title = '',
  iconfont = 'edit',
  onClick,
  disabled = true,
  style = {},
  color = '',
  placement = 'top',
  showTooltip = false,
}) {
  return showTooltip
    ? (
      <Tooltip title={title} placement={placement}>
        <div
          className={ClassNames('TUI-IconDiv', { disabled })}
          style={{
            '--Color': color,
            ...style,
          }}
          key={iconfont}
          onClick={onClick}
        >
          {IconObj[iconfont]}
        </div>
      </Tooltip>
      )
    : (
      <div
        className={ClassNames('TUI-IconDiv', { disabled })}
        style={{
          '--Color': color,
          ...style,
        }}
        key={iconfont}
        onClick={onClick}
      >
        {IconObj[iconfont]}
      </div>
      );
}
