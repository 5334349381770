interface systemTitleProps {
  sysTitleType: 'img' | 'string';
  sysTitle?: string | any;
  sysSubTitle?: string;
}
export default function SystemTitle({ sysTitleType, sysTitle, sysSubTitle }: systemTitleProps) {
  return (
    <div className="sysTitle">
      {sysTitle
        ? sysTitleType === 'string'
          ? (
            <>
              <div className="systemName">{sysTitle}</div>
              <div className="sysSubTitleName">{sysSubTitle}</div>
            </>
            )
          : (
            <img alt="" src={sysTitle} />
            )
        : null}
    </div>
  );
};
