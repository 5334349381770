import { Tree as AntTree } from 'antd';

export default function TreeCheck({ id, value, onChange, options = [], opts = {} }) {
  const onCheck = (checkedKeys) => {
    onChange(checkedKeys);
  };

  return (
    <div id={id}>
      <AntTree
        checkable
        selectable={false}
        blockNode
        checkedKeys={value}
        onCheck={onCheck}
        treeData={options}
        {...opts}
      />
    </div>
  );
};
