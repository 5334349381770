import { Space } from 'antd';
import ClassNames from 'classnames';
import './CheckBoxDiv.less';

function CheckBoxDiv({
  value,
  onChange,
  options = [],
  opts = {},
}) {
  const { width = 312, size = 16, boxType = 'img', Contenxt } = opts;

  return (
    <Space className={ClassNames(`TUI-CheckBoxDiv`)} size={size} wrap>
      {
        options.map(item => (boxType === 'img'
          ? (
            <img
              key={item.id}
              src={item.url}
              style={{ width }}
              className={value === item.id ? 'selected' : ''}
              onClick={() => {
                onChange(item.id);
              }}
            />
            )
          : Contenxt
            ? (
              <div
                key={item.id}
                className={ClassNames(`Box`, { selected: value === item.id })}
                onClick={() => {
                  onChange(item.id);
                }}
              >
                {Contenxt(item)}
              </div>
              )
            : (
              <div
                key={item.id}
                className={ClassNames(`Box`, { selected: value === item.id })}
                onClick={() => {
                  onChange(item.id);
                }}
              >
                {item.name}
              </div>
              )
        ))
      }
    </Space>
  );
}

export default CheckBoxDiv;
