import { useEffect, useState } from 'react';

interface useResizerProps {
  elementRef: any;
  elementAttr?: string;
}
export default function useResizer({
  elementRef,
  elementAttr = '',
}: useResizerProps) {
  const [elementValue, setElementValue] = useState(0); // 某元素、某属性的值

  // 防抖
  const debounce = (fn, delay) => {
    let timer;
    return function () {
      if (timer)
        clearTimeout(timer);

      timer = setTimeout(() => {
        fn();
      }, delay);
    };
  };

  // 浏览器窗口高度发生改变触发
  const resizeChange = (target) => {
    target && setElementValue(target);
  };

  useEffect(() => {
    // 监听的函数
    const resize = new ResizeObserver((entries) => {
      if (!Array.isArray(entries) || !entries.length)
        return;
      const clientHeight = elementRef.current?.clientHeight;
      resizeChange(clientHeight);
    });
    // 传入监听对象
    resize.observe(document.body);
    // 及时销毁监听函数（重要!!!）
    return () => {
      resize.unobserve(document.body);
    };
  }, []);

  return {
    elementValue,
  };
}
