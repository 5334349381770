import { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { validateTCaptcha } from '../service';
import './Tcaptcha.less';

interface SliderValidateProps {
  TCAPTCHE_APPID?: number | string;
  onChange?: Function;
  SliderValidateService?: Function;
}
export default function SliderValidate({ onChange, TCAPTCHE_APPID, SliderValidateService }: SliderValidateProps) {
  const ref = useRef(null);
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (!window.TencentCaptcha)
      return;

    const t = new window.TencentCaptcha(
      ref.current,
      TCAPTCHE_APPID || 2001749235,
      (res) => {
        if (res.ret === 0) {
          const validateTCaptchaService = SliderValidateService || validateTCaptcha;
          validateTCaptchaService({
            ticket: res.ticket,
            randstr: res.randstr,
          }).then((data) => {
            console.log(data);
            setChecked(true);
            onChange && onChange(data.data);
          }).catch((e) => {
            setChecked(false);
          });
        }
      },
    );
  }, []);

  return (
    <div ref={ref} className={classNames(`TUI-Tcaptcha ${checked ? 'TUI-Tcaptcha__success' : ''}`)}>
      {checked ? '验证成功' : '点击按钮进行验证'}
    </div>
  );
};
