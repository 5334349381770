/** 数据库关键字 */
export const DB_KEYWORDS_ARR = [
  'create_by',
  'update_by',
  'create_time',
  'update_time',
  'id',
  'del_flag',
  'create',
  'alter',
  'drop',
  'rename',
  'truncate',
  'select',
  'insert',
  'update',
  'delete',
  'grant',
  'revoke',
  'set',
  'where',
  'join',
  'group',
  'having',
  'order',
  'limit',
  'distinct',
  'and',
  'or',
  'not',
  'like',
  'in',
  'null',
  'is',
  'exists',
  'union',
  'int',
  'char',
  'varchar',
  'date',
  'timestamp',
  'concat',
  'sum',
  'avg',
  'count',
  'max',
  'min',
  'if',
  'case',
  'index',
  'unique',
  'foreign',
  'auto_increment',
  'default',
  'comment',
  'check',
  'key',
  'function',
  'procedure',
  'return',
  'begin',
  'end',
  'delimiter',
  'time',
  'datetime',
  'float',
  'double',
  'decimal',
  'enum',
  'boolean',
  'blob',
  'binary',
  'varbinary',
  'long',
  'children',
  'fieldid',
  'tablehead',
  'field_id',
  'table_head',
  'integer',
];

export default {
  /** 对象校验必填 */
  ValidateRequireObj: (value, field) => {
    try {
      let r = true;
      const err = (value || []).map((item) => {
        if (!item[field])
          r = '请输入完整数据';
      });
      const eer = err.filter(it => it);
      if (eer.length)
        return eer[0];

      return r;
    }
    catch (e) {}
  },
  /** 校验是否是正则表达式 */
  ValidateReg: (val) => {
    if (val && !/^\S.*\S$|(^\S{1,2}$)/.test(val))
      return '前后不允许出现空格';

    if (val) {
      let isReg = true;
      try {
        new RegExp(eval(val));
      }
      catch (e) {
        isReg = false;
      }
      if (!isReg)
        return '正则填写错误';

      return true;
    }
    return true;
  },
  /** 校验是否是Json格式 */
  ValidateJSON: (val) => {
    // if (val && !/^\S.*\S$|(^\S{0,1}\S$)/.test(val)) {
    //   return '前后不允许出现空格';
    // }
    let valiFlag = true;
    let data = [];
    try {
      val && (data = JSON.parse(val));
    }
    catch (e) {
      valiFlag = false;
    }
    if (!valiFlag)
      return '请输入json格式的数据';
  },
  /** 校验选择框选项 */
  ValidateSelectObj: (val, fields = {}) => {
    const { dbFieldType, initSelectObj } = fields;
    const data = val ? JSON.parse(val) : [];
    let initData = [];
    try {
      initSelectObj && (initData = JSON.parse(initSelectObj));
    }
    catch (e) {}
    if (!(Object.prototype.toString.call(data) === '[object Object]'))
      return '请输入对象转换后的json';

    const initIds = Object.keys(initData);
    const fieldIds = Object.keys(data);
    // id不能重复
    const fieldIdsNew = Array.from(new Set(fieldIds));
    if (fieldIdsNew.length && fieldIdsNew.length < fieldIds.length)
      return 'id不能重复';

    // 看是否减少了id
    let flag = true;
    initIds.map((it) => {
      if (!fieldIds.includes(it))
        flag = false;
    });
    if (!flag)
      return '下拉选项只能增量式更新';

    // 数据类型校验 dbFieldType 4-int 1-string  int时，key只能输入 Number类型
    if (dbFieldType === 4) {
      if (fieldIds.some(it => isNaN(Number(it))))
        return 'key值只能为数字';
    }
    return true;
  },
  /** 校验联级选项 */
  ValidateCascaderObj: (val, fields = {}) => {
    const { initSelectObj } = fields;
    const data = val ? JSON.parse(val) : [];
    let initData = [];
    try {
      initSelectObj && (initData = JSON.parse(initSelectObj));
    }
    catch (e) {}
    if (!Array.isArray(data))
      return '请输入数组转换后的json';

    // 校验是否是label,value
    //  [{"label":"单幅","value":1},{"label":"整体式路基双幅","value":2,"children":[{"label":"左幅","value":3}]}]
    if (!validateCascaderKey(data))
      return '配置的key值有误，请按填写说明输入';

    // 原存储值有误，不校验
    if (!Array.isArray(initData))
      return true;

    const initIds = initData.map(it => String(it.value));
    const fieldIds = data.map(it => String(it.value));
    // id不能重复
    const fieldIdsNew = Array.from(new Set(fieldIds));
    if (fieldIdsNew.length && fieldIdsNew.length < fieldIds.length)
      return 'id不能重复';

    // 看是否减少了id
    let flag = true;
    initIds.map((it) => {
      if (!fieldIds.includes(it))
        flag = false;
    });
    if (!flag)
      return '下拉选项只能增量式更新';

    return true;
  },
  /** 校验多选必填 */
  ValidateMultiSelect: (val) => {
    if (
      val
      && Array.isArray(val)
      && val.length
      && val.every(it => it !== undefined && it !== null)
    )
      return true;

    return '请选择';
  },
  /** 校验该数值和某一数值重复 */
  ValidateUnique: (val, valTwo, tips = '存在重复值') => {
    if (val === valTwo)
      return tips;

    return true;
  },
  /** 校验数据库字段 只能输小写字母加下划线 */
  ValidateDBFiled: (val) => {
    if (val && !/^[a-z][a-z0-9_]*$/.test(val))
      return '只能输入小写字母数字和下划线组合，首字母为字母';

    return true;
  },
  /** 数据库字段不能为关键字 */
  ValidateDBKeyWorlds: (val) => {
    if (val && DB_KEYWORDS_ARR.includes(val))
      return '与数据库关键字冲突，请更换';

    return true;
  },
  /** 校验字段分类填写 */
  ValidateClassName: (val) => {
    if (!val && !val.trim())
      return '分类不允许为空';
    if (val && !/^[a-z0-9\u4E00-\u9FA5]+$/i.test(val))
      return '只能输入大小写字母数字和中文';
    if (val && val.length > 10)
      return '范围为1～10个字符';

    return true;
  },
  /** 校验手机号 */
  ValidatePhoneNum: (val) => {
    if (val && !/^1([3-9])\d{9}$/.test(val))
      return '请输入有效的手机号';

    return true;
  },
  /** 校验邮箱 */
  ValidateEmail: (val) => {
    if (val && !/^[\w.-]+@[a-z0-9-]+(\.[a-z0-9-]+)*\.[a-z0-9]{2,6}$/i.test(val))
      return '请输入正确格式的邮箱';

    return true;
  },
};

/** 校验是否是 label, value, children 组合成的 */
export function validateCascaderKey(data = []) {
  const flagArr = data.map((item) => {
    if (!item.children) {
      if (item.label && item.value)
        return true;

      return false;
    }
    else if (item.children && Array.isArray(item.children)) {
      if (item.label && item.value)
        return validateCascaderKey(item.children); // 递归判断

      return false;
    }
    return false;
  });
  return flagArr.every(it => it);
}
