import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import useCrudController from '../../hooks/useCrudController';
import Crud from '../Crud';
import './index.less';
import Input from '../Input';
import String from '../String';
import Select from '../Select';
import { getValidateErrors } from '../../lib/validator';
import deepSearch from './deepSearch';

function getType(type) {
  if (type === 'Select')
    return Select;
  if (typeof type !== 'string')
    return type;
  return Input;
}
function deepCopy(v) {
  try {
    return typeof v === 'object' ? JSON.parse(JSON.stringify(v)) : v;
  }
  catch (e) {
    return v;
  }
}
export function addTableEditorTool(columns) {
  return {
    ...columns,
    $$$addOn: {
      name: '操作',
      editable: false,
      render: ({ record, list, onRecordChange }) => {
        const { data } = list;
        const index = data.indexOf(record);
        return (
          <>
            {index > 0
              ? (
                <a
                  style={{ marginRight: 8 }}
                  onClick={() => {
                    if (index <= 0)
                      return;
                    const i = index - 1;
                    const d = data[i];
                    const r = record;
                    for (const field in columns) {
                      if (/^\$\$\$/.test(field))
                        continue;
                      const shm = columns[field];
                      if (shm && !shm.nocopy)
                        r[field] = deepCopy(d[field]);
                      else
                        r[field] = record[field];
                    }
                    onRecordChange(r);
                  }}
                >
                  <img className="TUI-TableEditor-Tool-Icon" src="https://static-1255466169.cos.ap-guangzhou.myqcloud.com/tui/assets/row_copy.png" title="复制上一条" />
                </a>
                )
              : null}
            <a
              onClick={() => {
                const r = record;
                for (const field in columns) {
                  if (/^\$\$\$/.test(field))
                    continue;
                  const shm = columns[field];
                  if (shm && !shm.noclean)
                    r[field] = undefined;
                }
                data[index] = r;
                onRecordChange(r);
              }}
            >
              <img className="TUI-TableEditor-Tool-Icon" src="https://static-1255466169.cos.ap-guangzhou.myqcloud.com/tui/assets/clear.png" title="清除" />
            </a>
          </>
        );
      },
    },
  };
}
function findParent(node, parentName, root) {
  let parent = node.parentElement;
  let count = 0;
  const MAX_COUNT = 10;
  while (parent !== root) {
    count++;
    if (parent.nodeName.toLowerCase() === parentName)
      return parent;

    if (parent === window.document)
      return null;

    if (count > MAX_COUNT)
      return null;
    parent = parent.parentElement;
  }
  return null;
}
function KeyValueComponent({
  field,
  value,
  onChange,
  Component,
  VALUEFIELD = 'value',
  LABELFIELD = 'label',
  editable,
  ...rest
}) {
  const _onChange = (v) => {
    if (typeof value === 'object') {
      value[VALUEFIELD] = v;
      onChange(value);
    }
    else {
      onChange(v);
    }
  };
  const v = typeof value === 'object' ? value[VALUEFIELD] : value;
  const k = typeof value === 'object' ? value[LABELFIELD] : undefined;
  return (
    <div className="TUI-KeyValueInput" data-field={field} data-editable={editable}>
      {k
        ? (
          <span className="TUI-KeyValueInput-Key">
            {k}
            :
            {' '}
          </span>
          )
        : null}
      <span className="TUI-KeyValueInput-Value">
        <Component value={v} onChange={_onChange} {...rest} />
      </span>
    </div>
  );
}
function RepeatComponent({ value, onChange, Component, field, editable, ...rest }) {
  const arr = Array.isArray(value) ? value : [value];
  const _onChange = (v, i) => {
    arr[i] = v;
    onChange(Array.isArray(value) ? [...arr] : v);
  };
  return (
    <>
      {arr.map((v, i) => (
        <KeyValueComponent
          editable={editable}
          field={field}
          key={i}
          value={v}
          onChange={d => _onChange(d, i)}
          Component={Component || Input}
          {...rest}
        />
      ))}
    </>
  );
}
function isMutiSelect(type, schema) {
  // 多选选择框或者标签选择框会产生数组value，避免识别为repeatComponent
  if (type === Select && schema && schema.opts && schema.opts.mode === 'multiple' || schema.opts.mode === 'tags')
    return true;

  return false;
}
function needRepeatInput(schema, fields, field) {
  const type = schema && schema.type ? getType(schema.type) : Input;
  const value = fields ? fields[field] : undefined;
  if (type === Input && Array.isArray(value))
    return true;

  if (type === Select && Array.isArray(value) && !isMutiSelect(type, schema))
    return true;

  return false;
}
const TableEditorInputor = forwardRef(({ domCell, value, children }, ref) => {
  const rect = domCell
    ? {
        left: domCell.offsetLeft,
        top: domCell.offsetTop,
        width: domCell.offsetWidth,
        height: domCell.offsetHeight,
      }
    : null;
  useEffect(() => {
    const rect = domCell
      ? {
          left: domCell.offsetLeft,
          top: domCell.offsetTop,
          width: domCell.offsetWidth,
          height: domCell.offsetHeight,
        }
      : null;
    if (ref.current && rect) {
      ref.current.style = `
        position: absolute;
        left: ${rect.left + 1}px;
        top: ${rect.top + 1}px;
        width: ${rect.width - 1}px;
        height: ${rect.height - 1}px;
      `;
    }
  }, [value]);
  if (!rect)
    return null;
  return (
    <div
      className="TUI-TableEditor-Input"
      ref={ref}
      style={{
        position: 'absolute',
        left: rect.left + 1,
        top: rect.top + 1,
        width: rect.width - 1,
        height: rect.height - 1,
      }}
    >
      {children}
    </div>
  );
});
function isEditable(schema, record = {}) {
  if (!schema)
    return false;
  if ('editable' in schema) {
    const { editable } = schema;
    if (typeof editable === 'function')
      return editable(record);

    return !!editable;
  }
  return true;
}
function filterCompute(schema, record) {
  const { options = [], optionCompute } = schema;
  if (typeof optionCompute !== 'function')
    return options;
  return optionCompute(record, options);
}
function getAsyncComponent(Component) {
  return (props = {}) => {
    const [state, setState] = useState({});
    const pendingProps = Object.keys(props).filter(k => props[k] instanceof Promise);
    useEffect(() => {
      if (!pendingProps)
        return;
      Promise.all(pendingProps.map(k => props[k])).then((res) => {
        const d = pendingProps.reduce((p, c, i) => Object.assign(p, { [c]: res[i] }), {});
        setState(d);
      }).catch((e) => {
        console.log(e);
      });
    }, [...pendingProps.map(k => props[k])]);

    const params = Object.keys(props).filter(k => !(props[k] instanceof Promise)).reduce((p, c) => Object.assign(p, { [c]: props[c] }), {});
    // TODO 默认值
    return <Component {...params} {...state} />;
  };
}
function TableEditor({
  value,
  onChange,
  columns,
  children,
  tag = 'td',
  editable,
}) {
  const [rowIndex, setRowIndex] = useState();
  const [colIndex, setColIndex] = useState();
  const [domCell, setDomCell] = useState();
  const [resizeCount, setResizeCount] = useState(0);
  const ref = useRef();
  const editorRef = useRef();
  useEffect(() => {
    if (!(rowIndex in value)) {
      setRowIndex(undefined);
      setColIndex(undefined);
      setDomCell(null);
    }
  }, [value]);
  useEffect(() => {
    if (!editable)
      setDomCell(null);
  }, [editable]);
  useEffect(() => {
    const resizer = function () {
      setResizeCount(pre => pre + 1);
    };
    window.addEventListener('resize', resizer);
    return () => {
      window.removeEventListener('resize', resizer);
    };
  }, []);
  const handleClick = (e) => {
    if (!editable)
      return;
    const target = e.target;
    const container = ref.current;
    let td;
    if (target.nodeName.toLowerCase() !== tag)
      td = findParent(target, tag, container);
    else
      td = target;

    if (!td)
      return;
    setDomCell(td);
    if (td) {
      const parent = td.parentElement;
      const parentTag = parent.nodeName.toLowerCase();
      const root = parent.parentElement;
      const trs = root.querySelectorAll(parentTag);
      const tds = parent.querySelectorAll(tag);
      const rindex = Array.prototype.indexOf.call(trs, parent);
      const cindex = Array.prototype.indexOf.call(tds, td);
      setRowIndex(rindex);
      setColIndex(cindex);
    }

    setTimeout(() => {
      try {
        const elm = editorRef.current;
        const ipt = elm.querySelector('.ant-input');
        const sel = elm.querySelector('.ant-select');
        const iptnum = elm.querySelector('.ant-input-number-input');
        ipt && ipt.focus();
        sel && sel.click();
        iptnum && iptnum.focus();
      }
      catch (e) { }
    }, 0);
  };

  const getSchema = () => {
    const fields = Object.keys(columns);
    const field = fields[colIndex];
    const schema = columns[field];
    return [field, schema];
  };
  const [field, schema] = getSchema();
  const fields = value[rowIndex];
  const writeable = editable && isEditable(schema, fields);
  const isRepeat = needRepeatInput(schema, fields, field);
  const Component = useMemo(() => getAsyncComponent(isRepeat ? RepeatComponent : (schema && schema.type ? getType(schema.type) : Input)), [field]);
  return (
    <div className="TUI-TableEditor" style={{ position: 'relative' }}>
      <div ref={ref} onClick={handleClick}>
        {children}
      </div>
      {writeable && schema && fields && field ? (
        <TableEditorInputor
          ref={editorRef}
          domCell={domCell}
          value={fields[field]}
        >
          <Component
            value={fields[field]}
            fields={fields}
            options={filterCompute(schema, fields)}
            onChange={(v) => {
              const d = value;
              d[rowIndex][field] = v;
              onChange(d[rowIndex], field, v);
            }}
            Component={getType(schema.type) || Input}
            opts={{
              ...(schema.opts || {}),
              ...(schema.type === 'Select' || schema.type === Select ? { getPopupContainer: () => editorRef.current } : {}),
              // onMouseOut: () => {
              //   setDomCell(null);
              // },
            }}
          />
        </TableEditorInputor>
      ) : null}
    </div>
  );
}
function computeColumn(columns, record, changedField, changedValue) {
  const res = [];
  for (const field in columns) {
    const schema = columns[field];
    if (schema && typeof schema.compute === 'function') {
      res.push({
        field,
        value: schema.compute(record, changedField, changedValue),
      });
    }
  }
  return res;
}
function addRepeatRender(columns) {
  const StringComponent = RepeatComponent;
  for (const field in columns) {
    const schema = columns[field];
    const type = schema && schema.type ? getType(schema.type) : Input;
    if (type === Input && !schema.render) {
      schema.render = ({ value, record }) => {
        return <StringComponent value={value} Component={String} field={field} editable={isEditable(schema, record)} />;
      };
    }
    if (type === Select && !schema.render) {
      schema.render = ({ value, record }) => {
        const v = getSelectedValue(value, schema);
        return <StringComponent value={v} Component={String} field={field} editable={isEditable(schema, record)} />;
      };
    }
  }
  return columns;
}
function getSelectedValue(value, schema) {
  const { options = [] } = schema;
  let items = Array.isArray(value) ? value : [value];
  items = items.map((item) => {
    if (typeof item === 'object') {
      const d = options.find(o => o.id == item.value);
      const n = d ? d.name : item.value == 0 ? '' : item.value;
      return {
        ...item,
        value: n,
      };
    }
    else {
      const d = options.find(o => o.id == item);
      const n = d ? d.name : item == 0 ? '' : item; // ASSERT option id 不能为0
      return n;
    }
  });
  return items;
}
const OptionRender = getAsyncComponent(({ value, options, beforeOptionRender }) => {
  let ids = Array.isArray(value) ? value : [value];// TODO 严谨匹配
  ids = ids.map(id => `${id}`);
  const getResult = d => d.name || d.label;
  const item = deepSearch(options, ids).map(getResult);
  const result = typeof beforeOptionRender === 'function' ? beforeOptionRender(item, value, options) : item.join(',');
  return (
    <span>{result || value}</span>
  );
});
function addSelectRender(columns) {
  for (const field in columns) {
    const schema = columns[field];
    const type = schema && schema.type ? getType(schema.type) : {};
    if (schema.options || schema.optionCompute) {
      if (!schema.render) {
        schema.render = ({ value, record }) => {
          const { options, optionCompute } = schema;
          if (options && options.length)
            return <OptionRender value={value} options={options} beforeOptionRender={schema.beforeOptionRender} />;

          if (optionCompute)
            return <OptionRender value={value} options={filterCompute(schema, record)} beforeOptionRender={schema.beforeOptionRender} />;
        };
      }
      schema.opts = {
        ...schema.opts,
        allowClear: false,
      };
    }
  }
  return columns;
}
function TableEditorWraper({ value, columns, addTool = addTableEditorTool, editable = false, onChange, listProps = {} }, ref) {
  const [changed, setChanged] = useState([]);
  const columnSchema
    = typeof addTool === 'function' && editable ? addTool(columns) : columns;
  // addRepeatRender(columnSchema);
  addSelectRender(columnSchema);
  const crud = useCrudController({
    ListSchema: columnSchema,
  });
  useEffect(() => {
    crud.list.setState({
      ...crud.list,
      data: (value || []).map((d, i) => ({ ...d, $$$key: i })),
    });
    setChanged([]);
  }, [value]);
  const valiate = (schema, fields) => {
    const e = getValidateErrors(schema, fields);
    const errors = {};
    for (const key in e) {
      const error = e[key];
      if (error !== undefined)
        errors[key] = error;
    }
    if (Object.keys(errors).length)
      return errors;
    else
      return null;
  };
  const getErrors = () => {
    const { data } = crud.list;
    for (const d of data) {
      const i = data.indexOf(d);
      const errors = valiate(columnSchema, d);
      if (errors)
        return [i, errors];
    }
    return [-1, null];
  };
  const getCellByField = (tr, field) => {
    try {
      const i = Object.keys(columns).indexOf(field);
      return tr.children[i];
    }
    catch (e) { }
    return null;
  };
  const renderErrors = (errors, rowIndex) => {
    const field = Object.keys(errors)[0];
    const trs = tableRef.current.querySelectorAll('tbody tr');
    const tr = trs[rowIndex];
    const td = getCellByField(tr, field);
    if (!td)
      return;
    const { left, top, width, height } = {
      left: td.offsetLeft,
      top: td.offsetTop,
      width: td.offsetWidth + 1,
      height: td.offsetHeight + 1,
    };
    const div = document.createElement('div');
    div.style = `position: absolute;left: ${left}px;top:${top}px;width:${width}px;height:${height}px;border: 1px solid red;`;
    const sub = document.createElement('div');
    sub.classList.add('ant-tooltip');
    sub.classList.add('ant-tooltip-placement-topLeft');
    sub.style = 'transform:translate(0, -100%);';
    sub.innerHTML = `
      <div class="ant-tooltip-content">
        <div class="ant-tooltip-arrow"></div>
        <div class="ant-tooltip-inner" role="tooltip">
          <span>${errors[field]}</span>
        </div>
      </div>
    `;
    div.appendChild(sub);
    tableRef.current.appendChild(div);
    div.onclick = () => {
      try {
        tableRef.current.removeChild(div);
      }
      catch (e) { }
    };
    setTimeout(() => {
      try {
        tableRef.current.removeChild(div);
      }
      catch (e) { }
    }, 3000);
  };
  useImperativeHandle(ref, () => {
    return {
      flush: () => {
        crud.list.setState({
          ...crud.list,
        });
      },
      getValue: () => {
        return [
          crud.list.data.map((d) => {
            delete d.$$$key;
            return d;
          }),
          changed,
        ];
      },
      getErrors: () => {
        const [rowIndex, errors] = getErrors();
        if (errors)
          renderErrors(errors, rowIndex);

        return errors;
      },
    };
  });
  const onRecordChange = (row, field, value) => {
    const { data } = crud.list;
    const computed = computeColumn(columnSchema, row, field, value);
    const pending = computed.filter(d => d.value instanceof Promise);
    const plain = computed.filter(d => !pending.includes(d));
    plain.map((m, i) => {
      row[m.field] = m.value;
    });
    crud.list.setState({
      ...crud.list,
      data: [...data],
    });
    if (!changed.includes(row))
      setChanged([...changed, row]);

    onChange && onChange([...data]); // TODO 是否要结构赋值
    if (pending.length) {
      Promise.all(pending.map(d => d.value)).then((res) => {
        pending.map((m, i) => {
          row[m.field] = res[i];
        });
        crud.list.setState({
          ...crud.list,
          data: [...data],
        });
        if (!changed.includes(row))
          setChanged([...changed, row]);

        onChange && onChange([...data]);
      }).catch((e) => {
        console.log(e);
      });
    }
  };
  const tableRef = useRef();
  const groupRef = useRef();
  useEffect(() => {
    const thead = tableRef.current.querySelector('thead');
    const tr = document.createElement('tr');
    const group = {};
    let needGroup = false;
    for (const col in columnSchema) {
      const g = columnSchema[col].group;
      if (g !== undefined) {
        needGroup = true;
        if (group[g])
          group[g]++;
        else
          group[g] = 1;
      }
    }
    const ths = Object.keys(group)
      .map(k => `<th colSpan='${group[k]}' data-head='${k}'>${k}</th>`)
      .join('');
    if (needGroup && ths) {
      tr.innerHTML = ths;
      thead.insertBefore(tr, thead.firstElementChild);
      groupRef.current = tr;
    }
  }, []);
  useEffect(() => {
    const tr = groupRef.current;
    if (tr) {
      const th = tr.querySelector('[data-head=addOn]');
      if (editable) {
        // TODO 不使用特殊字段
        if (!th && columnSchema.$$$addOn) {
          const d = document.createElement('th');
          d.dataset.head = 'addOn';
          tr.appendChild(d);
        }
      }
      else {
        if (th)
          tr.removeChild(th);
      }
    }
  }, [editable]);
  return (
    <TableEditor
      columns={columnSchema}
      value={crud.list.data}
      onChange={onRecordChange}
      editable={editable}
    >
      <div ref={tableRef} style={{ position: 'relative' }}>
        <Crud
          filter={crud.filter}
          modal={crud.modal}
          form={crud.form}
          list={crud.list}
          FilterSchema={crud.FilterSchema}
          FormSchema={crud.FormSchema}
          ListSchema={crud.ListSchema}
          listProps={{
            rowSelection: false,
            pagination: null,
            rowKey: '$$$key',
            ...listProps,
          }}
          onRecordChange={onRecordChange}
        />
      </div>
    </TableEditor>
  );
}
export default forwardRef(TableEditorWraper);
