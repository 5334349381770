import type { HTMLAttributes } from 'react';
import React, { forwardRef } from 'react';
import { Typography } from 'antd';
import classNames from 'classnames';
import { Action, ChangeDivComponent, Handle, TUIIcon } from '../../../index';
import type { MenuProps } from '../SortableTree/types';
import styles from './TreeItem.module.less';

const { Text } = Typography;
export interface Props extends Omit<HTMLAttributes<HTMLLIElement>, 'id'> {
  isDarag?: boolean;
  childCount?: number;
  clone?: boolean;
  collapsed?: boolean;
  depth: number;
  disableInteraction?: boolean;
  disableSelection?: boolean;
  ghost?: boolean;
  handleProps?: any;
  indicator?: boolean;
  indentationWidth: number;
  value: string;
  name: string;
  icon?: any;
  item?: object;
  selectItem?: object;
  extraContent?: object;
  showCount?: boolean;
  menus?: MenuProps[];
  setMenu?: Function;
  parentItem?: object;
  onCollapse?: () => void;
  onRemove?: () => void;
  wrapperRef?: (node: HTMLLIElement) => void;
  nameWidth?: number;
  renderTooltip?: any;
}

function TreeItemT({
  childCount,
  clone,
  depth,
  disableSelection,
  disableInteraction,
  ghost,
  handleProps,
  indentationWidth,
  indicator,
  collapsed,
  onCollapse,
  onRemove,
  style,
  value,
  name: nameOri,
  item,
  selectItem = {},
  isDarag,
  extraContent,
  icon = <TUIIcon type="icon-a-folder1x1" style={{ marginRight: 4 }} />,
  showCount = false,
  menus = [],
  parentItem = {},
  wrapperRef,
  setMenu = () => [],
  nameWidth = 140,
  renderTooltip,
  ...props
}: Props, ref) {
  const isSelected = selectItem.id === item.id;
  const name = nameOri || '默认分类';
  // console.log('isSelected--', isSelected, depth, showCount, item);

  const Menus = setMenu(item).filter(itr => itr.isShow ? !!itr.isShow(item) : true);
  const names = depth === 0 ? showCount ? `${name}(${(item.children || []).length})` : name : name;

  return (
    <li
      className={classNames(
        styles.Wrapper,
        clone && styles.clone,
        ghost && styles.ghost,
        indicator && styles.indicator,
        disableSelection && styles.disableSelection,
        disableInteraction && styles.disableInteraction,
      )}
      ref={wrapperRef}
      style={
          {
            '--spacing': `${indentationWidth * depth}px`,
          } as React.CSSProperties
        }
      {...props}
    >
      <div className={classNames(isSelected && styles.isSelected, styles.TreeItem)} ref={ref} style={style}>
        {isDarag ? extraContent ? <Handle {...handleProps} /> : <div className={styles.PlaceholdDiv}></div> : <div className={styles.PlaceholdDiv}></div>}
        {/* <Handle {...handleProps} /> */}
        {depth === 0 && onCollapse && (
          <Action
            onClick={(e) => {
              e.stopPropagation();
              onCollapse();
            }}
            className={classNames(
              styles.Collapse,
              collapsed && styles.collapsed,
            )}
          >
            {collapseIcon}
          </Action>
        )}

        {extraContent && Menus.length
          ? (
            <div className={classNames(styles.extraContent, depth && styles.extraContentDeep)}>
              {depth === 0 ? icon : null}
              <ChangeDivComponent
                type={extraContent.type}
                parentItem={parentItem}
                itr={item}
                isSelected={isSelected}
                nameWidth={nameWidth}
                name={names}
                editGroupItem={extraContent.editGroupItem}
                beforeAdd={extraContent.beforeAdd}
                onSaveLeaf={(itr, val) => {
                  extraContent.onSaveLeaf(itr, val);
                }}
                onCancelLeaf={(type, itr) => {
                  extraContent.onCancelLeaf(type, itr);
                }}
                setMenu={item => setMenu(item)}
                renderTooltip={renderTooltip}
              />
            </div>
            )
          : (
            <div className={classNames(styles.extraContent, depth && styles.extraContentDeep)}>
              {depth === 0 ? icon : null}
              <Text
                ellipsis={{ tooltip: names }}
                className={classNames('name_row', { hasHandle: isDarag, hasDropMenu: Menus.length, isSelected })}
                style={nameWidth ? { width: `calc(${nameWidth}px + 12px + var(--spacing))` } : {}}
              >
                {names}
              </Text>
            </div>
            )}

        {clone && childCount && childCount > 1
          ? (
            <span className={styles.Count}>{childCount}</span>
            )
          : null}
      </div>
    </li>
  );
}

const collapseIcon = (
  <svg width="10" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 70 41">
    <path d="M30.76 39.2402C31.885 40.3638 33.41 40.995 35 40.995C36.59 40.995 38.115 40.3638 39.24 39.2402L68.24 10.2402C69.2998 9.10284 69.8768 7.59846 69.8494 6.04406C69.822 4.48965 69.1923 3.00657 68.093 1.90726C66.9937 0.807959 65.5106 0.178263 63.9562 0.150837C62.4018 0.123411 60.8974 0.700397 59.76 1.76024L35 26.5102L10.24 1.76024C9.10259 0.700397 7.59822 0.123411 6.04381 0.150837C4.4894 0.178263 3.00632 0.807959 1.90702 1.90726C0.807714 3.00657 0.178019 4.48965 0.150593 6.04406C0.123167 7.59846 0.700153 9.10284 1.75999 10.2402L30.76 39.2402Z" />
  </svg>
);

export const TreeItem = forwardRef<HTMLDivElement, Props>(TreeItemT);
