import React, { useEffect, useRef, useState } from 'react';
import { getCellArea, getCellLayoutPosition, getCellLayoutSize, getRowColByLayout } from './layout';

export default function GridLayout({ layout, gap = 4, cellRatio = 'auto', children, style }) {
  const [row, col] = getRowColByLayout(layout);
  const _style = {
    width: '100%',
    height: '100%',
    display: 'grid',
    gridTemplateRows: `${Array.from({ length: row })
      .map(() => '1fr')
      .join(' ')}`,
    gridTemplateColumns: `${Array.from({ length: col })
      .map(() => '1fr')
      .join(' ')}`,
    gridGap: gap,
    ...style,
  };
  const usedSpace = [];
  return (
    <div className="TUI-Grid-Layout" style={_style}>
      {React.Children.map(children, (c, i) => {
        const area = getCellArea({ layout, index: i, usedSpace }).map(d => d + 1);
        return (
          <div className="TUI-Grid-Layout-Item" key={c} style={{ gridArea: area.join('/') }}>
            {c}
          </div>
        );
      })}
    </div>
  );
};

function getCellSize(width, height, row, col, gap, cellRatio) {
  const g1 = gap * (row > 1 ? row - 1 : 0);
  const g2 = gap * (col > 1 ? col - 1 : 0);
  let w, h;
  if (cellRatio === 'auto') {
    w = (width - g2) / col;
    h = (height - g1) / row;
  }
  else {
    const w1 = (width - g2) / col;
    const w2 = ((height - g1) * cellRatio) / row;
    w = Math.min(w1, w2);
    h = w / cellRatio;
  }
  return {
    width: w,
    height: h,
  };
}
function throttle(func, wait) {
  let timer;
  let last = 0;
  return (...args) => {
    clearTimeout(timer);
    const current = +new Date();
    const d = current - last;
    if (d >= wait) {
      func.apply(null, ...args);
      last = +new Date();
    }
    else {
      timer = setTimeout(() => {
        func.apply(null, ...args);
        last = +new Date();
      }, d);
    }
  };
}

export function GridLayoutFlow({ layout, gap = 4, cellRatio = 'auto', children, style }) {
  const [row, col] = getRowColByLayout(layout);
  const [cellSize, setCellSize] = useState();
  const ref = useRef();
  const layoutFn = () => {
    setTimeout(() => {
      const elm = ref.current;
      if (!elm)
        return;
      const rect = elm.getBoundingClientRect();
      const size = getCellSize(rect.width, rect.height, row, col, gap, cellRatio);
      setCellSize(size);
    }, 0);
  };
  const cacehdLayout = throttle(layoutFn, 300);
  useEffect(() => {
    if (!row || !col)
      return;
    cacehdLayout();
    // const resizeObserver = new ResizeObserver((entries) => {
    //   cacehdLayout();
    // });
    // resizeObserver.observe(ref.current);
    return () => {
      // resizeObserver.unobserve(ref.current);
      setCellSize(null);
    };
  }, [row, col]);

  useEffect(() => {}, []);
  // todo resize
  const _style = {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    ...style,
  };
  const elms = React.Children.map(children, c => c);
  let count = 0;

  return (
    <div className="TUI-GridLayout_wrap" style={_style} ref={ref}>
      {cellSize
        ? (
          <div
            className="TUI-GridLayout"
            style={{
              position: 'relative',
              width: col * cellSize.width + (col > 1 ? col - 1 : 0) * gap + 1,
              height: row * cellSize.height + (row > 1 ? row - 1 : 0) * gap + 1,
            }}
          >
            {Array.from({ length: row * col }).map((_, i) => {
              const [width, height] = !layout
                ? [cellSize.width, cellSize.height]
                : getCellLayoutSize({
                  width: cellSize.width,
                  height: cellSize.height,
                  gap,
                  index: i,
                  layout,
                });
              const [left, top] = getCellLayoutPosition({
                index: i,
                col,
                width: cellSize.width,
                height: cellSize.height,
                gap,
              });
              if (!width || !height)
                return null;
              const c = elms[count];
              count++;
              return (
                <div
                  className="TUI-GridLayout-Item"
                  style={{
                    position: 'absolute',
                    width,
                    height,
                    left,
                    top,
                  }}
                >
                  {c}
                </div>
              );
            })}
          </div>
          )
        : null}
    </div>
  );
}
