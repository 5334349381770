import './wraper.less';
import { Button, Space } from 'antd';

export function FieldWrap({ children, name }) {
  return (
    <Space.Compact block className="TUI-FormField-Wrap">
      {name ? <Button className="TUI-FormField-Lable">{name}</Button> : null}
      <div className="TUI-FormField-Value">
        {children}
      </div>
    </Space.Compact>
  );
}
