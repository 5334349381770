import { Flex } from 'antd';
import { useAppConfigContext } from '../../../../context';
import { TUIIconPark } from '../../../../index';

export default function JumpSystem() {
  const { jumpSystemProps = {} } = useAppConfigContext();
  const { jumpSystemUrl, api = '/web/user/getSessionId', jumpCallBack, jumpText = '', jumpIcon = '', jumpProps = {} } = jumpSystemProps;

  const userFormUrl = localStorage.getItem('formUrl');

  return (
    <Flex
      align="center"
      gap="small"
      className="tui-cursor"
      onClick={() => {
        if (jumpCallBack) {
          jumpCallBack();
          return;
        }
        if (!userFormUrl)
          return;

        window.location.href = `${userFormUrl}/home`;
      }}
      {...jumpProps}
    >
      <TUIIconPark type={jumpIcon || 'workbench'} />
      <span>{jumpText || '个人工作台'}</span>
    </Flex>
  );
}
