import { useRef, useState } from 'react';

interface ModalControllerProps {
  visible?: boolean;
  loading?: boolean;
}
export default function useModalController(props: ModalControllerProps) {
  const {
    visible = false,
    loading = false,
  } = props || {};
  const [state, setState] = useState({
    visible,
    loading,
  });
  const handler = useRef({});
  const show = () => {
    setState({
      ...state,
      visible: true,
    });
  };

  const close = () => {
    setState({
      ...state,
      visible: false,
    });
  };

  const toggleLoading = () => {
    setState({
      ...state,
      loading: state.loading,
    });
  };

  return Object.assign(handler.current, {
    ...state,
    setState,
    show,
    close,
    toggleLoading,
  });
}
