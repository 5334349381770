import React from 'react';
import ClassNames from 'classnames';
import { Button } from 'antd';
import type { ButtonProps } from 'antd';
import './index.less';
import { TUIIcon, getIconType } from '../../index';

interface LinkBtnProps extends ButtonProps {
  key?: string;
  id?: string;
  iconType?: string;
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
}
export default function LinkBtn({ id, key, iconType, children, className, style = {}, ...rest }: LinkBtnProps) {
  return (
    <Button
      className={ClassNames('TUI-LinkBtn', className)}
      id={id}
      key={key}
      type="link"
      style={style}
      icon={<TUIIcon type={iconType || getIconType(id)} />}
      {...rest}
    >
      {children}
    </Button>
  );
};
