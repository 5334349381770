import { SortableTree } from './SortableTree';
import type { DragTreeMenuProps } from './types';

export default function DragTreeMenu({
  width = 258,
  nameWidth = 148,
  isDarag,
  storeData = initStoreData,
  setStoreData,
  onClickLeaf: onClickLeafSelf,
  onFetchService = {},
  setMenu, // 全自定义菜单
  OneMenus = [],
  TwoMenus = [],
  submitCallback,
  updateTreeData,
  icon,
  DragStyle = {},
  DragMenuProps = {},
  redefinedContent = {},
  beforeAdd,
  extraContent = {},
  ...rest
}: DragTreeMenuProps) {
  const { subAddId = {}, editId = {}, delId = {}, subEditId = {}, subDelId = {} } = redefinedContent;

  const { treeData = [], initData = [], editGroupItem } = storeData;

  const themeStyle = {
    '--width': `${width}px`,
  };

  /** 更新数据 */
  const updateStore = (payload = {}) => {
    setStoreData && setStoreData({ ...storeData, ...payload });
  };

  /** 点击某条 */
  const onClickLeaf = (item = {}) => {
    // const { depth } = item;
    // if (!depth)
    //   return;

    if (editGroupItem && (item.id !== editGroupItem.id)) {
      // 在新增分类时点击别的文件夹，数据要初始化
      updateStore({
        editGroupItem: null,
        ...(editGroupItem.type === 'leaf' && editGroupItem.id === '0' ? { treeData: initData } : {}),
        ...(item.id !== '0' ? { selectItem: item } : {}),
      });
    }
    else {
      updateStore({
        ...(item.id !== '0' ? { selectItem: item } : {}),
      });
    }
  };

  const callBack = (para) => {
    submitCallback && submitCallback(para);
  };

  const onSaveLeaf = async (itr, val) => {
    updateStore({
      ...(itr.depth !== 0 ? { selectItem: itr } : {}),
    });

    if (itr.id === '0')
      await onFetchService.onFetchAdd(val, itr).then(r => callBack());

    else
      await onFetchService.onFetchEdit({ ...itr, name: val }).then(r => callBack());
  };

  /** 点击取消 */
  const onCancelLeaf = (type, item) => {
    updateStore({
      editGroupItem: null,
      ...(item.id === '0' ? { treeData: initData } : {}),
    });
  };

  /** 新增 */
  const onAdd = (item) => {
    // 默认行内新增
    const treeDataT = JSON.parse(JSON.stringify(treeData));
    const findIndex = treeDataT.findIndex(it => it.id === item.id);
    const newParent = {
      ...item,
      collapsed: false,
      children: [...(item.children || []), { type: 'leaf', pId: item.id, parentId: item.id, id: '0', name: '', children: [] }],
    };
    treeDataT.splice(findIndex, 1, newParent);

    updateStore({
      ...storeData,
      initData: treeData,
      treeData: treeDataT,
      // expandKey: newExpand,
      //   needFresh: needFresh + 1,
      editGroupItem: { type: 'leaf', pId: item.id, parentId: item.id, id: '0', name: '' },
    });
  };

  /** 编辑 */
  const onEdit = (itr) => {
    updateStore({
      editGroupItem: { type: 'tree', ...itr },
      ...(itr.depth !== 0 ? { selectItem: itr } : {}),
      ...(editGroupItem && editGroupItem.type === 'leaf' && editGroupItem.id === '0' ? { treeData: initData } : {}),
    });
  };

  /** 删除 */
  const onDelete = async (itr) => {
    // TODO二次确认
    await onFetchService.onFetchDelete(itr).then(r => callBack({ mode: 'delete', ...itr }));
  };

  /** 拖拽完成 */
  const onDragAfter = ({ resultData }) => {
    const changeOrderArr = resultData.map((item, idx) => ({ id: item.id, order: `${idx + 1}`.padStart(8, '0') }));
    // console.log('拖拽后的字段--', resultData, changeOrderArr);
    updateTreeData && updateTreeData(resultData, changeOrderArr);
    onFetchService.onFetchChangeOrder && onFetchService.onFetchChangeOrder(changeOrderArr);
  };

  const menus = (item) => {
    const { depth, children = [] } = item;
    return depth === 0
      ? [
          {
            id: 'subAddId',
            name: subAddId.name || '新增子分类',
            isShow: () => (children.length && children.find(it => it.id === '0') ? false : subAddId.isShow ? subAddId.isShow(item) : true),
            onClick: () => { subAddId.onClick ? subAddId.onClick(item) : onAdd(item); },
          },
          {
            id: 'editId',
            name: editId.name || '编辑分类',
            isShow: () => editId.isShow ? editId.isShow(item) : true,
            onClick: () => { editId.onClick ? editId.onClick(item) : onEdit(item); },
          },
          {
            id: 'delId',
            name: delId.name || '删除分类',
            isShow: () => delId.isShow ? delId.isShow(item) : true,
            onClick: () => { delId.onClick ? delId.onClick(item) : onDelete(item); },
          },
          ...OneMenus,
        ]
      : [
          {
            id: 'subEditId',
            name: subEditId.name || '编辑',
            isShow: () => subEditId.isShow ? subEditId.isShow(item) : true,
            onClick: () => { subEditId.onClick ? subEditId.onClick(item) : onEdit(item); },
          },
          {
            id: 'subDelId',
            name: subDelId.name || '删除',
            isShow: () => subDelId.isShow ? subDelId.isShow(item) : true,
            onClick: () => { subDelId.onClick ? subDelId.onClick(item) : onDelete(item); },
          },
          ...TwoMenus,
        ];
  };

  return (
    <div style={{ ...themeStyle, ...DragStyle }}>
      {treeData.length > 0 && (
        <SortableTree
          collapsible
          showCount
          defaultItems={treeData}
          isDarag={isDarag}
          storeData={storeData}
          updateStoreData={setStoreData}
          icon={icon}
          updateTreeData={updateTreeData}
          onClickLeaf={onClickLeafSelf || onClickLeaf}
          onDragAfter={onDragAfter}
          onFetchChangeOrder={onFetchService.onFetchChangeOrder}
          setMenu={item => setMenu ? setMenu(item) : menus(item).filter(it => it)}
          extraContent={{
            type: 'tree',
            editGroupItem,
            onSaveLeaf: (itr, val) => onSaveLeaf(itr, val),
            onCancelLeaf: (type, itr) => onCancelLeaf(type, itr),
            beforeAdd,
            ...extraContent,
          }}
          nameWidth={nameWidth}
          updateStore={updateStore}
          {...DragMenuProps}
          {...rest}
        />
      )}

    </div>
  );
}
