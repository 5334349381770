import React from 'react';
import { Tooltip, message } from 'antd';
import { DrawableUploader, ExportButton, Form, Modal, useFormController } from '../../index';
import { getToken } from '../../utils/request';

interface BatchAddModelProps {
  visible: boolean;
  title: string;
  toggleBatchAdd: Function;
  tplUrl: string;
  submit: Function;
  submitCallback?: Function;
  xlslName?: string;
  endEss?: string;
  IconExclamation?: any;
  downPara?: object;
}
let form;
function BatchAddModel({ props }) {
  const {
    visible,
    title,
    toggleBatchAdd,
    tplUrl,
    submit,
    submitCallback,
    xlslName,
    endEss = 'xlsx',
    IconExclamation,
    downPara = {},
  }: BatchAddModelProps = props;
  const formschema = {
    file: {
      type: DrawableUploader,
      name: '请选择文件',
      rules: [
        { required: true, message: '请上传文件' },
        {
          validator: value => (value.length === 1 ? true : '请上传最多一个附件'),
        },
      ],
      opts: {
        accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        beforeUpload: async (file) => {
          if (!submit)
            return;
          try {
            await submit({ file });
            message.success('上传成功');
            submitCallback && submitCallback();
          }
          catch (e) {
            console.log(e);
            message.error(e.message);
          }
          return false;
        },
      },
    },
  };
  form = useFormController({ schema: formschema });
  if (!visible)
    return null;

  return (
    <Modal
      open={visible}
      title={title || '批量新增'}
      footer={null}
      maskClosable={false}
      destroyOnClose={true}
      onCancel={toggleBatchAdd}
      confirmLoading={form.submitting}
    >
      <Form
        schema={formschema}
        fields={form.fields}
        errors={form.errors}
        onFieldChange={form.setField}
        onChange={form.setFields}
      />
      <div style={{ textAlign: 'center' }}>
        <span style={{ marginRight: 30 }}>支持文件格式：xlsx</span>
        <Tooltip placement="bottomLeft" title="请在模板文件内按照说明填写，否则可能会上传失败">
          {IconExclamation || (
            <span>
              <i className="iconfont">&#xe630;</i>
            </span>
          )}
        </Tooltip>
        {xlslName
          ? (
            <ExportButton
              id="downXlsxBtn_Id"
              api={`${tplUrl}`}
              params={downPara}
              type="application/vnd.ms-excel"
              fileName={xlslName}
              endEss={endEss}
              buttonType="link"
              style={{ paddingLeft: 0 }}
            >
              下载模板
            </ExportButton>
            )
          : (
            <a target="_blank" rel="noreferrer" href={`${tplUrl}&authorization=${getToken()}`}>
              下载模板
            </a>
            )}
      </div>
    </Modal>
  );
}

export default React.memo(BatchAddModel);
