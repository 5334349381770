export default (schema) => {
  const layout = [];
  const getLastLine = () => layout[layout.length - 1];
  const createLine = () => {
    const line = [];
    layout.push(line);
    return line;
  };
  for (const k in schema) {
    const lastLine = getLastLine();
    const s = schema[k];
    if (s.inline) {
      if (lastLine)
        lastLine.push(k);

      else
        createLine().push(k);
    }
    else {
      createLine().push(k);
    }
  }

  return layout;
};
