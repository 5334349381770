

export const configTheme = [
  // { field: 'colorBgContainer', name: '组件的容器背景色', remark: '例如：默认按钮、输入框等。务必不要将其与 `colorBgElevated` 混淆', type: 'color', value: '#fff' },
  // { field: 'colorBgTextActive', name: '控制文本在激活状态下的背景色', remark: '', type: 'color', value: 'rgba(0, 0, 0, 0.15)' },
  // { field: 'colorBgTextHover', name: '控制文本在悬停状态下的背景色', remark: '', type: 'color', value: 'rgba(0, 0, 0, 0.06)' },
  // { field: 'colorBorder', name: '默认使用的边框颜色', remark: '例如：表单的分割线、卡片的分割线等', type: 'color', value: '#d9d9d9' },
  // { field: 'borderRadius', name: '基础组件的圆角大小', remark: '例如按钮、输入框、卡片等', type: 'number', value: 6 },
  // { field: 'fontSize', name: '字体大小', remark: '', type: 'number', value: 14 },
  // { field: 'lineHeight', name: '文本行高', remark: '', type: 'number', value: 1.5714285714285714 },
  // { field: 'lineType', name: '控制组件边框、分割线', remark: '', type: 'string', value: 'solid' },
  // { field: 'colorPrimary', name: '品牌色', remark: '', type: 'color', value: '#1677ff' },
  // { field: 'colorPrimaryActive', name: '品牌色激活态', remark: '', type: 'color', value: '#0958d9' },
  // { field: 'colorPrimaryBorder', name: '描边用色', remark: '', type: 'color', value: '#91caff' },
  // { field: 'colorPrimaryHover', name: '悬浮态', remark: '', type: 'color', value: '#4096ff' },

]