import { Drawer } from 'antd';
import { cloneElement, useEffect, useRef, useState } from 'react';
import * as ReactDOM from 'react-dom';

export default Drawer;

function ModalWrap({ children, register, CustomDrawer, ...rest }) {
  const [visible, setVisible] = useState(true);
  const ref = useRef();
  useEffect(() => {
    register
    && register({
      setVisible,
      ref,
    });
  }, []);
  const Elm = cloneElement(children, {
    ref,
  });
  const ModalComponent = CustomDrawer || Drawer;
  return (
    <ModalComponent
      open={visible}
      visible={visible}
      destroyOnClose
      maskClosable={false}
      {...rest}
    >
      {Elm}
    </ModalComponent>
  );
}
function show(content, logicFactory, CustomDrawer) {
  const wrap = document.createElement('div');
  document.body.appendChild(wrap);
  const handler = {};
  const logic = logicFactory(handler);
  const hide = () => {
    handler.setVisible(false);
    setTimeout(() => {
      document.body.removeChild(wrap);
    }, 0);
  };
  handler.hide = hide;
  const Elm = (
    <ModalWrap
      register={s => Object.assign(handler, s)}
      CustomDrawer={CustomDrawer}
      {...logic}
    >
      {content}
    </ModalWrap>
  );

  ReactDOM.render(Elm, wrap);
  return hide;
}
Drawer.show = show;
