import { message } from 'antd';
import request from '../../../../utils/request';

/** 修改手机号 */
export function revisePhoneNumber(url, params) {
  return request(url, params).catch((e) => {
    message.error(e.status ? e.message : '操作失败');
  });
}

export async function logout(url) {
  return request(url, {});
}

/** 修改密码 */
export async function submitUserPassword(url, para) {
  return request(url, para).catch((e) => {
    message.error(e.status ? e.message : '操作失败');
  });
}

/** 跳转门户 */
export async function getSessionId(url) {
  return request(url, {});
}

/** 获取二维码 */
export async function getAppQrCode(url) {
  return request(url, {});
}
