/* eslint-disable react-refresh/only-export-components */
import type {
  GetProps,
  GlobalToken,
} from 'antd';
import {
  Affix as AntAffix,
  Alert as AntAlert,
  Anchor as AntAnchor,
  App as AntApp,
  AutoComplete as AntAutoComplete,
  Avatar as AntAvatar,
  BackTop as AntBackTop,
  Badge as AntBadge,
  Breadcrumb as AntBreadcrumb,
  Button as AntButton,
  Calendar as AntCalendar,
  Card as AntCard,
  Carousel as AntCarousel,
  Cascader as AntCascader,
  Checkbox as AntCheckbox,
  Col as AntCol,
  Collapse as AntCollapse,
  ColorPicker as AntColorPicker,
  ConfigProvider as AntConfigProvider,
  DatePicker as AntDatePicker,
  Descriptions as AntDescriptions,
  Divider as AntDivider,
  Drawer as AntDrawer,
  Dropdown as AntDropdown,
  Empty as AntEmpty,
  Flex as AntFlex,
  FloatButton as AntFloatButton,
  Form as AntForm,
  Image as AntImage,
  Input as AntInput,
  InputNumber as AntInputNumber,
  Layout as AntLayout,
  List as AntList,
  Mentions as AntMentions,
  Menu as AntMenu,
  Modal as AntModal,
  Pagination as AntPagination,
  Popconfirm as AntPopconfirm,
  Popover as AntPopover,
  Progress as AntProgress,
  QRCode as AntQRCode,
  Radio as AntRadio,
  Rate as AntRate,
  Result as AntResult,
  Row as AntRow,
  Segmented as AntSegmented,
  Select as AntSelect,
  Skeleton as AntSkeleton,
  Slider as AntSlider,
  Space as AntSpace,
  Spin as AntSpin,
  Statistic as AntStatistic,
  Steps as AntSteps,
  Switch as AntSwitch,
  Table as AntTable,
  Tabs as AntTabs,
  Tag as AntTag,
  TimePicker as AntTimePicker,
  Timeline as AntTimeline,
  Tooltip as AntTooltip,
  Tour as AntTour,
  Transfer as AntTransfer,
  Tree as AntTree,
  TreeSelect as AntTreeSelect,
  Typography as AntTypography,
  Upload as AntUpload,
  Watermark as AntWatermark,
  theme,
} from 'antd';
import { forwardRef } from 'react';

const REACT_FORWARD_REF_TYPE: symbol = Symbol.for('react.forward_ref');

// https://github.com/Microsoft/TypeScript/issues/29729#issuecomment-567871939
export type LiteralUnion<T extends string> = T | (string & never);

function getDefaultProps<T>(defaultProps: T, token: GlobalToken): T {
  if (typeof defaultProps === 'function')
    return defaultProps(token);
  else
    return defaultProps;
}

/**
 * 设置组件默认属性
 * @param OriginalComponent
 * @param defaultProps
 * @returns OriginalComponent 的 HOC
 */
function setDefaultProps<T extends React.ComponentType<any>, P extends GetProps<T>>(OriginalComponent: T, defaultProps?: P | ((token: GlobalToken) => P)) {
  // 未传默认属性，原样返回
  if (defaultProps === undefined)
    return OriginalComponent;

  let Wrap;

  if (OriginalComponent.$$typeof === REACT_FORWARD_REF_TYPE) {
    Wrap = forwardRef((props, ref) => {
      const { token } = theme.useToken();
      return <OriginalComponent ref={ref} {...getDefaultProps(defaultProps, token)} {...props} />;
    });
  }
  else {
    Wrap = (props: P) => {
      const { token } = theme.useToken();
      return <OriginalComponent {...getDefaultProps(defaultProps, token)} {...props} />;
    };
  }

  const Wrap1 = Wrap as T;
  // Wrap1.displayName = OriginalComponent.displayName;
  // Wrap1.contextTypes = OriginalComponent.contextTypes;
  // Wrap1.defaultProps = OriginalComponent.defaultProps;
  // Wrap1.propTypes = OriginalComponent.propTypes;
  // 处理额外属性及 compounded component
  Object.entries(OriginalComponent).filter(([name]) => name !== 'render').forEach(([name, value]) => {
    Wrap1[name] = value;
  });

  return Wrap1;
}

export const Affix = setDefaultProps(AntAffix);
export const Alert = setDefaultProps(AntAlert);
export const Anchor = setDefaultProps(AntAnchor);
export const App = setDefaultProps(AntApp);
export const AutoComplete = setDefaultProps(AntAutoComplete);
export const Avatar = setDefaultProps(AntAvatar);
export const BackTop = setDefaultProps(AntBackTop);
export const Badge = setDefaultProps(AntBadge);
export const Breadcrumb = setDefaultProps(AntBreadcrumb);
export const Button = setDefaultProps(AntButton);
export const Calendar = setDefaultProps(AntCalendar);
export const Card = setDefaultProps(AntCard);
export const Carousel = setDefaultProps(AntCarousel);
export const Cascader = setDefaultProps(AntCascader);
export const Checkbox = setDefaultProps(AntCheckbox);
export const Col = setDefaultProps(AntCol);
export const Collapse = setDefaultProps(AntCollapse);
export const ColorPicker = setDefaultProps(AntColorPicker);
export const ConfigProvider = setDefaultProps(AntConfigProvider);
export const DatePicker = setDefaultProps(AntDatePicker);
export const Descriptions = setDefaultProps(AntDescriptions);
export const Divider = setDefaultProps(AntDivider);
export const Drawer = setDefaultProps(AntDrawer, { maskClosable: false, keyboard: false });
export const Dropdown = setDefaultProps(AntDropdown);
export const Empty = setDefaultProps(AntEmpty);
export const Flex = setDefaultProps(AntFlex, token => ({ gap: token.padding, children: undefined }));
export const FloatButton = setDefaultProps(AntFloatButton);
export const Form = setDefaultProps(AntForm);
export const Image = setDefaultProps(AntImage);
export const Input = setDefaultProps(AntInput, { placeholder: '请输入' });
export const InputNumber = setDefaultProps(AntInputNumber);
export const Layout = setDefaultProps(AntLayout);
export const List = setDefaultProps(AntList);
export const Mentions = setDefaultProps(AntMentions);
export const Menu = setDefaultProps(AntMenu);
export const Modal = setDefaultProps(AntModal, { maskClosable: false, keyboard: false });
export const Pagination = setDefaultProps(AntPagination);
export const Popconfirm = setDefaultProps(AntPopconfirm);
export const Popover = setDefaultProps(AntPopover);
export const Progress = setDefaultProps(AntProgress);
export const QRCode = setDefaultProps(AntQRCode);
export const Radio = setDefaultProps(AntRadio);
export const Rate = setDefaultProps(AntRate);
export const Result = setDefaultProps(AntResult);
export const Row = setDefaultProps(AntRow, token => ({ gutter: token.padding }));
export const Segmented = setDefaultProps(AntSegmented);

// compounded component 示例
const Select1 = setDefaultProps(AntSelect, { allowClear: true, placeholder: '请选择' });
Select1.Option = setDefaultProps(Select1.Option);
export const Select = Select1;

export const Skeleton = setDefaultProps(AntSkeleton);
export const Slider = setDefaultProps(AntSlider);
export const Space = setDefaultProps(AntSpace);
export const Spin = setDefaultProps(AntSpin);
export const Statistic = setDefaultProps(AntStatistic);
export const Steps = setDefaultProps(AntSteps);
export const Switch = setDefaultProps(AntSwitch);
export const Table = setDefaultProps(AntTable);
export const Tabs = setDefaultProps(AntTabs);
export const Tag = setDefaultProps(AntTag);
export const TimePicker = setDefaultProps(AntTimePicker);
export const Timeline = setDefaultProps(AntTimeline);
export const Tooltip = setDefaultProps(AntTooltip);
export const Tour = setDefaultProps(AntTour);
export const Transfer = setDefaultProps(AntTransfer);
export const Tree = setDefaultProps(AntTree);
export const TreeSelect = setDefaultProps(AntTreeSelect, { allowClear: true });
export const Typography = setDefaultProps(AntTypography);
export const Upload = setDefaultProps(AntUpload);
export const Watermark = setDefaultProps(AntWatermark);

export { default as locales } from 'antd/locale';
export { message, notification, theme, version } from 'antd';
