(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("React"), require("ReactDOM"), require("ReactRouterDOM"), require("antd"), require("dayjs"));
	else if(typeof define === 'function' && define.amd)
		define(["React", "ReactDOM", "ReactRouterDOM", "antd", "dayjs"], factory);
	else if(typeof exports === 'object')
		exports["tengitsui5"] = factory(require("React"), require("ReactDOM"), require("ReactRouterDOM"), require("antd"), require("dayjs"));
	else
		root["tengitsui5"] = factory(root["React"], root["ReactDOM"], root["ReactRouterDOM"], root["antd"], root["dayjs"]);
})(self, (__WEBPACK_EXTERNAL_MODULE__4883__, __WEBPACK_EXTERNAL_MODULE__1845__, __WEBPACK_EXTERNAL_MODULE__854__, __WEBPACK_EXTERNAL_MODULE__2059__, __WEBPACK_EXTERNAL_MODULE__9185__) => {
return 