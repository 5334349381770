interface headProps {
  LoginLogo?: string | any;
}
export default function Head({ LoginLogo }: headProps) {
  return (
    <div className="head">
      {LoginLogo && (
        <img
          className="logo"
          src={LoginLogo}
          alt=""
        />
      )}
    </div>
  );
};
