/* eslint-disable react-refresh/only-export-components */
import type { Dispatch } from 'react';
import { createContext, useContext, useReducer } from 'react';
import { merge } from 'lodash-es';
import { LOCAL_STORAGE_KEY } from '../../types';
import { type DispatchType, getReducer } from '../types';

/**
 * 用户上下文类型
 */
export interface UserContextType {
  birthday: string;
  phone: string;
  sex: number;
  name: string;
  worker: string;
  userId: number;
  mail: string;
  account: string;
  info: any[];
};

/**
 * 用户分发上下文类型
 */
type UserDispatchType = DispatchType<UserContextType>;

/**
 * 用户初始化数据
 */
const initialState: UserContextType = {
  birthday: '',
  phone: '',
  sex: 0,
  name: '',
  worker: '',
  userId: 0,
  mail: '',
  account: '',
  info: [],
};

/**
 * 用户上下文
 */
export const UserContext = createContext(initialState);

/**
 *  获取用户上下文
 * @returns {UserContextType} 用户上下文
 */
export function useUserContext() {
  return useContext(UserContext);
}

/**
 * 用户分发上下文
 */
export const UserDispatch = createContext<Dispatch<UserDispatchType>>(() => {});

/**
 * 获取用户分发上下文
 * @returns {Dispatch<UserDispatchType>} 用户分发上下文
 */
export function useUserDispatch() {
  return useContext(UserDispatch);
}

export interface UserContextProviderProps {
  defaultStatus?: UserContextType;
  children: React.ReactNode | Function; // TODO: 写明具体类型
}

/**
 * 用户上下文提供者
 * @param param0 子组件
 * @returns {React.ReactNode} 用户上下文提供者
 */
export function UserContextProvider({ defaultStatus, children }: UserContextProviderProps) {
  const jsonStr = localStorage.getItem(LOCAL_STORAGE_KEY.USERSTATUS);
  let _initialState: UserContextType;
  if (jsonStr) {
    try {
      _initialState = JSON.parse(jsonStr) as UserContextType;
    }
    catch (e) {
      _initialState = merge({}, initialState, defaultStatus);
    }
  }
  else {
    _initialState = merge({}, initialState, defaultStatus);
  }

  const [data, dispatch] = useReducer(
    getReducer<UserContextType, UserDispatchType>(_initialState, (newStatus) => {
      localStorage.setItem(LOCAL_STORAGE_KEY.USERSTATUS, JSON.stringify(newStatus));
    }),
    _initialState,
  );
  return (
    <UserContext.Provider value={data}>
      <UserDispatch.Provider value={dispatch}>
        {typeof children === 'function' ? children(data, dispatch) : children!}
      </UserDispatch.Provider>
    </UserContext.Provider>
  );
}
