export default (link, query = {}) => {
  const params = { ...query };
  const search
    = `?${
     Object.keys(params)
      .map(i => `${i}=${params[i]}`)
      .join('&')}`;
  const elm = document.createElement('a');
  elm.href = link + search;
  elm.target = '_blank';
  elm.onClick = (e) => {
    e.stopPropagation();
  };
  elm.click();
};

/** 文件下载 */
export function downloadReport(filename, res, endEss = 'zip', type = 'application/vnd.ms-excel') {
  let name = '';
  try {
    if (!filename) {
      const disposition = res.headers['content-disposition'].split('=')[2].substring(7);
      name = window.decodeURI(disposition, 'UTF-8');
    }
    else {
      name = `${filename}.${endEss}`;
    }
  }
  catch (error) {}

  const url = window.URL.createObjectURL(new Blob([res.data], { type })); // 创建下载的链接
  const link = document.createElement('a');
  link.style.display = 'none';
  link.href = url; // 设置a标签的路径
  link.setAttribute('download', name); // 设置下载的名字
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  window.URL.revokeObjectURL(url); // 释放掉blob对象
}
