import { useState } from 'react';
import { Button, Space, message } from 'antd';
import ClassNames from 'classnames';

export default function AddModal({
  modalMode,
  Modal,
  open,
  width: defaultWidth,
  title,
  onOk,
  onCancel,
  modalProps = {},
  errorMsg,
  noSuccessMsg = false,
  children,
  ...rest
}) {
  const width = defaultWidth || (modalMode === 'drawer' ? 500 : 520);

  const { footer, noFooter = false, cancelText = '取消', okText = '确定', cancelBtnProps = {}, okBtnProps = {}, className = '', classNames = {}, FotterButtons, extraFooterButtons = [] } = modalProps;

  const [submitLoading, setSubmitLoading] = useState(false); // 提交按钮的loading

  const handleClick = async () => {
    if (typeof onOk === 'function') {
      setSubmitLoading(true);
      setTimeout(async () => {
        try {
          const res = await onOk();
          // console.log('res', res)
          if (res && res.status === 0)
            !noSuccessMsg && message.success('提交成功');
          else
            throw res;

          setSubmitLoading(false);
          return res;
        }
        catch (e) {
          setSubmitLoading(false);
          throw e;
        }
      }, 0);
    }
  };

  // 弹窗默认要给footer，不要footer 传 noFooter
  const FooterBtns = noFooter
    ? null
    : (
      <Space className="TUI-AddModal-Footer">
        {footer || [<Button
          key="cancel"
          onClick={onCancel}
          {...cancelBtnProps}
                    >
          {cancelText}
        </Button>, ...extraFooterButtons, onOk
          ? (
            <Button key="ok" onClick={handleClick} loading={submitLoading} type="primary" {...okBtnProps}>
              {okText}
            </Button>
            )
          : null, FotterButtons].filter(it => it)}
      </Space>
      );

  return (
    <Modal
      open={open}
      destroyOnClose
      maskClosable={false}
      onOk={handleClick}
      onCancel={onCancel}
      onClose={onCancel}
      confirmLoading={submitLoading}
      cancelText={cancelText}
      okText={okText}
      width={width}
      title={title}
      {...modalProps}
      className={ClassNames(`TUI-AddModal`, className)}
      classNames={{
        body: ClassNames(`TUI-AddModal-body ${!FooterBtns && 'TUI-AddModal-body_noFooter'}`, modalMode === 'drawer' ? 'TUI-AddModal-body_dawer' : 'TUI-AddModal-body_modal'),
        mask: ClassNames({ 'TUI-AddModal-mask': modalMode === 'drawer' }),
        ...classNames,
      }}
      footer={modalMode === 'drawer' ? null : FooterBtns}
      {...rest}
    >
      {children}
      {modalMode === 'drawer' ? FooterBtns : null}
    </Modal>
  );
};
