import { useEffect, useState } from 'react';
import { Flex, Tooltip, Typography, message } from 'antd';
import ClassNames from 'classnames';
import { Search, TUIEmpty, TUIIcon, TUISkeleton } from '../../index';
import DragTree from './DragTree';
import type { DragTreeProps, storeDataProps, subUpdateParaType } from './types';
import { getFirstChildrenItem, getFirstParentItem, initStoreData } from './common';
import './index.less';

const { Title } = Typography;

function TUIDragTree({
  id = 'TUIDragTree_Id',
  width = 258,
  nameWidth = 148,
  title = '分类',
  addTips = '新增自定义分类',
  isDarag = false, // 是否可拖拽
  icon = '',
  updateSelectItem = () => {}, // 更新选中的数据
  dependPara = {}, // 接口依赖的一些参数
  dependField = '', // 接口依赖的参数
  onFeatchStart = () => true, // 请求前的判断
  onFetchList,
  onFetchAdd,
  onFetchEdit,
  onFetchDelete,
  onFetchChangeOrder,
  classNames = '',
  style = {},
  DragStyle = {},
  DargWarpHeight = `calc(100vh - 200px)`,
  DragMenuProps = {},
  redefinedContent = {}, // 重定义方法
  refresh,
  isAllExpand = false, // 是否默认全展开
  ...rest
}: DragTreeProps) {
  const { addId = {} } = redefinedContent;

  const iconT = icon || (<TUIIcon type="icon-a-folder1x1" style={{ marginRight: 4, marginLeft: isDarag ? 0 : 4 }} />);

  const [storeData, setStoreData] = useState<storeDataProps>(initStoreData);
  const [searchVal, setSearchVal] = useState('');

  const { selectItem, treeData, loading, editGroupItem, expandKey = [] } = storeData;

  const setValue = (field, val) => {
    setStoreData({
      ...storeData,
      [field]: val,
    });
  };

  /** 列表数据请求 */
  const getTreeData = async (para = {}, isFirstItem: boolean = false, subUpdatePara: subUpdateParaType = {}) => {
    if (!onFetchList)
      return;

    if (dependField && !dependPara[dependField])
      return;

    if (!onFeatchStart(dependPara))
      return;

    setValue('loading', true);
    const dataT = await onFetchList({
      ...para,
      ...dependPara,
    }).finally(() => {
      setValue('loading', false);
    });
    const dataTT = Array.isArray(dataT) ? dataT : [];

    const expandKeyT = subUpdatePara.parentId ? [subUpdatePara.parentId] : isFirstItem ? dataTT.length ? [getFirstParentItem(dataTT).id] : [] : expandKey;

    const data = dataTT.length ? dataTT.map(item => ({ ...item, collapsed: isAllExpand ? false : !expandKeyT.includes(item.id) })) : [];

    const selectItemT = subUpdatePara.parentId ? getFirstChildrenItem(dataTT, subUpdatePara.parentId, subUpdatePara.subName) : !isFirstItem && selectItem && selectItem.id ? selectItem : getFirstChildrenItem(dataTT);

    // console.log('getTreeData---', para, isFirstItem, subUpdatePara, dataTT, 111, expandKeyT, data, selectItemT, storeData);
    setStoreData({
      ...storeData,
      loading: false,
      treeData: data,
      initData: data,
      selectItem: selectItemT,
      expandKey: expandKeyT,
      editGroupItem: null,
    });
  };

  const onSearch = (val) => {
    setSearchVal(val);
    getTreeData({ keyWord: val }, true);
  };

  /** 更新树数据 */
  const updateTreeData = (newData) => {
    setTimeout(() => {
      setStoreData({
        ...storeData,
        treeData: newData,
        initData: newData,
        editGroupItem: null,
      });
    }, 100);
  };

  /** 新增 */
  const onAddT = () => {
    if (addId.onClick) { // 自定义新增
      addId.onClick();
      return;
    }
    // 默认行内新增
    if (editGroupItem && editGroupItem.id === '0') {
      message.info('已有新增项，请填写完成后再新增');
      return;
    }
    const dataTemp = [...treeData, { type: 'tree', id: '0', name: '', children: [] }];
    setStoreData({
      ...storeData,
      editGroupItem: { type: 'tree', id: '0', name: '', children: [] },
      treeData: dataTemp,
    });
  };

  /** 确定时，保证有数据填入 */
  const beforeAdd = (name, item) => {
    if (!name && !name.trim())
      return '请输入分类名称';
    return true;
  };

  const submitCallback = (para = {}) => {
    const subUpdatePara = para.mode === 'delete' ? { parentId: para.parentId, subName: '' } : {};
    getTreeData({ keyWord: searchVal }, para.mode === 'delete' && para.parentId, subUpdatePara);
  };

  useEffect(() => {
    updateSelectItem(selectItem || {});
  }, [selectItem]);

  const dependVaule = dependField ? dependPara[dependField] : '';

  useEffect(() => {
    const subUpdatePara = typeof refresh === 'number' ? {} : refresh;
    if (dependVaule)
      refresh && getTreeData({ keyWord: searchVal }, true, subUpdatePara);

    else
      getTreeData({ keyWord: searchVal }, !refresh);
  }, [refresh, dependVaule]);

  useEffect(() => {
    if (dependVaule) {
      setSearchVal('');
      getTreeData({}, true);
    }
  }, [dependVaule]);

  return (
    <div id={id} className={ClassNames(`TUI-DragTree ${classNames}`)}>
      <div className={ClassNames('Warp')} style={{ width, ...style }}>
        <Flex justify="space-between" align="center" className="Top">
          <Title level={5}>{title}</Title>
          {(addId.isShow ? addId.isShow() : true) && (
            <div className="Add" onClick={onAddT}>
              <Tooltip title={addTips}>
                <i className="iconfont">&#xe6b0;</i>
              </Tooltip>
            </div>
          )}
        </Flex>

        <div className="SearchWarp">
          <Search
            value={searchVal}
            onChange={onSearch}
            placeholder="搜索"
          />
        </div>

        {loading
          ? <TUISkeleton />
          : (
            <div className="DargWarp">
              {/* style={{ height: DargWarpHeight }}> */}
              {treeData.length > 0
                ? (
                  <DragTree
                    width={width}
                    nameWidth={nameWidth}
                    isDarag={isDarag}
                    storeData={storeData}
                    setStoreData={setStoreData}
                    onFetchService={{
                      onFetchAdd,
                      onFetchEdit,
                      onFetchDelete,
                      onFetchChangeOrder,
                    }}
                    updateTreeData={updateTreeData}
                    submitCallback={submitCallback}
                    icon={iconT}
                    DragStyle={DragStyle}
                    DragMenuProps={DragMenuProps}
                    redefinedContent={redefinedContent}
                    beforeAdd={beforeAdd}
                    {...rest}
                  />
                  )
                : <TUIEmpty />}
            </div>
            )}
      </div>
    </div>
  );
}

export default TUIDragTree;

export * as DragTreeUitls from './utilities';
export * as DragTreeCommon from './common';
export * as DragTreeTypes from './types';
