import { Skeleton, Space } from 'antd';

interface TUISkeletonProps {
  type?: 'default' | 'list' | 'table';
}
function TUISkeleton({
  type = 'default',
}: TUISkeletonProps) {
  if (['table', 'list'].includes(type)) {
    return (
      <Space direction="vertical" style={{ width: '100%' }}>
        {type === 'table' && <Skeleton.Input active size="default" block />}
        <Skeleton.Input active size="small" block />
        <Skeleton.Input active size="small" block />
        <Skeleton.Input active size="small" block />
      </Space>
    );
  }
  return <Skeleton active />;
}
export default TUISkeleton;
