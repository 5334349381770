import ClassNames from 'classnames';
import { Layout as AntdLayout } from 'antd';
import { useThemeContext } from '../../context/ThemeContext';
import { useLayoutContext } from '../../context/LayoutContext';
import type { ComponentBaseProps } from '../../types';
import { useThemeHook } from '../../index';
import Layout from './layout';
import Body from './Body';
import { getThemeStyles } from './getThemeStyles';
import './index.less';

export default function TUILayout({
  classNames,
  style,
  children,
}: ComponentBaseProps) {
  const { themeType, sysMode } = useThemeContext();
  const { menuCollapsed } = useLayoutContext();
  const { token } = useThemeHook();

  return (
    <div className={ClassNames(`TUI-LayoutWarp`, classNames)} style={{ ...getThemeStyles({ token, themeType, sysMode, menuCollapsed }), ...style }}>
      <AntdLayout>
        <Layout>
          <Body>
            {children}
          </Body>
        </Layout>
      </AntdLayout>
    </div>
  );
}
