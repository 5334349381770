import React, { useEffect, useState } from 'react';
import { Flex } from 'antd';
import ClassNames from 'classnames';
import { TUIIcon } from '../../index';
import './index.less';

interface PageLayoutProps {
  id?: string;
  right: React.ReactNode;
  left: React.ReactNode;
  collapsedWidth?: number;
  collPosition?: 'left' | 'right' | 'center-left' | 'center-right';
  OpenButtonPstion?: 'left' | 'right';
  height?: number | string;
  isNotShowCollapseBtn?: boolean;
  isCollapsed?: boolean;
  changeIsCollapsed?: Function;
  className?: string;
  style?: React.CSSProperties;
  OpenButtonStyle?: React.CSSProperties;
}

function PageLayout({
  id = 'PageLayout_Id',
  left,
  right,
  collapsedWidth = 258,
  height = 'calc(100vh - 92px)',
  collPosition = 'left',
  OpenButtonPstion = 'left',
  isNotShowCollapseBtn = false,
  isCollapsed: isCollapsedOri = false,
  changeIsCollapsed,
  className = '',
  style = {},
  OpenButtonStyle = {},
}: PageLayoutProps) {
  const [isCollapsed, setIsCollapsed] = useState(isCollapsedOri);

  const openBtnPos = collPosition === 'left' ? OpenButtonPstion === 'left' ? 'left' : 'center-left' : OpenButtonPstion === 'right' ? 'right' : 'center-right';
  const unfoldIcon = (collPosition === 'left' && isCollapsed) || (collPosition === 'right' && !isCollapsed);

  useEffect(() => {
    changeIsCollapsed && changeIsCollapsed(isCollapsed);
  }, [isCollapsed]);

  useEffect(() => {
    setIsCollapsed(isCollapsedOri);
  }, [isCollapsedOri]);

  const CollBtn = () => {
    return !isNotShowCollapseBtn && (
      <div
        className={ClassNames('OpenButton', openBtnPos)}
        style={{
          '--collapsedWidth': `${isCollapsed ? '0' : collapsedWidth}px`,
          ...OpenButtonStyle,
        }}
        onClick={() => setIsCollapsed(!isCollapsed)}
      >
        {unfoldIcon
          ? (
            <TUIIcon type="icon-menu-unfold-line" />
            )
          : (
            <TUIIcon type="icon-a-location1" />
            )}
        <div>{isCollapsed ? '展' : '收'}</div>
        <div>{isCollapsed ? '开' : '起'}</div>
      </div>
    );
  };

  return (
    <div id={id} className={ClassNames(`TUI-PageLayout ${className}`)} style={{ height, ...style }}>
      <Flex style={{ flexDirection: collPosition === 'left' ? 'row' : 'row-reverse', height: '100%' }}>
        <div
          className={ClassNames('TUI-leftWarp', collPosition)}
          style={{
            width: `${collapsedWidth}px`,
            [collPosition]: `${isCollapsed ? -collapsedWidth : '0'}px`,
          }}
        >
          {collPosition === 'left' ? left : right}
        </div>
        <Flex className="FlexWrap" style={{ flexDirection: collPosition === 'left' ? 'row' : 'row-reverse' }}>
          <div
            className="TUI-helpLeftWidth"
            style={{
              width: `${isCollapsed ? '0' : collapsedWidth}px`,
              height: '100%',
            }}
          >
          </div>
          <div
            className="TUI-rightWarp"
            style={{
              width: `calc(100% - ${collapsedWidth}px)`,
            }}
          >
            {collPosition === 'left' ? right : left}
          </div>
        </Flex>
      </Flex>

      {CollBtn()}
    </div>
  );
}

export default PageLayout;
