import { useEffect, useState } from 'react';
import { getSearchParams } from 'tengits-fe-utils';
import { LOCAL_STORAGE_KEY, LoginPage, TUISkeleton, jugeIsVideo } from '../../index';
import { getLoginPage } from './service';
import { defaultConfig, getConfigValueByField } from './common';
import './index.less';

interface LoginProps {
  page: 'login' | 'resetPass';
  loginConfigUrl?: string;
  otherLoginPara?: object;
  otherResetPara?: object;
}
export default function Login({ page = 'login', loginConfigUrl, otherLoginPara, otherResetPara, ...rest }: LoginProps) {
  const { jumpUrl } = getSearchParams();

  const [pageConfig, setPageConfig] = useState({});

  /** 拉登录页配置 */
  const getLoginPageService = async () => {
    const currentUrl = loginConfigUrl || jumpUrl || (window.location.origin.includes('localhost') ? '' : window.location.origin);
    await getLoginPage({
      keyWord: currentUrl || 'http://111.230.183.239:16017',
    }).then((r) => {
      setPageConfig(r.data);
      localStorage.setItem('Local_login_config', JSON.stringify(r.data));
    }).catch((e) => {
      setPageConfig(defaultConfig);
    });
  };

  useEffect(() => {
    localStorage.removeItem('Local_BreadcrumbArr');
    localStorage.removeItem(LOCAL_STORAGE_KEY.USERSTATUS);
    localStorage.clear();
    setTimeout(() => {
      getLoginPageService();
    }, 100);
  }, []);

  if (!pageConfig.systemName)
    return <TUISkeleton />;

  const {
    systemName,
    systemSubTitle,
    copyright,
    backImage,
    loginBgMask,
    loginFormWidth,
    loginTitleColor,
    loginType,
    logo,
    icon,
    title,
    tenantId,
    url,
  } = pageConfig;

  /** 登录页配置项转换 */
  const resultPageConfig = () => ({
    sysTitle: systemName,
    sysSubTitle: systemSubTitle,
    LoginBck: backImage,
    LoginLogo: logo,

    loginTitleColor: getConfigValueByField('loginTitleColor', loginTitleColor ? Number(loginTitleColor) : 2),
    loginType: getConfigValueByField('loginType', loginType ? Number(loginType) : 1),
    loginBGMask: getConfigValueByField('loginBGMask', loginBgMask ? Number(loginBgMask) : 1),
    LoginBckType: jugeIsVideo(backImage) ? 'video' : 'img',

    formWidth: loginFormWidth || undefined,
    footer: copyright,
  });

  return (
    <LoginPage
      page={page}
      themeMode="light"
      sysTitleType="string"
      {...resultPageConfig()}
      otherLoginPara={{
        tenantId: pageConfig.tenantId,
        ...otherLoginPara,
      }}
      otherResetPara={{
        ...otherResetPara,
      }}
      {...rest}
    />
  );
}
