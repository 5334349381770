// 一个合并单元只能用一个字符表示
// 合并单元数限制于字符数量
// 只能临近合并
export const layout1 = `
.
`;
export const layout2 = `
..
..
`;
export const layout3 = `
...
...
...
`;
export const layout4 = `
......
......
......
`;
export const layout5 = `
xxxx.
xxxx.
xxxx.
xxxx.
`;
export const layout6 = `
xx.
xx.
...
`;
export const layout7 = `
xxx.
xxx.
xxx.
....
`;
export const layout8 = `
aaabbb
aaabbb
aaabbb
......
`;
export const layout9 = `
.xxxx.
.xxxx.
.xxxx.
.xxxx.
`;
export const layout10 = `
xxxx..
xxxx..
xxxx..
xxxx..
`;

export const layout11 = `
....
.xx.
.xx.
....
`;
export const layout12 = `
......
aabbcc
aabbcc
......
`;

export const layout13 = `
xxx...
xxx...
xxx...
......
`;
export const layout14 = `
....
....
....
....
`;
export const layout15 = `
.....
.....
.....
.....
.....
`;
export const layout16 = `
......
......
......
......
......
......
`;
export const layout = {
  1: layout1,
  2: layout2,
  3: layout3,
  4: layout4,
  5: layout5,
  6: layout6,
  7: layout7,
  8: layout8,
  9: layout9,
  10: layout10,
  11: layout11,
  12: layout12,
  13: layout13,
  14: layout14,
  15: layout15,
  16: layout16,
};
export function createLayoutByRowCol(row, col) {
  return Array.from({ length: row })
    .map(() => {
      return '.'.repeat(col);
    })
    .join('\n');
}
export function getLayoutByMode(mode = 4, row, col) {
  if (mode === 4)
    return createLayoutByRowCol(row, col);
  return layout[mode];
}
export function getRowColByMode(mode = 4) {
  const la = layout[mode];
  if (!la)
    return [3, 6];
  const m = la.trim().split('\n');
  return [m.length, m[0].split('').length];
}
export function getRowColByLayout(la) {
  if (!la)
    return [1, 1];
  const m = la.trim().split('\n');
  return [m.length, m[0].split('').length];
}
export function getLayoutCellCount({ row, col, mode }) {
  if (mode === 4)
    return row * col;
  const la = getLayoutByMode(mode, row, col);
  const n = [];
  Array.from({ length: row * col }).map((_, i) => {
    n.push(
      getCellLayoutSize({
        width: 100,
        height: 100,
        layout: la,
        index: i,
      }),
    );
  });
  return n.filter(d => d[0] && d[1]).length;
}
export function getCellLayoutSize({ width, height, layout, index, gap }) {
  const t = layout
    .trim()
    .split('\n')
    .map(d => d.trim().split(''));
  const w = t[0].length;
  const h = t.length;

  const r = Math.floor(index / w);
  const c = index % w;
  const i = t[r][c];
  if (i === '.')
    return [width, height];
  const left = c > 0 ? t[r][c - 1] : '';
  const top = r > 0 ? t[r - 1][c] : '';
  if (left !== i && top !== i) {
    const a = t[r].reduce((p, o) => (o === i ? p + 1 : p), 0);
    const b = Array.from({ length: h }).reduce((p, o, x) => (t[x][c] === i ? p + 1 : p), 0);
    return [
      a * width + (a - 1 > 0 ? (a - 1) * gap : 0),
      b * height + (b - 1 > 0 ? (b - 1) * gap : 0),
    ];
  }
  return [0, 0];
}
export function getCellLayoutPosition({ index, col, width, height, gap }) {
  const r = Math.floor(index / col);
  const c = index % col;
  return [width * c + (c > 0 ? gap * c : 0), height * r + (r > 0 ? gap * r : 0)];
}
export function isMeredCell({
  index,
  mode,
  row,
  col,
  // configFlag = false
}) {
  const cellLayout = getLayoutByMode(mode, row, col);
  const cellSize = {
    width: 100,
    height: 100,
  };
  const [width, height] = getCellLayoutSize({
    width: cellSize.width,
    height: cellSize.height,
    index,
    layout: cellLayout,
  });
  if (mode == 8 && [0, 1].includes(index))
    return true;
  if (mode == 12 && [6, 7, 8].includes(index))
    return true;
  if (mode != 8 && mode != 12) {
    if (width > cellSize.width || height > cellSize.height)
      return true;
  }

  return false;
}

export function getCellArea({ layout, index, usedSpace = [] }) {
  const t = layout
    .trim()
    .split('\n')
    .map(d => d.trim().split(''));
  const w = t[0].length; // TODO 处理layout列不齐的情况
  const h = t.length;
  const fillSpace = (r, c, y, x) => {
    for (let i = 0; i < y; i++) {
      for (let j = 0; j < x; j++)
        usedSpace.push((r + i) * w + c + j);
    }
  };
  const getEnd = (unusedIndex) => {
    const r = Math.floor(unusedIndex / w);
    const c = unusedIndex % w;
    const i = t[r] ? t[r][c] || '' : '';
    if (i === '' || i === '.') {
      usedSpace.push(unusedIndex);
      return [r, c, r, c];
    }
    let f1 = true;
    let f2 = true;
    const x = t[r].slice(c).reduce((p, x, m) => (x === i && f1 ? p + 1 : ((f1 = 0), p)), 0);
    const y = t
      .map(d => d[c])
      .slice(r)
      .reduce((p, x, m) => (x === i && f2 ? p + 1 : ((f2 = 0), p)), 0);
    fillSpace(r, c, y, x);
    return [r, c, r + y, c + x];
  };

  const getIndex = (n) => {
    if (!usedSpace.includes(n))
      return n;
    return getIndex(n + 1);
  };
  const unusedIndex = getIndex(index);
  return getEnd(unusedIndex);
}
