import { cloneElement, createContext, forwardRef, useContext, useEffect, useImperativeHandle } from 'react';
import { Form, Input, Table } from 'antd';
import type { FormInstance, Rule } from 'antd/es/form';
import './index.less';

const EditableContext = createContext<FormInstance | null>(null);

type onSaveType = (rowIndex: number, dataIndex: string, value: any) => void;

interface Item {
  key: string;
  name: string;
  age: string;
  address: string;
}

type EditableType = boolean | ((record, rowIndex, dataIndex) => boolean);

interface EditableCellProps {
  title: React.ReactNode;
  editable: EditableType;
  children: React.ReactNode;
  dataIndex: keyof Item;
  record: Item;
  rowIndex: number;
  onSave: onSaveType;
  rules?: Rule[];
  inputComponent: React.ReactNode;
  valuePropName: string;
  rowKey: string;
}

const defaultInputComponent = <Input style={{ width: '100%' }} />;

const EditableCell: React.FC<EditableCellProps> = (props) => {
  const {
    title,
    editable,
    children,
    dataIndex,
    record,
    rowIndex,
    onSave,
    rules,
    inputComponent = defaultInputComponent,
    valuePropName = 'value',
    rowKey,
    ...restProps
  } = props;
  const form = useContext(EditableContext)!;
  const formItemName = `${rowKey ? record[rowKey] : rowIndex}@${dataIndex}`;

  const update = (values) => {
    onSave?.(rowIndex, dataIndex, values[formItemName]);
  };

  const onSaveInternal = async () => {
    await form
      .validateFields()
      .then(update)
      .catch((err) => {
        console.error('validation errors:', err);
        const { errorFields, values } = err;
        const selfError = errorFields.some(c => c.name.includes(formItemName));
        if (selfError)
          return;

        update(values);
      });
  };

  let childNode = children;

  let _editable: boolean = false;
  if (typeof editable === 'boolean')
    _editable = editable;
  else if (typeof editable === 'function')
    _editable = editable(record, rowIndex, dataIndex);

  useEffect(() => {
    if (record)
      form.setFieldValue(formItemName, record[dataIndex]);
  }, [dataIndex, form, formItemName, record]);

  if (_editable) {
    childNode = (
      <Form.Item
        style={{ margin: 0 }}
        name={formItemName}
        rules={rules}
        initialValue={record[dataIndex]}
        valuePropName={valuePropName}
      >
        {cloneElement(inputComponent, {
          onChange: onSaveInternal,
        })}
      </Form.Item>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

type TableProps = Parameters<typeof Table>[0];
export type EditableTableColumnTypes = (Exclude<TableProps['columns'], undefined>[number] & {
  editable?: EditableType;
  dataIndex?: string;
  rules?: Rule[];
  inputComponent?: React.ReactNode | ((record: any, rowIndex: number) => React.ReactNode);
  valuePropName?: string;
})[];

type Component<P> =
  | React.ComponentType<P>
  | React.ForwardRefExoticComponent<P>
  | React.FC<P>
  | keyof React.ReactHTML;

export type TUIEditableTableProps = Omit<TableProps, 'columns'> & {
  /**
   * 表格列配置
   */
  columns: EditableTableColumnTypes;
  bodyRow?: Component<any>;
  onSave?: onSaveType;
};

function TUIEditTableInternal(props: TUIEditableTableProps, ref) {
  const { columns, bodyRow, onSave, ...rest } = props;
  const [form] = Form.useForm();

  const getColumnsDeep = (columns: EditableTableColumnTypes) => {
    return columns.map((col) => {
      if (col.children?.length > 0) {
        return {
          ...col,
          children: getColumnsDeep(col.children),
        };
      }
      else {
        if (!col.editable)
          return col;
        return {
          ...col,
          onCell: (record: any, rowIndex: number) => ({
            record,
            rowIndex,
            onSave,
            editable: typeof col.editable === 'function' ? col.editable(record, rowIndex) : col.editable,
            dataIndex: col.dataIndex,
            title: col.title,
            rowKey: rest.rowKey,
            // columns 上新增
            rules: typeof col.rules === 'function' ? col.rules(record, rowIndex) : col.rules,
            inputComponent: typeof col.inputComponent === 'function' ? col.inputComponent(record, rowIndex) : col.inputComponent,
            valuePropName: col.valuePropName,
          }),
        };
      }
    });
  };

  useImperativeHandle(ref, () => ({
    form,
  }));

  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <Table
          components={{
            body: {
              row: bodyRow,
              cell: EditableCell,
            },
          }}
          rowClassName={() => 'tui-editable-row'}
          columns={getColumnsDeep(columns) as EditableTableColumnTypes}
          {...rest}
        />
      </EditableContext.Provider>
    </Form>
  );
}

const TUIEditTable = forwardRef(TUIEditTableInternal);

export default TUIEditTable;
