function getPropsByKeyPath(obj, key) {
  try {
    return eval(`obj.${key}`);
  }
  catch (e) {
    return undefined;
  }
}
export default (obj, props) => {
  const add = (key, value) => {
    const path = key
      .replace(/(\[([^'"])*?\])/g, '.$2')
      .replace(/(\[['"]([^'"]+)['"]\])/g, '.$2')
      .split('.');
    const len = path.length;
    let node = obj;
    for (const p of path) {
      const i = path.indexOf(p);
      if (i >= len - 1) {
        node[p] = value;
        return;
      }
      else {
        const t = path[i + 1];
        node[p] = node[p] !== undefined ? node[p] : /^\d+$/.test(t) ? [] : {};
        node = node[p];
      }
    }
  };
  // 处理serials[].label这种情况
  const extend = (key) => {
    let res = [];
    if (/\[\]/.test(key)) {
      const [nextKey] = key.split('[]');
      const arr = getPropsByKeyPath(obj, nextKey);
      if (Array.isArray(arr)) {
        if (arr.length === 0) {
          const r = extend(key.replace(`${nextKey}[]`, `${nextKey}[${0}]`));
          res = [...res, ...r];
        }
        else {
          for (const i in arr) {
            const r = extend(key.replace(`${nextKey}[]`, `${nextKey}[${i}]`));
            res = [...res, ...r];
          }
        }
      }
      else if (arr === undefined) {
        res = [...res, key.replace(`${nextKey}[]`, `${nextKey}[${0}]`)];
      }
    }
    else {
      res.push(key);
    }
    return res;
  };
  for (const key in props) {
    const v = props[key];
    const ks = extend(key);
    for (const k of ks)
      add(k, v);
  }
  return obj;
};
