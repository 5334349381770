import { Image as AntImage, ConfigProvider } from 'antd';

export function Image({ Items = [], tokenProps = {}, width, src = '', PreviewGroupOpts = {}, ImageOpts = {} }) {
  return (
    <ConfigProvider
      theme={{
        token: {
          ...tokenProps,
        },
      }}
    >
      {Items.length
        ? (
          <AntImage.PreviewGroup items={Items} {...PreviewGroupOpts}>
            <AntImage
              width={width}
              src={Items[0]}
              {...ImageOpts}
            />
          </AntImage.PreviewGroup>
          )
        : (
          <AntImage
            width={width}
            src={src}
            {...ImageOpts}
          />
          )}
    </ConfigProvider>
  );
}
