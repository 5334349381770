/** icon type 对照表 */
export const IconTypeObj = {
  view: 'icon-browse',
  edit: 'icon-edit-1',
  delete: 'icon-delete',
  more: 'icon-ellipsis',
  other: 'icon-setting',
  add: 'icon-add',
  upload: 'icon-upload',
  download: 'icon-download',
  share: 'icon-share',
};

/** 根据key获取iconType */
export function getIconType(key) {
  return key ? IconTypeObj[key] : 'icon-setting';
}
