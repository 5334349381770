import { message } from 'antd';
import request from '../../utils/request';
import axios from 'axios';

/** 拿登录页配置 */
export function getLoginPage(params) {
  return request('/saas/user/getLoginPage', {
    ...params,
  });
}

/** 登录 */
export function login(params) {
  return request('/saas/user/login', {
    ...params,
  }).catch((e) => {
    message.error(e.message || '登录失败');
  });
}

/** 换token */
export function loginSSO(para) {
  return request('/saas/user/getCookieBySessionId', {
    ...para,
  });
}

/** 滑块验证 */
export function validateTCaptcha(params) {
  return request('/saas/thirdpartsdk/validateCaptcha', {
    ...params,
  }).catch((e) => {
    message.error(e.message || '验证码出错');
  });
}

/** 拿用户信息 */
export function getUserInfoService(para) {
  return request('/saas/user/getUserInfo', {
    ...para,
  });
}

/** 拉菜单权限-用户权限 */
export function getRoleList(para) {
  return request('/saas/user/getRoleList', {
    ...para,
  });
}

/** 获取短信验证码 */
export function getSMSCaptcha(params) {
  return request('/saas/user/sendMessage', {
    ...params,
  });
}

/** 用户登录--重置密码 */
export function resetSMSPass(params) {
  return request(`/saas/user/forgetPassword`, {
    ...params,
  });
}

//  企业微信扫码：获取appid, redirect_uri, state
export function getCodeConfig(params) {
  return axios.get('sign/logon/oauth20/authorize/workweixin', {
    params,
  }).then(res => {
    if (res && res.data.code === 0) {
      const jumpUrl = res.data.data;
      if (!jumpUrl) return;
      const query = {};
      const regex = /([^?=&]+)(=([^&]*))?/g;
      let match;
      while ((match = regex.exec(jumpUrl))) {
        const [, key, , value] = match;
        query[key] = value;
      }
      return {
        appid: query.appid,
        redirect_uri: decodeURIComponent(query.redirect_uri),
        state: query.state,
      }
    }
    return null;
  })
}

// 企业微信扫码：获取agentId
export function getAgentId(params) {
  return axios.get('/sign/logon/oauth20/scanqrcode/workweixin', {
    params,
  }).then(res => {
    if (res && res.data.code === 0) {
      const obj = res.data.data;
      return {
        agentid: obj.agentId,
      }
    }
    return null;
  })
}

// 微信扫码：获取扫码地址
export function getWechatopenUrl(params) {
  return axios.get('/sign/logon/oauth20/authorize/wechatopen', {
    params,
  })
}

// 钉钉扫码：获取扫码地址
export function getDingtalkUrl(params) {
  return axios.get('/sign/logon/oauth20/authorize/dingtalk', {
    params,
  })
}

// 动态获取第三方扫码登录方式
export function getCodeWay() {
  return axios.get('/sign/login/get').then(res => res.data)
}

