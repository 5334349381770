import { Component } from 'react';
import { Button, Divider } from 'antd';
import Form from '../Form';
import FormItem from '../FormItem';

import './index.less';

export default class Filter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: true,
    };
  }

  toggleCollapse = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  getSchema = (schema) => {
    const { collapsed } = this.state;
    const keys = Object.keys(schema);
    const len = keys.length;
    if (collapsed && len > 2) {
      const firstKey = keys.shift();
      const lastKey = keys.pop();
      return {
        [firstKey]: schema[firstKey],
        [lastKey]: schema[lastKey],
      };
    }
    return schema;
  };

  getSearchClassName = () => {
    const { collapsed } = this.state;
    const { schema } = this.props;
    let otherClassName = '';
    if (document.body.offsetWidth < 1200 && collapsed) {
      if (Object.keys(schema).length >= 2 && collapsed)
        otherClassName = 'TUI-Filter-Controller-sm';
    }
    if (Object.keys(schema).length === 3 && !collapsed && document.body.offsetWidth >= 1200)
      otherClassName = 'TUI-Filter-Controller-lg';

    return `TUI-Filter-Controller ${otherClassName}`;
  };

  render() {
    const {
      defaultValue = {},
      schema,
      noCollpose = false,
      noClearer = false,
      onSearch,
      onReset,
      ...nextProps
    } = this.props;

    const { collapsed } = this.state;

    return (
      <div className="TUI-Filter">
        <Form
          schema={this.getSchema(schema)}
          defaultValue={defaultValue}
          {...nextProps}
        >
          <FormItem
            label=""
            className={this.getSearchClassName()}
            style={Object.keys(schema).length === 1 ? { textAlign: 'left' } : null}
          >
            <Button type="primary" onClick={onSearch}>
              查询
            </Button>
            {!noClearer ? <Button onClick={onReset}>清空</Button> : null}
            {Object.keys(schema).length > 2 && !noCollpose
              ? (
                  collapsed
                    ? (
                      <span className="TUI-Filter__collapse">
                        <a onClick={this.toggleCollapse}>
                          展开
                        </a>
                      </span>
                      )
                    : (
                      <span className="TUI-Filter__collapse">
                        <a onClick={this.toggleCollapse}>
                          收起
                        </a>
                      </span>
                      )
                )
              : null}
          </FormItem>
        </Form>
      </div>
    );
  }
}
